export const locale = {
    lang: 'es',
    data: {
        MAIN: {
            NO_RESULTS: 'No se encontraron resultados',
            NO_RESULTS_ADD_PATIENT:
                'No se encontraron resultados, haz click en (+) para registrar un paciente',
            BACK: 'Regresar',
            CLOSE: 'Cerrar',
            CLEAR: 'Borrar',
            NONE: 'No hay registros para mostrar',
            SEARCH: 'Buscar',
            ONLINE: 'En línea',
            OFFLINE: 'Fuera de línea',
            SALES_MENU: 'Menú de Ventas',
            YEARS: 'años',
            OF: 'de',
            HAS_VITALS: 'Tiene signos vitales registrados',
            VITALS: `No tiene signos vitales registrados`,
            CALENDAR: 'Calendario',
            PATIENTS_OF_THE_DAY: 'Pacientes del día',
        },
        CHART: {
            BILLING: 'Facturación',
            STADISTICS: 'Estadísticas',
            ATTEND: 'Citas atendidas',
            SCHEDULED: 'Citas planificadas',
            YEAR: 'Año',
            QUANTITY: 'Cantidad',
            MONTH: 'Mes',
            SEX: 'Sexo',
            MALE: 'Masculino',
            FEMALE: 'Femenino',
        },
        ANEXOS: {
            ADD: 'Agregar',
            ADD_ANNEX: 'Agregar anexo',
            EDIT_ANNEX: 'Editar anexo',
            SELECT_TYPE: 'Seleccionar tipo',
            DATE: 'Fecha',
            SPECIALTY: 'Especialidad',
            MEDIC: 'Médico',
            PATIENT: 'Paciente',
            DESCRIPTION: 'Descripción',
            SEARCH: 'Buscar',
            ATTACH: 'Adjuntar',
            SAVE: 'Guardar',
            REQUIRED: 'Este campo es requerido',
            INVALID_DATE: 'Formato de fecha invalido',
            CANCEL: 'Cancelar',
            ERROR: 'Por favor adjuntar un archivo',
            OBSERVATION: 'Observaciones',
            DROP_FILES:
                'Arrastra el archivo para subirlo o haz click en adjuntar',
            ALLOWED_FILES: 'Archivos permitidos',
            FILE_NOT_SUPPORTED: 'Extensión de archivos no soportada',
        },
        CITAS: {
            TITLE: 'Pacientes citados',
            TODAY: 'Hoy',
            TOMORROW: 'Mañana',
            NONE: 'No hay citas agendadas para mostrar en el rango de 7 días',
            AGE: 'Años',
            EDIT: 'Editar',
            MAKE_APPOINTMENT: 'Asignar Cita',
            CONFIRMED: 'Confirmada',
        },
        PATIENTS: {
            TITLE: 'Pacientes',
            HISTORY: 'Historia',
            PATIENT: 'Paciente',
            SEARCH: 'Buscar',
            RESULTS: 'Resultados',
            OPTIONS: 'Opciones',
            REGISTER: 'Registrar paciente',
            EDIT: 'Actualizar información del paciente',
            FILTER: 'Busqueda',
            NEW_WINDOW: 'Abrir en una nueva ventana',
            MAIL_CONFIRMED: 'Correo confirmado',
            MAIL_NOT_CONFIRMED: 'Correo no confirmado',
            RECIPE: 'Recipe',
            ANNEX: 'Anexo',
            MEDICAL_REPORT: 'Informe médico',
            EXAMN: 'Exámen',
            APPOINTMENT: 'Cita',
            SELECT_OPTION: 'Seleccione una opción',
            EVO_NOTE: 'Nota evolutiva',
            EVO_NOTE_CONFIRM_MSG: '¿Está seguro que desea registrar una nueva',
            TABLE: {
                ID: 'Identificación (C.I / Pasaporte)',
                NAME: 'Nombre',
                LASTNAME: 'Apellido',
                TELF: 'Teléfono',
                DATE: 'Fecha de nacimiento',
                MAIL: 'Correo',
                ACTION: 'Acciones',
                HISTORY: 'Historia',
                REPORT: 'Reportes',
                DELETE: 'Borrar',
                EXPORT: 'Exportar',
            },
            ADD: {
                CONFIRMATION_PWD_MSG:
                    'Ingrese su contraseña para ejecutar \n la acción',
                CONFIRM_EMAIL: 'Confirmar correo',
                UPLOAD: 'Cargar foto',
                INVITE: 'Invitar Portal',
                REQUIRED: 'Campo requerido',
                IMAGE: 'Imagen del paciente',
                PATIENT: 'Datos del paciente',
                NAME: 'Nombre',
                LASTNAME: 'Apellido',
                PASSWORD: 'Contraseña',
                PASSWORD_CONFIRM: 'Confirmar contaseña',
                TYPE: 'Tipo',
                ID: 'Identificación',
                DATE: 'Fecha de nacimiento',
                SEX: 'Sexo',
                MALE: 'Masculino',
                FEMALE: 'Femenino',
                COUNTRY: 'País',
                STATE: 'Estado',
                CITY: 'Ciudad',
                ADDRESS: 'Dirección',
                EMERGENCY: 'Datos de contacto de emergencia',
                TELF: 'Teléfono',
                MAIL: 'Correo',
                SAVE: 'Guardar',
                EDIT: 'Editar',
                UPDATE: 'Actualizar',
                WAIT: 'Espere que carguen los componentes',
                OK: 'Aceptar',
                CANCEL: 'Cancelar',
                CROP: 'Cortar imagen',
                MAX_10: 'El campo permite un maximo de 10 carácteres',
                MAX_40: 'El campo permite un maximo de 40 carácteres',
                MAX_250: 'El campo permite un maximo de 250 carácteres',
                VALID_MAIL: 'No es un correo valido',
            },
            EXPORT: {
                HISTORY: 'Historia',
                CONTACT: 'Contacto de emergencia',
                SELECT: 'Seleccionar Todos',
                FIELDS: 'Campos',
                TODAY: 'Hoy',
                LASTDAYS: 'Ultimos 7 días',
                LASTMONTH: 'Ultimo 1 mes',
                EVERYBODY: 'Todos',
                FILTER: 'Filtrar por fecha de creación',
                EXPORTAS: 'Exportar como',
                EXPORTPATIENTS: 'Exportar Pacientes',
                DREGISTER: 'Fecha Registro',
            },
        },
        CALENDAR: {
            APPOINT: 'Planificar cita',
            DATE: 'Fecha',
            TIME: 'Hora',
            FROM: 'Desde',
            TO: 'Hasta',
            MOTIVE: 'Motivo Consulta',
            ACCEPT: 'Aceptar',
            CANCEL: 'Cancelar',
            SEARCH_PATIENT: 'Buscar Paciente',
            REGISTER_PATIENT: 'Registrar Paciente',
            NAME: 'Nombre',
            LAST_NAME: 'Apellido',
            NACIONALITY: 'Nacionalidad',
            CEDULA: 'Identificación',
            BIRTH_DATE: 'Fecha de Nacimiento',
            SEX: 'Sexo',
            PHONE: 'Teĺefono',
            EMAIL: 'Correo Electrónico',
            APPOINTMENT_TYPE: 'Tipo de Cita',
            SPECIALTY: 'Especialidad',
            PATIENT_BASIC_DATA: 'Datos básicos del paciente',
            CLOSED: 'Cita Cerrada',
            ATTEND: 'Atender',
            DELETE: 'Eliminar',
            DAY: 'Día',
            WEEK: 'Semana',
            MONTH: 'Mes',
            GO_TO_TODAY: 'Ir al día actual',
            EDIT: 'Editar',
            CONSULT: 'Consulta en clínica',
            TELEMEDICINE: 'Telemedicina',
            TYPE_ATTENTION: 'Tipo de atención',
            SERVICES: 'Servicios',
            AT_HOME: 'Domicilio',
            NEXT_SLOT_AV: 'Prox. cupo disponible',
        },
        ATTEND: {
            TITLE: 'Atender paciente',
            ATTEND: 'Atender',
            YEARS: 'años',
            MOTIVE: 'Motivo de consulta',
            TYPE: 'Tipo de consulta',
            FIRST: 'Primera',
            SUCCESSIVE: 'Sucesiva',
            LOADING: 'Cargando información del paciente, por favor espere.',
            SHARE_TELEMEDICINE: 'Compartir',
            LINK_SUCCESS:
                'El enlace de acceso está disponible en el portapapeles',
            LINK_ERROR:
                'El enlace de acceso no pudo ser copiado en el portapapeles',
            COPY_LINK: 'Copiar enlace de cita',
            SEND_TO: 'Enviar a:',
            SEND_EMAIL: 'Enviar a otro correo',
            GO_TO_FILE: 'Ir a ficha',
            VITALS: 'Signos Vitales',
            APPOINTMENT: 'Cita',
            LINK_TITLE:
                '¡Hola! \n\nPara unirte a la video llamada, haz clic en este enlace: \n\n',
            CONFIRMED: 'Confirmada',
            CONFIRM: 'Confirmar',
            CONFIRMED_MSG: 'Confirmado el',
            MODIFIED_MSG: 'Modificado el',
            CONSULT_PRICE_SHOW: 'Consulta',
            PAY: 'Pagar',
        },
        ERROR: {
            INVALID_TIME: 'Horas de inicio y fin inválidas',
            INVALID_DATE: 'Fecha inválida',
            INVALID_DATE_FORMAT: 'Formato de fecha inválida',
            INVALID_DATE_AND_TIME: 'Fecha y hora inválida',
            INVALID_FORM: 'El formulario está incompleto',
            INVALID_PATIENT_FORM: 'Los datos del paciente están incompletos',
            INVALID_DATE_TELEMEDICINE:
                'La fecha selecciona no corresponde con las citas disponibles de telemedicina',
            REQUIRED: 'Este campo es requerido',
            INVALID_EMAIL: 'El formato del correo es inválido',
            APPOINTMENT_CLOSED:
                'La cita está cerrada, no puede ser modificada de horario o eliminada',
            OLD_DATE:
                'Debe seleccionar una fecha igual o mayor a la fecha actual',
            PATIENT_NOT_SELECTED: 'El paciente no se encuentra seleccionado',
            PATIENT_NOT_REGISTERED:
                'El paciente no se encuentra registrado, seleccione uno existente o registre uno nuevo',
            NO_SPECIALTYS: 'No hay especialidades asignadas al médico',
            CONTROL_MODULE:
                'No disponible, solicite la activación del módulo Control de Citas.',
            MISSING_ADDRESS: 'Las citas a domicilio requieren de una dirección',
        },
        REPORT: {
            TYPE: 'Tipo',
            DATE: 'Fecha',
            FROM: 'desde',
            DESCRIP: 'Descripción',
            TO: 'hasta',
            SPECIALTY: 'Especialidad',
            MEDIC: 'Médico',
            ACTIONS: 'Acciones',
            RECIPE: 'Recipe',
            INFORM: 'Informe',
            IMAGE: 'Imagenes',
            LAB: 'Laboratorio',
            PATIENT: 'Paciente',
            DELETE: 'Eliminar busqueda y resultados',
            ADD: 'Agregar informe',
            BACK: 'Regresar',
            NONE: 'No hay registros',
            ANNEX: 'Anexo',
        },
        WAITING_LIST: {
            TITLE: 'Pacientes en espera',
            TITLE_2: 'Pacientes atendidos',
            STATUS: 'Estatus',
            PATIENT: 'Paciente',
            MOTIVE: 'Motivo de consulta',
            SPECIALTY: 'Especialidad',
            TIME: 'Hora',
            ACTIONS: 'Acciones',
            NO_RESULTS: 'No hay citas para mostrar en este campo',
            ONLINE: 'Esperando',
            OFFLINE: 'Ausente',
            IN_CONSULTATION: 'En consulta',
            APPOINTMENTS: 'Citas planificadas',
            APPOINTMENTS_TODAY: 'Citas planificadas para el día actual',
            ATTENDED: 'Citas atendidas',
            ATTENDING: 'Atendiendo:',
            ATTEND_MSG: '¿Entrar a videollamada?',
            DELETE_MSG: '¿Desea eliminar al paciente de la lista de espera?',
            NO_MAIL: 'El paciente no tiene correo asociado',
            WAITED_TIME: 'Tiempo en espera',
            NO_MOTIVE: 'No registró motivo',
            EMAIL: 'Correo',
            CONFIRM_DISCONNECT_MSG:
                'Al desconectarse los pacientes en espera por atención seran eliminados de la lista. \n ¿Desea continuar?',
            OFFLINE_PATIENT_MSG:
                'El paciente se encuentra fuera de linea. \n ¿Desea continuar con la atención?',
            LEFT: 'Abandonó',
        },
        SERVICES: {
            NAME: 'Servicios',
            PRICE: 'Precio',
            TAX: 'Impuesto',
            SUBTOTAL: 'Subtotal',
            INVOICED: 'Facturado',
            ACTIONS: 'Acciones',
            DELETE: 'Borrar',
            NOT_INVOICED: 'No Facturados',
            PlURAL_INVOICED: 'Facturados',
            ALL: 'Todos',
        },
        BTN: {
            CANCEL: 'Cancelar',
            DELETE: 'Eliminar',
            INVITE: 'Invitar Portal',
            CONFIRM_MAIL: 'Confirmar correo',
            ACCEPT: 'Aceptar',
            SEND: 'Enviar',
            SELECT: 'Seleccionar',
            ENTER: 'Entrar',
            ATTEND: 'Atender',
            COPY_LINK: 'Copiar link',
            SEND_INVITATION: 'Enviar invitación',
            OPTIONS: 'Opciones',
            DOWNLOAD: 'Descargar',
            NEW: 'Nuevo',
            NEW_2: 'Nueva',
            LAB: 'Laboratorio',
            IMAGE: 'Imagen',
            SEND_EMAIL: 'Enviar correo',
            QUESTIONNARIE_PRECONSULT: 'Pre-cita',
            DELETE_ELE_QUESTION:
                '¿Está seguro que desea eliminar este archivo?',
        },
        MENU_IZQUIERDO: {
            VIEW_LIST: 'Ver lista',
            VIEW_CHART: 'Ver gráfica',
            ACTIONS: 'Acciones',
            ALLERGIES: 'Alergias',
            RECORD: 'Antecedentes',
            VITAL_SIGNS: 'Signos Vitales',
            APPOINTMENT_HISTORY: 'Citas Recientes',
            BLOOD_TYPE: 'Grupo sanguíneo',
            NOTES: 'Notas',
            PATHOLOGICAL: 'Patológicos',
            NO_PATHOLOGICAL: 'No Patológicos',
            ADD_ALLERGY: 'Agregar Alergia',
            ADD_RECORD: 'Agregar Antecedente',
            ADD_VITAL_SIGNS: 'Agregar Signos Vitales',
            VIEW_ALLERGY: 'Ver Alergias',
            VIEW_RECORD: 'Ver Antecedentes',
            VIEW_VITAL_SIGNS: 'Ver Signos Vitales',
            NOT_ALLERGIES: 'No presenta alergias',
            ACTIVE_PRINCIPLE: 'Seleccione principio activo (Opcional)',
            ACTIVE_PRINCIPLE_D: 'Principio Activo',
            DATE: 'Seleccione fecha (Opcional)',
            DATE_N: 'Seleccione fecha',
            COMMENTS: 'Comentarios (Opcional)',
            NOT_RECORD: 'No presenta antecedentes',
            TYPE_RECORD: 'Tipo de Antecedente',
            INVALID_OPTION: 'Opción Invalida',
            PATHOLOGICAL_Q: '¿Patológico?',
            DESCRIPTION: 'Descripción',
            REQUIRED_FIELD: 'Campo requerido!',
            TYPE: 'Tipo',
            MEDIC: 'Médico',
            OBSERVATION: 'Observación',
            DATE_D: 'Fecha',
            DATE_O: 'Fecha (Opcional)',
            NO_VITAL_SIGNS: 'No presenta signos vitales',
            OUT_RANGE: 'Fuera de rango',
            ALLOWED_RANGE: 'Valores permitidos',
            GRAPH: 'Gráfica',
            VALUE: 'Valor',
            DATE_H: 'Fecha / Hora',
            HOUR: 'Hora',
            MALE: 'Masculino',
            FEMALE: 'Femenino',
            RESPONSIBLE: 'Responsable de pago',
            TREATING: 'Medico tratante',
            AREA: 'Área',
            FLOOR: 'Piso',
            ROOM: 'Cuarto',
            ROOM_INFO: 'Seleccione un área y piso',
            BED: 'Cama',
            BED_INFO: 'Seleccione un cuarto',
            LOCATION: 'Ubicación',
            ASSIGN: 'Sin asignar',
            CONSULT: 'Consulta',
            ESPECIALTY: 'Especialidad',
            MEDICAL_TRANSFER: 'Traslado médico',
            LOCATION_TRANSFER: 'Traslado de ubicación',
            BLUE: 'Azúl: Confirmado',
            RED: 'Rojo: No confirmado',
            CONFIRMATION_EMAIL: '¿Desea enviar correo de confirmación?',
            PASSWORD: 'Contraseña',
            PASSWORD_CONFIRM: 'Confirmar contraseña',
            CONFIRMATION_PWD_MSG:
                'Ingrese su contraseña para ejecutar \n la acción',
            RESPONSIBLE_SIGNS: 'Responsable',
            CHANGE_TO_APPOINTMENT: 'Historia Clínica',
            CHANGE_TO_NOTE: 'Nota Evolutiva',
        },
        DIRECTION: {
            UPDATE_MSG:
                '¿Desea guardar la dirección en el perfíl del paciente?',
            BTN: {
                EDIT: 'Editar',
                ACCEPT: 'Aceptar',
            },
            ADD: {
                COUNTRY: 'País',
                CITY: 'Ciudad',
                ADDRESS: 'Dirección',
            },
        },
        MODULES: {
            EMERGENCY: 'Emergencia',
            HOSPITALIZATION: 'Hospitalización',
            SURGERY: 'Cirugía',
        },
        NAV: {
            ADMITTED: 'Ingresados',
            WAITING_ROOM: 'Sala de Espera',
            DISCHARGED: 'Egresados',
            OTHERS: 'Otros',
        },
        EMERGENCY: {
            ADMIT_PATIENT: 'Ingresar paciente',
            DASH_TITLE: 'Emergencia - Módulos',
            BACK_TO_MODULES: 'Módulos',
            CLICK_TO_CHANGE: 'Click para cambiar Módulo',
            ADMIT: {
                SEARCH: 'Buscar paciente',
                SELECT_AREA: 'Seleccione el área',
                SELECT_BED: 'Seleccione la cama',
                SELECT_SPEC_MEDIC: 'Seleccione especialidad y médico tratante',
                NO_BED: 'No hay un área seleccionada o camas disponibles en el área seleccionada',
                NO_AREA_SELECTED: 'No hay un área seleccionada',
                NO_BEDS_AVAILABLE: 'No hay camas disponibles en el área seleccionada',
                MEDIC_PLACEHOLDER: 'Médico',
                SPECIALTY_PLACEHOLDER: 'Especialidad',
                CONSULTATION_REASON: 'Motivo de consulta (Opcional)',
                NO_USER_SELECTED: 'No hay un usuario seleccionado',
                ADMIT: 'Ingresar',
                ADD: 'Agregar',
                SAVE: 'Guardar',
                CANCEL: 'Cancelar',
                DELETE: 'Eliminar',
                TABLE_DEFAULT: 'Por defecto',
                NO_AREAS: 'No hay áreas disponibles',
                NO_MEDICS: 'No hay médicos de esta especialidad',
                DONE: 'Hecho',
                ALL: 'Todas',
                ADMITTED_PATIENTS: 'Ingresados',
                AGE: 'Edad',
            },
            E_TABLE: {
                LOCATION: 'Ubicación',
                PATIENT: 'Paciente',
                MEDIC: 'Médico',
                TIME: 'Tiempo',
                REASON: 'Motivo',
                DX: 'Dx',
                TEMPERATURE: 'Temperatura',
                BLOOD_PRESSURE: 'Tensión Arterial',
                WEIGHT: 'Peso',
                DEFAULT: 'Por defecto',
                NO_INFO: 'Sin información',
                AGE: 'Edad',
                MALE: 'Masculino',
                FEMALE: 'Femenino',
                NO_RECORDS: 'No hay registros',
                YEARS: 'años',
                S2OX: 'S2Ox',
                SIZE: 'Talla',
            },
            WAIT: {
                ADD: 'Agregar',
                ADMIT_PATIENT: 'Agregar paciente - Sala de espera',
                EDIT_PATIENT: 'Editar paciente - Sala de espera',
                DELETE_PATIENT: 'Eliminar paciente',
                DELETE_PATIENT_CONFIRMATION: '¿Está seguro de eliminar a "{{patient}}" de la sala de espera?',
                YES: 'Si',
                NO: 'No',
                ADDITIONAL_INFO: 'Información adicional',
                SITUATION: 'Situación/Clasificación',
                WAIT_PATIENTS: 'Sala de espera',
                LEGEND: 'Leyenda',
            },
            OTHERS: {
                MOVE_PATIENT_CONFIRMATION: '¿Está seguro de reingresar a "{{patient}}" a la sala de espera?',
                YES: 'Si',
                NO: 'No',
                ADDITIONAL_INFO: 'Información adicional',
                SITUATION: 'Situación/Clasificación',
                OTHERS: 'Otros',
                MOVE_PATIENT: 'Reingresar paciente a sala de espera',
                MAINTAIN_WAIT_TIME: 'Mantener hora de espera',
                SEARCH: 'Buscar',
            },
            W_TABLE: {
                CLASSIFICATION: 'Clasificación',
                ACTIONS: 'Acciones',
                ADMIT: 'Ingresar',
                DELETE: 'Eliminar',
                QUIT_WAIT: 'Abandonar espera',
                GET_INTO_BED: 'Ingresar a cama',
                ORDER: 'Orden',
            },
            DISCHARGE: {
                DISCHARGED: 'Egresados',
                RE_ADMIT: 'Reingresar paciente',
                RE_ADMIT_CONFIRMATION: '¿Está seguro de reingresar a "{{patient}}" al área {{area}}/{{bed}}?',
                RE_ADMIT_AREA: 'Seleccione una ubicación para {{patient}}',
                CANCEL: 'Cancelar',
                YES: 'Si',
                READMIT: 'Reingresar',
            },
            D_TABLE: {
                DATE_TIME: 'Fecha/Hora',
                DISCHARGE_BY: 'Egresado por',
                RE_ADMIT: 'Reingresar a cama',
            },
            O_TABLE: {
                ACTIONS: 'Actions',
                MOVE_INTO_WAITING: 'Reingresar a sala de espera',
                DEPARTURE_DATE: 'Fecha salida',
                ELIMINATED_BY: 'Eliminado por',
            },
            SEARCH: {
                ADMITED: 'Paciente / Especialidad',
                WAITING: 'Paciente / Clasificación',
                DISCHARGED: 'Paciente / Especialidad',
                OTHERS: 'Paciente',
            },
        },
        HOSPITALIZATION: {
            DASH_TITLE: 'Hospitalización - Módulos',
        },
        SURGERY: {
            DASH_TITLE: 'Cirugía - Módulos',
            SCHEDULE_TITLE: 'Planificación de Quirófano',
            CONFIRM_ADMISSION: 'Confirmación de Ingreso',
            CONFIRM_OMIT: 'Omitir Paciente',
            CONFIRM_REVERSE: 'Reversar Ingreso Paciente',
            CONFIRMATION: 'Confirmar Cita',
            PARTICIPANTS: 'Participantes',
            ADD_PARTICIPANTS: 'Agregar Participantes',
            MOVE_APPOINTMENT: 'Mover Cita',
            ADMITTED: 'Ingresado',
            NOT_ADMITTED: 'No Ingresado',
            TABLE: {
                TIME: 'Hora',
                PATIENT: 'Paciente',
                INTERVENTIONS: 'Intervenciones Planificadas',
                PARTICIPANTS: 'Participantes',
                ACTIONS: 'Acciones',
                NO_INFO: 'Sin información',
                OMIT: 'Omitir',
                REVERSE: 'Reversar',
                ADMIT: 'Ingresar',
                DISCHARGED_BY: 'Egresado por',
            },
            INPUTS: {
                PLACEHOLDER: 'Paciente, Historia, Médico, ...',
                TODAY: 'Hoy',
                YESTERDAY: 'Ayer',
            },
            MODAL: {
                APPOINTMENT_DATA: 'Datos de la cita',
                DATE_TIME: 'Fecha - Hora:',
                SPECIALTY: 'Especialidad:',
                MEDIC: 'Médico Tratante:',
                LOCATION_DATA: 'Datos de la ubicación',
                AREA: 'Área:',
                OPERATING_ROOM: 'Quirófano:',
                COMMENT: 'Comentario',
                OMIT_MESSAGE: '¿Está seguro de omitir el ingreso de {{patient}} del {{quirofano}}?',
                REVERSE_MESSAGE: '¿Está seguro de reversar el ingreso de {{patient}} del {{quirofano}}?',
                CONFIRMATION_MESSAGE: '¿Está seguro de confirmar la cita del {{patient}} al {{quirofano}}?',
                ADMIT_MESSAGE: '¿Está seguro de ingresar al {{patient}} al {{quirofano}}?',
                DRAG_MESSAGE: '¿Está seguro de cambiar la cita de {{hora}} a {{hora_n}}?',
                DRAG_MESSAGE_2: '¿Está seguro de cambiar la cita de {{hora}} a {{hora_n}}, y de {{quirofano}} a {{quirofano_n}}?',
                CANCEL: 'Cancelar',
                ADMIT: 'Ingresar',
                YES: 'Si',
                NO: 'No',
                YEARS: 'años',
                MONTHS: 'meses',
                DAYS: 'días',
                MAIN_SURGEON: 'Cirujano Principal:',
                ROLE: 'Rol',
                NAME: 'Nombre',
                ANESTHESIOLOGIST: 'Anestesiólogo',
                HELPER_1: 'Ayudante 1',
                HELPER_2: 'Ayudante 2',
                HELPER_3: 'Ayudante 3',
                INSTRUMENTIST: 'Enf. Instrumentista',
                CIRCULANT: 'Enf. Circulante',
                PEDIATRICIAN: 'Pediatra',
                VIDEO_TECHNICIAN: 'Técnico Video',
                HEMODYNAMIC_NURSE: 'Enf. Hemodinamia',
                SELECT_ROLE: 'Seleccione un rol',
            },
            SCHEDULE: {
                GO_BACK_DASH: 'Volver al Dashboard',
                AREA: 'Área',
                WEEK_VIEW: 'Vista de Semana',
                DAY_VIEW: 'Vista de Día',
                OPERATING_ROOM: 'Quirófano',
                AVAILABILITY: 'Disponibilidad de Quirófano',
                DATE_TIME: 'Seleccione Fecha y Hora',
                CANCEL: 'Cancelar',
                ACCEPT: 'Aceptar',
                CLICK_TO_CHANGE_DATE: 'Click para cambiar fecha',
                TODAY: 'Hoy',
                OMITTED: 'Pacientes Omitidos',
                NO_OMITTED: 'Pacientes No Omitidos',
                PENDING: 'Pacientes Pendientes',
                SCHEDULE: 'Agendar',
                RESCHEDULE: 'Reagendar',
                NON_APPROVED: 'Emergencias no Aprobadas',
                EDIT: 'Editar',
                CONFIRM: 'Confirmar',
                OMIT: 'Omitir',
                PARTICIPANTS: 'Participantes',
                PENDING_TABLE: {
                    PATIENT: 'Paciente',
                    INTERVENTION: 'Intervención',
                    MEDIC: 'Médico',
                    AREA: 'Área',
                    OPERATING_ROOM: 'Quirófano',
                },
                APPOINTMENT: {
                    SCHEDULE: 'Agendar',
                    TITLE: 'Asignación de Cita',
                    PATIENT_INFO: 'Información del Paciente',
                    INTERVENTION_INFO: 'Información de la Intervención',
                    INTERVENTION_SUMMARY: 'Resumen de la Intervención',
                    SPECIALTY: 'Especialidad',
                    DOCTOR: 'Médico',
                    DATE: 'Fecha',
                    TIME: 'Hora Inicio',
                    TIME_LABEL: 'Hora',
                    PRIORITY: 'Prioridad',
                    TRANSIT: 'Tránsito',
                    AREA: 'Área',
                    OPERATING_ROOM: 'Quirófano',
                    ADITIONAL_SERVICES: 'Servicios Adicionales',
                    DX_PRE: 'Dx Pre-Operatorio',
                    INTERVENTION: 'Intervención',
                    TOTAL_TIME: 'Tiempo Total Intervencion (min)',
                    RX: 'Rx',
                    PATHOLOGICAL_ANATOMY: 'Anatomía Patológica',
                    ICU: 'UCI',
                    TRANSPARENT_TABLE: 'Mesa Transparente',
                    SEPTIC: 'Séptico',
                    BLOOD_BANK: 'Banco de Sangre',
                    LOCAL_ANESTHESIA: 'Anestesia Local',
                    THERMAL_BLANKET: 'Manta Térmica',
                    OBSERVATIONS: 'Observaciones',
                    REFERRED: 'Referido',
                    NOT_REFERRED: 'No Referido',
                    EXTERNAL_REFERRED: 'Referido Externo',
                    TO: 'hasta',
                    TOTAL_TIME_HINT: 'Mínimo 30 minutos',
                    RESCHEDULE_APPOINTMENT: 'Reagendar Cita',
                    LOCATION: 'Ubicación',
                },
                PARTICIPANTS_MODAL: {
                    ROLE: 'Rol',
                    NAME: 'Nombre',
                    ADD: 'Agregar',
                    CANCEL: 'Cancelar',
                    ACCEPT: 'Aceptar',
                    DELETE: 'Eliminar',
                },
            }
        },
        DATES: {
            YEARS: 'años',
            MONTHS: 'meses',
            DAYS: 'días',
        },
        VITALS: {
            TITLE: 'Signos Vitales',
            LOCATION: 'Ubicación',
            SPECIALTY: 'Especialidad',
            BLOOD_GROUP: 'Grupo sanguíneo',
            SAVE: 'Guardar',
            SHOW_CHART: 'Ver gráfica',
            HIDE_CHART: 'Ocultar gráfica',
            TEMPERATURE: 'Temperatura',
            TEMPERATURE_MEASURE: 'Temperatura (°C)',
            WEIGHT: 'Peso',
            WEIGHT_MEASURE: 'Peso (kg)',
            SIZE: 'Talla',
            SIZE_MEASURE: 'Talla (cm)',
            TENSION: 'Tensión Arterial (mmHg)',
            TENSION_HIGH: 'Sistólica',
            TENSION_HIGH_MEASURE: 'Sistólica (mmHg)',
            TENSION_LOW: 'Diastólica',
            TENSION_LOW_MEASURE: 'Diastólica (mmHg)',
            S2OX: 'S2Ox',
            S2OX_MEASURE: 'S2Ox (%)',
            FROM_ADMISION: 'Desde ingreso',
            THREE_MONTHS: '3 meses',
            SIX_MONTHS: '6 meses',
            TWELVE_MONTHS: '12 meses',
            NO_DATA: 'No hay datos',
            YEAR: 'Año',
            ADD: 'Agregar',
        }
    },
};
