export const locale = {
    lang: 'en',
    data: {
        SCHEDULE_APPOINTMENT: 'Schedule Appointment',
        SCHEDULED_APPOINTMENT: 'Scheduled Appointment',
        SCHEDULED_APPOINTMENT_MESSAGE: 'Your appointment was scheduled',
        FILL_FORM_MESSAGE:
            'Your doctor needs you to fill out a form before your appointment, click next to fill it out. Close to fill it later.',
        DO_VERIFY:
            'Verify time and date of the appointment then press schedule',
        DO_SAVE: 'Then press save',
        SAVE: 'Save',
        SCHEDULE: 'Schedule',
        CANCEL: 'Cancel',
        MOTIVE: 'Motive',
        CLOSE: 'Close',
        NEXT: 'Next',
        TELEMEDICINE: 'Telemedicine',
        CONSULT: 'On site consult',
        AT_HOME: 'At home',
        CONSULT_PRICE: 'Consult price',
        PAYMENT_TITLE: 'Pay Appointment',
        PAYMENT_BUTTON: 'Pay',
        PAYMENT_AMOUNT: 'Total to pay',
        CREDIT_CARD_NAME: 'Number that appears on the card',
        CREDIT_CARD: 'Credit Card',
        SELECT_PAYMENT: 'Select a payment method',
        CREDIT_CARD_FIELDS: {
            NUMBER: 'Credit card number',
            EXPIRATION: 'Expiration date',
            CVV: 'Security Code',
        },
        INFO_ICON:
            'We require that you enter your credit card verification number (CVV) to confirm that the payment is successful. Your CVV number can be found on the back of your credit card.',
        NO_PAYMENT_CONTACT_CLINIC:
            'There are no forms of payment enabled. Contact the clinic',
        ZELLE_PAYMENT: {
            ANTICIPATION_DAYS: 'Minimum of days allowed for advance payment',
            PM_CODE: 'PM+ code',
            IMPORTANT_MSG:
                '<b>Important: </b> Record this code in the comment field of the Zelle payment.',
            IMPORTANT_MSG_IMG:
                '<b>Important: </b> Record this code in the comment field of the Zelle payment.',
            COPY_CODE: 'Copy code',
            COPY_SUCCESS: 'Reconciliation code copied successfully',
            COPY_ERROR:
                'An error occurred while copying the reconciliation code, please try again',
            MAIL: 'Transfer Email',
            LAST_DIGITS: 'Last 4 digits of the account',
            TITULAR: 'Account holder name',
            DATE: 'Payment date',
            NOT_ALLOWED:
                'The anticipation time for payment method - appointment is less than stipulated, please use another payment method.',
            COMMENT: 'Comment',
            REFERENCE: 'Reference',
            AMOUNT: 'Amount Bs',
            ERROR: {
                REQUIRED: 'Field required',
                MIN: 'Minimun of characters required',
                EMAIL_INVALID: 'Not a valid email format',
                AMOUNT_INVALID: 'the amount must be greater than 0 Bs.',
                INVALID_DATE: 'Invalid date',
            },
        },
        DIRECTION: {
            UPDATE_MSG:
                'Do you want to save the direction in the patient profile?',
            BTN: {
                EDIT: 'Edit',
                ACCEPT: 'Accept',
            },
            ADD: {
                COUNTRY: 'Country',
                CITY: 'City',
                ADDRESS: 'Direction',
            },
        },
    },
};
