import { Injectable } from '@angular/core';
import { NetworkService } from 'app/services/network-service.service';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class SecretaryService {
    constructor(private networkService: NetworkService) {}

    // Home
    getAllCitas(desde, hasta): Observable<any> {
        return this.networkService.get(`ambulatorio/agenda/${desde}/${hasta}`);
    }

    // Config ----------------------------------------------------------------------------------------------

    /**
     * Registro de usuarios
     * @param body
     */
    registerUser(body: any): Observable<any> {
        return this.networkService.post(`users/register`, body);
    }

    /**
     * Registro de foto de usuarios
     * @param body
     */
    postUserPhoto(body: any): Observable<any> {
        return this.networkService.post(`foto/usuario`, body);
    }

    /**
     * Actualizacion de usuarios
     * @param body
     */
    updateUserConfig(body: any): Observable<any> {
        return this.networkService.put(`users/register`, body);
    }

    /**
     * Activar/desactivar usuario
     * @param body
     */
    updateUserStatus(body: any): Observable<any> {
        return this.networkService.put(`users/status`, body);
    }

    /**
     * Informacion del usuario
     */
    getUserInfo(id_user: string): Observable<any> {
        return this.networkService.get(`users/${id_user}`);
    }

    /**
     * Listado de médicos
     */
    getMedicList(filter?: any): Observable<any> {
        let f = '';
        if (filter) f = '?' + filter;
        return this.networkService.get(`medicos${f}`);
    }

    /**
     * Registrar un usuario como medico
     */
    registerMedic(body: any): Observable<any> {
        return this.networkService.post(`medico`, body);
    }

    /**
     * Actualizar informacion de usuario medico
     */
    updateMedic(body: any): Observable<any> {
        return this.networkService.put(`medico`, body);
    }

    /**
     * Informacion del medico
     */
    getMedicInfo(id_user: string): Observable<any> {
        return this.networkService.get(`medico/${id_user}`);
    }

    /**
     * Calendario del medico
     */
    getMedicCalendar(
        id_user: string,
        desde: string,
        hasta: string
    ): Observable<any> {
        return this.networkService.get(
            `medico/${id_user}/horario/${desde}/${hasta}`
        );
    }

    /**
     * Get info de paciente
     * @param body "ambulatorio": "string", "cita": "string", "email": "user@example.com"
     */
    postInvitacionVideollamada(body: any): Observable<any> {
        return this.networkService.post(`videollamada/invitacion`, body);
    }

    /**
     * Get info de paciente
     * @param idPaciente
     */
    getPacienteInfo(idPaciente: string): Observable<any> {
        return this.networkService.get(`pacientes/${idPaciente}`);
    }

    /**
     * Borrar horairo medico
     */
    deleteMedicCalendar(id_horario: string): Observable<any> {
        return this.networkService.delete(`horario/${id_horario}`);
    }

    /**
     * Listado de ambulatorios
     */
    getAmbulatorios(): Observable<any> {
        return this.networkService.get(`ambulatorio`);
    }

    /**
     * getConfig
     */
    getConfig(ambulatorio?): Observable<any> {
        return this.networkService.get(
            `config/summary${ambulatorio ? '?ambulatorio=' + ambulatorio : ''}`
        );
    }

    /**
     * Get all users
     */
    getUsers(): Observable<any> {
        return this.networkService.get('users?limit=10000');
    }

    /**
     * Lista de especialidad
     * @param filter
     */
    getSpecialtyList(filter?: any): Observable<any> {
        let f = '';
        if (filter) f = '?' + filter;
        return this.networkService.get(`especialidades${f}`);
    }

    /**
     * Registrar especialidad
     */
    registerSpecialty(body: any): Observable<any> {
        return this.networkService.post('especialidad', body);
    }

    /**
     * Actualizar especialidad
     * @param body
     */
    updateSpecialty(body: any): Observable<any> {
        return this.networkService.put('especialidad', body);
    }

    /**
     * Registrar especialidad
     */
    registerSpecialtyMedic(body: any): Observable<any> {
        return this.networkService.post('medico/especialidad', body);
    }

    /**
     * Actualizar especialidad
     * @param body
     */
    updateSpecialtyMedic(body: any): Observable<any> {
        return this.networkService.put('medico/especialidad', body);
    }

    /**
     * Registrar especialidad
     */
    deleteSpecialty(id_especialidad: string): Observable<any> {
        return this.networkService.delete(`especialidad/${id_especialidad}`);
    }

    /**
     * Retorna lista de paises
     */
    getPaises(): Observable<any> {
        return this.networkService.get('pais');
    }

    /**
     * Actualiza datos de una clinica / ambulatorio
     * @param body
     */
    updateClinic(body: any): Observable<any> {
        return this.networkService.put(`ambulatorio`, body);
    }

    /**
     * Retorna lista de ciudades de acuerdo al pais seleccionado
     * @param id_estado id del estado seleccionado
     */
    getCiudadesByPais(idPais): Observable<any> {
        return this.networkService.get(`pais/${idPais}/ciudades`);
    }

    /**
     * Registrar foto de clinica
     * @param body
     */
    postClinicPhoto(body: any): Observable<any> {
        return this.networkService.post(`foto/ambulatorio`, body);
    }

    /**
     * Registrar nuevo pais
     */
    postPais(body: any): Observable<any> {
        return this.networkService.post('pais', body);
    }

    /**
     * Lista de ciudades
     */
    getCiudad(uuid: string): Observable<any> {
        return this.networkService.get(``);
    }

    /**
     * Registrar nueva ciudad
     */
    postCiudad(body: any): Observable<any> {
        return this.networkService.post('ciudad', body);
    }

    /**
     * Update información de la clinica
     */
    getSubscripcion(uuid: string): Observable<any> {
        return this.networkService.get(``);
    }

    /**
     * Update información de la clinica
     */
    putClinic(body: any): Observable<any> {
        return this.networkService.put('ambulatorio', body);
    }

    /**
     * Registrar nuevo usuario
     */
    postUser(body: any): Observable<any> {
        return this.networkService.post('pacientes', body);
    }

    /**
     * Actualizar un nuevo usuario
     */
    updateUser(body: any): Observable<any> {
        return this.networkService.put('pacientes', body);
    }

    /**
     * Obtener foto de clinica
     * @param idAmbulatorio
     */
    getClinicPhoto(idAmbulatorio: string): Observable<any> {
        return this.networkService.get(`foto/ambulatorio/${idAmbulatorio}`);
    }

    /**
     * Obtiene listado de clinica / ambulatorios disponibles.
     */
    getModenadaList(): Observable<any> {
        return this.networkService.get(`moneda`);
    }

    /**
     * Listado de Clasificaciones de Medicos
     */
    getMedicClasificaciones(): Observable<any> {
        return this.networkService.get('clasificacionmedico');
    }

    /**
     * Obtener horario medico
     */
    getMedicHorario(
        id_medico: string,
        desde: string,
        hasta: string
    ): Observable<any> {
        return this.networkService.get(
            `/medico/${id_medico}/horario/${desde}/${hasta}`
        );
    }

    /**
     * Registrar un nuevo horario medico
     */
    postMedicHorario(body: any): Observable<any> {
        return this.networkService.post('medico/horario', body);
    }

    /**
     * Actualizar horario médico
     */
    putMedicHorario(body: any): Observable<any> {
        return this.networkService.put('medico/horario', body);
    }

    /**
     *
     * @param body imagen: imgbase64
     */
    registrarFirma(body: any): Observable<any> {
        return this.networkService.post('firma/usuario', body);
    }

    /**
     *
     * @param body imagen: imgbase64
     */
    getFirma(id_user: any): Observable<any> {
        return this.networkService.get(`firma/usuario/${id_user}`);
    }

    // Agenda ----------------------------------------------------------------------------------------------

    getPacienteById(id): Observable<any> {
        return this.networkService.get('pacientes/' + id);
    }

    searchPacientes(str): Observable<any> {
        return this.networkService.get('pacientes/busqueda/' + str);
    }

    getPacienteFoto(data): Observable<any> {
        return this.networkService.get('foto/paciente/' + data);
    }

    getCitas(desde, hasta): Observable<any> {
        return this.networkService.get(
            'medico/agendace' + '/' + desde + '/' + hasta
        );
    }

    getEspecialiadesMedicos(ambulatorio_id: string): Observable<any> {
        return this.networkService.get(
            `ambulatorio/${ambulatorio_id}/medicos_especialidades`
        );
    }

    postCita(data): Observable<any> {
        return this.networkService.post('citas/ce', data);
    }

    putCita(data): Observable<any> {
        return this.networkService.put('citas/ce', data);
    }

    deleteCita(cita): Observable<any> {
        return this.networkService.delete('citas/ce/' + cita);
    }

    getCupos(medico, especialidad, desde, hasta): Observable<any> {
        return this.networkService.get(
            `cupos/${medico}/${especialidad}/${desde}/${hasta}`
        );
    }

    getPacientesFiltrado(texto: string, limit?: string): Observable<any> {
        return this.networkService.get(
            `pacientes?filter=texto:like:${texto}&limit=${
                limit ? limit : '1000'
            }`
        );
    }

    /**
     * Registrar pacientes
     */
    postPacientes(body: any): Observable<any> {
        return this.networkService.post('pacientes', body);
    }

    /**
     * Obtiene datos detallos de una clinica / ambulatorio
     * @param idAmbulatory
     */
    getClinic(idAmbulatory: string): Observable<any> {
        return this.networkService.get(`ambulatorio/${idAmbulatory}`);
    }

    getTipoCita(
        medico: string,
        especialidad: string,
        paciente: string
    ): Observable<any> {
        return this.networkService.get(
            `tipocita/${medico}/${especialidad}/${paciente}`
        );
    }

    getMonedas(): Observable<any> {
        return this.networkService.get(`moneda`);
    }

    getPacientesByBirth(texto: string, date: string): Observable<any> {
        return this.networkService.get(
            `pacientes?filter=texto:like:${texto}|fecha_nac:eq:${date}&limit=200`
        );
    }

    /**
     * Enviar correo de confirmacion
     * @param body  "paciente": "string", "correo": "string", "contrasena": "string", "confirmar_contrasena": "string"
     */
    postConfirmarCorreo(body: any): Observable<any> {
        return this.networkService.post(`/paciente/correo/enviar`, body);
    }

    getDatosSecretaria(id: string): Observable<any> {
        return this.networkService.get(`secretaria/${id}`);
    }

    putDatosSecretaria(body): Observable<any> {
        return this.networkService.put(`secretaria`, body);
    }

    getPhoto(usuario_id: string): Observable<any> {
        return this.networkService.get(`foto/usuario/${usuario_id}`);
    }

    getTimezones(): Observable<any> {
        return this.networkService.get(`timezones`);
    }

    getExamenLaboratorio(): Observable<any> {
        return this.networkService.get('examenes/laboratorio');
    }

    getExamenLaboratorioListado(): Observable<any> {
        return this.networkService.get('examenes/laboratorio/listado');
    }

    getExamenLaboratorioPerfil(): Observable<any> {
        return this.networkService.get('examenes/laboratorio/perfil');
    }

    getExamenImagenes(): Observable<any> {
        return this.networkService.get('examenes/imagenes');
    }

    getExamenImagenesListado(): Observable<any> {
        return this.networkService.get('examenes/imagenes/listado');
    }

    getExamenImagenesPerfil(): Observable<any> {
        return this.networkService.get('examenes/imagenes/perfil');
    }

    postExams(body): Observable<any> {
        return this.networkService.post(`examenes/laboratorio`, body);
    }

    putExams(body): Observable<any> {
        return this.networkService.put(`examenes/laboratorio`, body);
    }

    postExamPerfil(body): Observable<any> {
        return this.networkService.post(`examenes/laboratorio/perfil`, body);
    }

    putExamPerfil(body): Observable<any> {
        return this.networkService.put(`examenes/laboratorio/perfil`, body);
    }

    deleteExams(body): Observable<any> {
        return this.networkService.post(`examenes/laboratorio/delete`, body);
    }

    deleteExamPerfil(body): Observable<any> {
        return this.networkService.post(`examenes/laboratorio/delete/perfil`, body);
    }

    // Clinica Area EPs
    postClinicaArea(body): Observable<any> {
        return this.networkService.post(`area`, body);
    }
    putClinicaArea(body): Observable<any> {
        return this.networkService.put(`area`, body);
    }
    deleteClinicaArea(id): Observable<any> {
        return this.networkService.delete(`area/${id}`);
    }
    getClinicaArea(): Observable<any> {
        return this.networkService.get(`area`);
    }
    getClinicaAreaById(id): Observable<any> {
        return this.networkService.get(`area/${id}`);
    }
    postAreaDetalle(body): Observable<any> {
        return this.networkService.post(`area/detalle`, body);
    }
    putAreaDetalle(body): Observable<any> {
        return this.networkService.put(`area/detalle`, body);
    }
    deleteAreaDetalle(idArea, idDetalle): Observable<any> {
        return this.networkService.delete(`area/detalle/${idArea}/${idDetalle}`);
    }
    getAreaDetalleNombreSugerido(idArea): Observable<any> {
        return this.networkService.get(`area/nextval/detalle/${idArea}`);
    }

    // -------------------------- EP de Dashboard Modulos -------------------------
    getDashboardInfo(modulo): Observable<any> {
        return this.networkService.get(`atencion/dashboard/${modulo}`);
    }

    // Usuarios Acceso EP
    putUsuarioAcceso(body): Observable<any> {
        return this.networkService.put(`users/register/access`, body);
    }

    // -------------------------- EP de Cita Quirurgica ---------------------------
    getCitasQuirurgicas(idArea, fecha_desde, fecha_hasta): Observable<any> {
        return this.networkService.get(
            `quirofano/planificacion/${idArea}/${fecha_desde}/${fecha_hasta}`
        );
    }

    getCitasQuirurgicasNoArea(fecha_desde, fecha_hasta): Observable<any> {
        return this.networkService.get(
            `quirofano/citas/${fecha_desde}/${fecha_hasta}`
        );
    }

    postCitaQuirurgica(body): Observable<any> {
        return this.networkService.post(`quirofano/cita`, body);
    }

    putCitaQuirurgica(body): Observable<any> {
        return this.networkService.put(`quirofano/cita`, body);
    }

    getCitaInfoQuirurgica(idCita): Observable<any> {
        return this.networkService.get(`quirofano/cita/${idCita}`);
    }

    putReagendarCitaQuirurgica(body): Observable<any> {
        return this.networkService.put(`quirofano/reagendar`, body);
    }

    postRegistrarParicipanteIntervencion(body): Observable<any> {
        return this.networkService.post(`quirofano/participantes`, body);
    }

    // Registrar Participante Cita
    postParticipanteCita(body): Observable<any> {
        return this.networkService.post(`quirofano/participantes`, body);
    }

    // Confirmar Cita
    putConfirmarCita(idCita, body): Observable<any> {
        return this.networkService.put(`cita/${idCita}/confirmar`, body);
    }

    // Ingresar Cita
    postIngresarCita(body): Observable<any> {
        return this.networkService.post(`atencion/ingreso/cita`, body);
    }

    // Prioridad y Transito
    getPrioridad(): Observable<any> {
        return this.networkService.get(`prioridad/iq`);
    }
    
    getTransito(): Observable<any> {
        return this.networkService.get(`transito/iq`);
    }

    // Intervenciones y diagnosticos
    getIntervenciones(): Observable<any> {
        return this.networkService.get(`intervenciones`);
    }

    getIntervencionesFiltered(filter): Observable<any> {
        return this.networkService.get(`intervenciones/${filter}`);
    }

    getDiagnosticos(descripcion): Observable<any> {
        return this.networkService.get(`diagnosticos?filter=descripcion:like:${descripcion}`);
    }

    // Roles Quirofano
    getRolesQuirofano(): Observable<any> {
        return this.networkService.get(`rol/iq`);
    }

    // Medics by IqRole
    getMedicsByIqRole(roleIq): Observable<any> {
        return this.networkService.get(`usuarios/busqueda/${roleIq}`);
    }

    // Medics by IqRole with filter
    getMedicsByIqRoleFilter(roleIq, filter): Observable<any> {
        return this.networkService.get(`usuarios/busqueda/${roleIq}/${filter}`);
    }

    // Medic Orders
    getMedicOrders(fecha_desde, fecha_hasta): Observable<any> {
        return this.networkService.get(`quirofano/emergencia/${fecha_desde}/${fecha_hasta}`);
    }

}
