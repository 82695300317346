import {
    Component,
    EventEmitter,
    Inject,
    OnInit,
    Output,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import {
    MatDialogRef,
    MAT_DIALOG_DATA,
    MatSnackBar,
    MatSnackBarVerticalPosition,
    MatSnackBarHorizontalPosition,
    MatTabGroup,
} from '@angular/material';
import { locale as english } from '../../i18n/en';
import { locale as spanish } from '../../i18n/es';
import { FuseConfigService } from '../../../../../@fuse/services/config.service';
import { FuseTranslationLoaderService } from '../../../../../@fuse/services/translation-loader.service';
import { takeUntil } from 'rxjs/operators';
import { PortalService } from '../../portal.service';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-event-tab-menu',
    templateUrl: './event-tab-menu.component.html',
    styleUrls: ['./event-tab-menu.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class EventTabMenuComponent implements OnInit {
    @Output() disableMenu = new EventEmitter();
    @ViewChild('tabs', { static: true }) tabs: MatTabGroup;
    data: any;
    showServicesCita = false;
    configAmbulatorio = {
        modulo_ventas: null,
        control_citas: null,
    };
    private unsubscribeAll = new Subject();

    constructor(
        public matDialogRef: MatDialogRef<any>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private snackBar: MatSnackBar,
        private service: PortalService,
        private _fuseConfigService: FuseConfigService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService
    ) {
        this.data = _data;
        this._fuseTranslationLoaderService.loadTranslations(english, spanish);
    }

    ngOnInit(): void {
        this.getAmbulatorioConfiguracion();
    }

    getAmbulatorioConfiguracion(): void {
        this._fuseConfigService
            .getConfig()
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((config) => {
                this.service
                    .getClinic(config.user.ambulatorio[0].ambulatorio)
                    .subscribe(
                        (res) => {
                            this.configAmbulatorio.modulo_ventas =
                                res.modulos.ventas;
                            this.configAmbulatorio.control_citas =
                                res.modulos.control_citas;
                            this.showServicesCita =
                                this.configAmbulatorio.modulo_ventas &&
                                this.data.hasOwnProperty('event');
                            if (this.data.action === 'services') {
                                this.tabs.selectedIndex = 1;
                            } else {
                            }
                        },
                        (err) => {
                            console.error(err);
                        }
                    );
            });
    }
}
