import {
    Component,
    OnInit,
    ChangeDetectorRef,
    ViewChild,
    EventEmitter,
    Output,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HistoryService } from '../../history.service';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { ITreeOptions } from 'angular-tree-component';
import { CitasService } from '../../citas/citas.service';
import { MatDialog, MatDialogRef, MatSnackBar } from '@angular/material';
import { CitasModalComponent } from 'app/main/portal/pacientes/citas-modal/citas-modal.component';
import { element } from 'protractor';
import { toInteger } from 'lodash';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as english } from './i18n/en';
import { locale as spanish } from './i18n/es';
import { EventTabMenuComponent } from '../../../../portal/calendario/event-tab-menu/event-tab-menu.component';
import { FuseConfigService } from '@fuse/services/config.service';
import { forkJoin, of, Subscription } from 'rxjs';
import {
    FormGroup,
    FormBuilder,
    Validators,
    FormControl,
} from '@angular/forms';
import { AddEventDialogComponent } from 'app/main/secretary/calendario/add-event-dialog/add-event.component';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { PatientService } from 'app/main/patient/patient.service';
import { fuseAnimations } from '@fuse/animations';
import { LandingPageService } from 'app/main/auth/landing/landing.service';
import { ConfirmarCitaDialogComponent } from './confirmar-cita/confirmar-cita.component';
import { C } from '@angular/cdk/keycodes';

@Component({
    selector: 'app-historial-citas',
    templateUrl: './historial_citas.component.html',
    styleUrls: ['./historial_citas.component.scss'],
    animations: fuseAnimations,
})
export class HistorialCitasComponent implements OnInit {
    // Variables publicas
    data: any;
    portal: boolean;
    useMoment = moment;
    node = [];
    filter = '';
    options: ITreeOptions = {
        // displayField: 'nodeName',
        isExpandedField: 'expanded',
        // idField: 'uuid',
        // hasChildrenField: 'nodes',
        // nodeHeight: 23,
        // allowDragoverStyling: true,
        // levelPadding: 10,
        // useVirtualScroll: true,
        animateExpand: true,
        // scrollOnActivate: true,
        animateSpeed: 15,
        // animateAcceleration: 1.2,
        // scrollContainer: document.documentElement, // HTML
    };
    view: any;
    paciente_id: any;

    @ViewChild('tree', { static: false }) tree;
    @Output() historicoCita = new EventEmitter();
    @Output() selectedHistory = new EventEmitter();
    dialogRef: MatDialogRef<any>;
    subscription: Subscription;
    fuseConfig: any;
    especialidades = [];
    selectedEspecialidades = [];
    tipoAtencion = '';
    especialidadCupos: any = [];
    lunesActual: any;
    medico: any;
    medicos: any;
    appointmentSelected: any;
    patient: any;
    pendientes: number;
    reschedule: boolean = false;
    display: boolean = true;

    colors = [
        {
            primary: 'rgba(3, 155, 228, 1)',
            secondary: 'rgba(3, 155, 228, 0.2)',
        },
        {
            primary: 'rgba(31, 171, 107, 1)',
            secondary: 'rgba(31, 171, 107, 0.2)',
        },
        {
            primary: 'rgba(247, 197, 0, 1)',
            secondary: 'rgba(247, 197, 0, 0.2)',
        },
        { primary: 'rgba(169, 78, 0, 1)', secondary: 'rgba(169, 78, 0, 0.2)' },
        {
            primary: 'rgba(200, 68, 95, 1)',
            secondary: 'rgba(200, 68, 95, 0.2)',
        },
    ];

    constructor(
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private historyService: HistoryService,
        private route: ActivatedRoute,
        private router: Router,
        private citasServ: CitasService,
        private translate: TranslateService,
        private matDialog: MatDialog,
        private config: FuseConfigService,
        private _formBuilder: FormBuilder,
        private snackBar: MatSnackBar,
        private progressBar: FuseProgressBarService,
        private patientService: PatientService,
        private landingService: LandingPageService
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, spanish);
        this.data = {
            historial: [],
        };
        this.paciente_id = this.route.snapshot.paramMap.get('paciente_id');
        this.view = this.route.snapshot.paramMap.get('view_id');
        this.subscription = this.config.getConfig().subscribe((config) => {
            this.fuseConfig = config;
            this.medico = config.user.id;
            if (config.isMedic) {
                this.especialidades = config.user.especialidades;
            } else {
                // this.getPatientInfo(this.paciente_id);
                this.getEspecialidades();
                this.getMedicos();
            }
            if (this.especialidades.length > 0)
                this.selectedEspecialidades = [...this.especialidades];

            if (!this.fuseConfig.modules.modulo_telemedicina) {
                this.tipoAtencion = 'C';
            } else {
                this.tipoAtencion = '';
            }
        });
    }

    ngOnInit(): void {
        this.router.url.includes('portal')
            ? (this.portal = true)
            : (this.portal = false);
        this.obtenerHistoricoCitas(this.paciente_id);
        this.lunesActual = moment().subtract(moment().day() - 1, 'days');
    }

    obtenerHistoricoCitas(paciente_id): void {
        this.progressBar.show();
        this.data.historial = [];
        this.historyService.getHistoricoCitas(paciente_id).subscribe(
            (res) => {
                this.progressBar.hide();
                this.data.historial = [...res];
                this.node = [];
                // this.prepareTree(res);
                this.year(res);
                this.month(res);
                this.day(res);
                this.clearEmpty();
                this.tree.treeModel.update();
            },
            (err) => {
                console.error(err);
            }
        );
    }

    // Evento que ocurre al hacer click en uno de los nodos
    onEvent($event): void {
        if (this.fuseConfig.isMedic) {
            const nodeParams = $event.node.id.split('/');
            const params = window.location.hash.split('/');

            if (
                $event.node.id.length > 10 &&
                this.view !== 'citas' &&
                !this.portal
            ) {
                window.open(
                    `${params[0]}/${params[1]}/${params[2]}/${params[3]}/${nodeParams[0]}/citas/${nodeParams[1]}/${nodeParams[0]}`,
                    '_blank'
                );
            } else if ($event.node.id.length > 10 && !this.portal) {
                this.router.navigate([
                    `${params[1]}/${params[2]}/${params[3]}/${nodeParams[0]}/citas/${nodeParams[1]}/${nodeParams[0]}`,
                ]);
                this.historicoCita.emit({
                    cita_id: nodeParams[0],
                    consulta_id: nodeParams[1],
                });
                this.citasServ.changeId(nodeParams[0]);
            } else if ($event.node.id.length > 10) {
                this.selectedHistory.emit({
                    paciente_id: this.paciente_id,
                    cita_id: nodeParams[0],
                    consulta_id: nodeParams[1],
                });
            }
        }
    }

    getEspecialidades(): void {
        this.historyService.getEspecialidad().subscribe((res) => {
            this.especialidades = res;
        });
    }

    getMedicos(): void {
        this.historyService.getMedico().subscribe((res) => {
            this.medicos = res;
        });
    }

    filterNodes(txt = this.filter): void {
        this.tree.treeModel.filterNodes((node) => {
            return node.data.name.toLowerCase().includes(txt.toLowerCase());
        });

        const hidden = Object.keys(this.tree.treeModel.hiddenNodeIds);

        hidden.forEach((ele) => {
            const node = this.tree.treeModel.getNodeById(ele);

            switch (node.level) {
                case 1:
                    break;

                case 2:
                    if (
                        node.parent.data.name
                            .toLowerCase()
                            .includes(txt.toLowerCase())
                    ) {
                        node.show();
                    }
                    break;

                case 3:
                    if (
                        node.parent.data.name
                            .toLowerCase()
                            .includes(txt.toLowerCase())
                    ) {
                        node.show();
                    } else {
                        const otherNode = this.tree.treeModel.getNodeById(
                            node.parent.data.id
                        );

                        if (
                            otherNode.parent.data.name
                                .toLowerCase()
                                .includes(txt.toLowerCase())
                        ) {
                            node.show();
                        }
                    }
                    break;
            }
        });
    }

    // Mapeo de años
    year(response: any[]): void {
        response.forEach((ele, i) => {
            const date = ele.fecha.split('-');
            if (i === 0) {
                this.node.push({
                    id: date[0],
                    name: date[0],
                    children: [],
                });
            } else if (this.node[this.node.length - 1].id !== date[0]) {
                this.node.push({
                    id: date[0],
                    name: date[0],
                    children: [],
                });
            }
        });
    }

    // Mapeo de meses por años
    month(response: any[]): void {
        response.forEach((ele) => {
            const date = ele.fecha.split('-');
            const index = (nodeElement) => nodeElement.id === date[0];

            if (this.node[this.node.findIndex(index)].children.length === 0) {
                this.node[this.node.findIndex(index)].children.push({
                    id: date[1],
                    name: this.getMonthName(toInteger(date[1])),
                    children: [],
                });
            } else if (
                this.node[this.node.findIndex(index)].children[
                    this.node[this.node.findIndex(index)].children.length - 1
                ].id !== date[1]
            ) {
                this.node[this.node.findIndex(index)].children.push({
                    id: date[1],
                    name: this.getMonthName(toInteger(date[1])),
                    children: [],
                });
            }
        });
    }

    // Mapeo de dia por meses
    day(response: any[]): void {
        // this.pendientes = 0;
        const month = moment(moment().subtract(1, 'month'));
        response.forEach((ele) => {
            // if (!ele.cerrada) {
            //     this.pendientes++;
            // }
            if (
                (moment(ele.fecha).isAfter(month) && !ele.cerrada) ||
                ele.cerrada
            ) {
                const date = ele.fecha.split('-');
                const indexYear = (nodeElement) => nodeElement.id === date[0];
                const indexMonth = (nodeElement) => nodeElement.id === date[1];

                const iY = this.node.findIndex(indexYear);
                const year = this.node[iY];

                this.node[iY].children[
                    year.children.findIndex(indexMonth)
                ].children.push({
                    id: ele.id + '/' + ele.tipo_consulta.id,
                    name: `${date[2].substring(0, 2)} - ${ele.especialidad.nombre_corto
                        } - ${ele.medico.nombre} - ${!ele.cerrada
                            ? this.translate.instant('HISTORY.PENDING')
                            : ele.plantilla_cita !== null
                                ? ele.plantilla_cita.nombre
                                : ele.nota_evolutiva
                                    ? this.translate.instant('HISTORY.EVO_NOTE')
                                    : this.translate.instant('HISTORY.MEDIC_HISTORY')
                        } `,
                    tipo_consulta: ele.tipo_consulta.id,
                    tipo_atencion: ele.tipo_atencion,
                    cerrada: ele.cerrada,
                });
            }
        });
    }

    clearEmpty(): void {
        this.node.forEach((ele) => {
            ele.children = ele.children.filter((child) => {
                if (child.children.length > 0) {
                    return child;
                }
            });
        });
    }

    // Retorna string con el nombre de mes
    getMonthName(num: number): string {
        switch (num) {
            case 1:
                return this.translate.instant('MONTH.JANUARY');
            case 2:
                return this.translate.instant('MONTH.FREBRUARY');
            case 3:
                return this.translate.instant('MONTH.MARCH');
            case 4:
                return this.translate.instant('MONTH.APRIL');
            case 5:
                return this.translate.instant('MONTH.MAY');
            case 6:
                return this.translate.instant('MONTH.JUNE');
            case 7:
                return this.translate.instant('MONTH.JULY');
            case 8:
                return this.translate.instant('MONTH.AUGUST');
            case 9:
                return this.translate.instant('MONTH.SEPTEMBER');
            case 10:
                return this.translate.instant('MONTH.OCTOBER');
            case 11:
                return this.translate.instant('MONTH.NOVEMBER');
            case 12:
                return this.translate.instant('MONTH.DECEMBER');
        }
    }

    onUpdateData(tree): void {
        if (
            window.location.href.includes('portal') ||
            window.location.href.includes('atencion/paciente/update')
        ) {
            tree.treeModel.expandAll();
        }
    }

    async modalReagendar(id, event) {
        event.preventDefault();
        event.stopPropagation();

        let el = await this.historyService
            .getCitaId(id.split('/')[0])
            .toPromise();

        let e = this._formBuilder.group({
            title: new FormControl(
                `${el.paciente.nombre_completo} - ${el.paciente.edad} - ${el.paciente.historia}`
            ),
            start: new FormControl(
                moment(el.fecha.split('T')[0] + ' ' + el.hora_desde).toDate()
            ),
            end: new FormControl(
                moment(el.fecha.split('T')[0] + ' ' + el.hora_hasta).toDate()
            ),

            color: new FormGroup({
                primary: new FormControl(this.colors[0].primary),
                secondary: new FormControl(this.colors[0].secondary),
            }),
            meta: new FormGroup({
                id_cita: new FormControl(el.id),
                medico: new FormControl(el.medico),
                tipo_cita: new FormControl(el.tipo_cita),
                motivo: new FormControl(el.motivo),
                estatus: new FormControl(el.estatus),
                cerrado: new FormControl(el.cerrado),
                paciente: new FormControl(el.paciente),
                telemedicina: new FormControl(el.telemedicina),
                especialidad: new FormGroup({
                    id: new FormControl(el.especialidad.id),
                    descripcion: new FormControl(el.especialidad.nombre),
                }),
                is_cupo: new FormControl(false),
                cuestionario_cita_completo: new FormControl(
                    el.cuestionario_cita_completo
                ),
                cuestionario_cita_template: new FormControl(
                    el.cuestionario_cita_template
                ),
                cuestionario_template: new FormControl(
                    el.cuestionario_template
                ),
                pais: new FormControl(el.pais),
                pais_nombre: new FormControl(el.pais_nombre),
                ciudad: new FormControl(el.ciudad),
                direccion: new FormControl(el.direccion),
                tipo_atencion: new FormControl({ id: el.tipo_atencion }),
                confirmada: new FormControl(el.confirmada),
                confirmada_fecha_mod: new FormControl(el.confirmada_fecha_mod),
                confirmada_usuario_mod: new FormControl(
                    el.confirmada_usuario_mod
                ),
                confirmada_usuario_mod_nombre: new FormControl(
                    el.confirmada_usuario_mod_nombre
                ),
            }),
            draggable: new FormControl(true),
        });

        if (this.fuseConfig.isMedic) {
            this.dialogRef = this.matDialog.open(EventTabMenuComponent, {
                width: '650px',
                panelClass: 'custom-dialog',
                autoFocus: false,
                data: {
                    action: 'edit',
                    especialidades: this.especialidades,
                    event: { ...e.value },
                    reagendar: true,
                    medico: this.medico,
                },
            });
            this.dialogRef.afterClosed().subscribe((response) => {
                if (!response) return;
                if (typeof response === 'boolean') {
                    this.obtenerHistoricoCitas(this.paciente_id);
                    // const newEvent = response.getRawValue();
                    // newEvent.actions = this.actions;
                    // this.events.push(newEvent);
                }
            });
        } else {
            this.rescheduleAppointment(el);
            // this.dialogRef = this.matDialog.open(AddEventDialogComponent, {
            //     width: '650px',
            //     maxHeight: '100vh',
            //     panelClass: 'custom-dialog',
            //     autoFocus: false,
            //     data: {
            //         action: 'edit',
            //         especialidades: this.especialidades,
            //         // selectedSpecialty: this.selectedEspecialidadId,
            //         medicos: this.medicos,
            //         event: { ...e.value },
            //     },
            // });
        }
    }

    rescheduleAppointment(appointment: any): void {
        this.reschedule = true;
        this.appointmentSelected = appointment;
        this.edit(this.appointmentSelected);
        this.getCupos(
            this.appointmentSelected.medico.id,
            this.appointmentSelected.especialidad.id,
            0,
            0
        );
    }

    edit(appointment: any): void {
        // this.editAppointment = appointment.id;
        const week = moment(appointment.fecha).week();
        this.lunesActual = moment().day('Monday').week(week);
    }

    dateChanged(date): void {
        this.lunesActual = date;
        this.getCupos(
            this.appointmentSelected.medico.id,
            this.appointmentSelected.especialidad.id,
            0,
            0
        );
    }

    getCupos(
        medic: string,
        specialty: string,
        medicIndex: number,
        specialtyIndex: number
    ): void {
        let fecha;
        let dias = 4;
        if (moment(this.lunesActual).isBefore(moment(), 'day')) {
            fecha = moment();
            dias = dias - (moment().day() - 1);
        } else {
            fecha = this.lunesActual;
        }

        this.progressBar.show();
        this.patientService
            .getCupos(
                medic,
                specialty,
                moment(fecha).format('YYYY-MM-DD'),
                moment(fecha).add(dias, 'days').format('YYYY-MM-DD')
            )
            .subscribe(
                (res) => {
                    const actualTime = moment();
                    this.progressBar.hide();
                    this.especialidadCupos = res.filter((ele) => {
                        const cupoTime = moment(
                            ele.fecha + 'T' + ele.hora_desde
                        );
                        if (true) {
                            return actualTime.isBefore(cupoTime);
                        }
                    });
                    // this.appointmentSelected.especialidad.cupos = res.filter(
                    //     (ele) => {
                    //         const cupoTime = moment(
                    //             ele.fecha + 'T' + ele.hora_desde
                    //         );
                    //         if (true) {
                    //             return actualTime.isBefore(cupoTime);
                    //         }
                    //     }
                    // );
                },
                (err) => {
                    this.progressBar.hide();
                    this.snackBar.open(err.error.message, '', {
                        verticalPosition: 'top',
                        duration: 3000,
                        panelClass: 'custom-red',
                    });
                }
            );
    }

    async agendar(cupo, info, especialidad, edit?: boolean): Promise<any> {
        this.display = false;
        this.progressBar.show();
        const especialidadInfo = this.appointmentSelected.especialidad;
        const precioConsulta = cupo.es_telemedicina
            ? especialidadInfo.precio_telemedicina
            : especialidadInfo.precio_consulta;
        const body = {
            cupo,
            info: {
                medico: info.nombre,
                medico_id: info.id,
                especialidad: especialidad.nombre,
                especialidad_id: especialidad.id,
                es_telemedicina: cupo.es_telemedicina,
                es_a_domicilio: cupo.es_a_domicilio,
                precio_consulta: precioConsulta,
            },
            motivo: this.appointmentSelected.motivo,
            medico: await this.patientService.getMedicInfo(info.id).toPromise(),
            paciente: this.paciente_id,
        };

        // Imagen del medico
        const userRes = await this.patientService
            .getUserInfo(info.id)
            .toPromise();
        body.medico.imagen =
            userRes.image !== ''
                ? userRes.image
                : 'assets/images/avatars/profile.jpg';

        this.display = true;
        this.progressBar.hide();
        this.confirmarCita(body, edit);
    }

    confirmarCita(body: any, edit?: boolean): void {
        const dialog = this.matDialog.open(ConfirmarCitaDialogComponent, {
            panelClass: 'custom-dialog',
            autoFocus: false,
            data: {
                body,
            },
        });

        dialog.afterClosed().subscribe(async (res) => {
            if (res === undefined) {
                return;
            }

            this.progressBar.show();
            const tipoCita = await this.landingService
                .getTipoCita(
                    body.info.medico_id,
                    body.info.especialidad_id,
                    this.appointmentSelected.paciente.id
                )
                .toPromise();

            const citaBody = {
                cita: this.appointmentSelected.id,
                paciente: this.appointmentSelected.paciente.id,
                tipo_cita: tipoCita.tipo_cita_ps,
                especialidad: body.info.especialidad_id,
                medico: body.info.medico_id,
                consultorio: body.cupo.consultorio_id,
                fecha: body.cupo.fecha,
                hora_cita: body.cupo.id_horario,
                motivo: res.motivo,
                tipo_atencion: body.cupo.es_telemedicina
                    ? 'T'
                    : body.cupo.es_a_domicilio
                        ? 'D'
                        : 'C',
                hora_desde: body.cupo.hora_desde,
            };

            if (citaBody.tipo_atencion === 'D') {
                if (res.appDireccion.pais) {
                    citaBody['pais'] = res.appDireccion.pais.id;
                } else {
                    citaBody['pais_nombre'] = res.appDireccion.customPais;
                }
                citaBody['ciudad'] = res.appDireccion.customCiudad;
                citaBody['direccion'] = res.appDireccion.direccion;
            }

            this.landingService.reagendarCita(citaBody).subscribe(
                (response) => {
                    this.progressBar.hide();
                    this.snackBar.open(response.message, '', {
                        verticalPosition: 'top',
                        duration: 3000,
                        panelClass: 'custom-green',
                    });
                    this.especialidadCupos = undefined;
                    this.appointmentSelected = undefined;
                    this.reschedule = false;
                    this.obtenerHistoricoCitas(this.paciente_id);
                    // this.patientUpdateService.emitChange(citaBody);
                },
                (err) => {
                    this.progressBar.hide();
                    this.snackBar.open(err.error.message, '', {
                        verticalPosition: 'top',
                        duration: 3000,
                        panelClass: 'custom-red',
                    });
                }
            );
        });
    }

    cancelReschedule(): void {
        this.especialidadCupos = undefined;
        this.appointmentSelected = undefined;
        this.reschedule = false;
        setTimeout(() => {
            this.tree.treeModel.expandAll();
        }, 100);
    }

    getPatientInfo(idPatient: string): void {
        this.historyService.getPacienteInfo(idPatient).subscribe((res) => {
            this.patient = { ...res };
        });
    }
}
