import { Component, OnInit, ChangeDetectorRef, Input, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';

@Component({
    selector: 'horas-listado-header',
    templateUrl: './horas-listado-header.component.html',
    styleUrls: ['./horas-listado-header.component.scss'],
})
export class HorasListadoHeaderComponent implements OnInit {
    lunesInicial = moment().subtract(moment().day() - 1, 'days');

    @Input('lunes') lunesActual = moment().subtract(moment().day() - 1, 'days');
    @Output() data = new EventEmitter;

    constructor() {}

    ngOnInit() {}

    subtractWeek() {
        this.lunesActual = moment(this.lunesActual).subtract(1, 'week');
        this.data.emit(this.lunesActual);
    }

    addWeek() {
        this.lunesActual = moment(this.lunesActual).add(1, 'week');
        this.data.emit(this.lunesActual);
    }
    
    getLunesActual(offset) {
        return moment(this.lunesActual).locale('es').add(offset, 'days').format('MMM DD');
    }

    isSameMonday() {
        return moment(this.lunesActual).isSame(this.lunesInicial, 'day');
    }

    currentDay(offset) {
        return moment(this.lunesActual).add(offset, 'days').isSame(moment(), 'day') ? { 'background-color': '#8acce3', 'border-color': '#8acce3', 'color': 'white' } : {};
    }
}
