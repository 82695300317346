export const locale = {
    lang: 'en',
    data: {
        ITEMS: 'Items per page',
        NEXT_PAGE: 'Next page',
        PREVIOUS_PAGE: 'Previous page',
        OF: 'of',
        FIRST_PAGE: 'First page',
        LAST_PAGE: 'Last page',
    },
};
