import { Injectable } from '@angular/core';
import { NetworkService } from 'app/services/network-service.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class IngresoService {
    public actualView:
        | 'motivo'
        | 'enfermedad'
        | 'dxi'
        | 'dxe'
        | 'examen'
        | 'laboratorio'
        | 'imagenes'
        | 'plan'
        | 'recipe'
        | 'historia'
        | 'anexos'
        | 'chat'
        | 'informes_medicos'
        | '' = '';

    private patientSignos: any;

    private tratamientoIdBehavior = new BehaviorSubject({});
    tratamientoId = this.tratamientoIdBehavior.asObservable();

    private modelBehavior = new BehaviorSubject({});
    model = this.modelBehavior.asObservable();
    constructor() {
        this.modelBehavior.next({});
    }

    // Retornar valor de signos vitales
    getpatientSignos(): void {
        return this.patientSignos;
    }

    // setear valor de signos vitales
    setpatientSignos(body: any): void {
        this.patientSignos = body;
    }

    /**
     * Utilizado para actualizar la variable
     * @param param {type: 'string', anchor: 'HTMLElement', blobFile: Blob}
     */
    updateModel(param: any): void {
        this.modelBehavior.next(param);
    }

    /**
     * Utilizado para actualizar la variable de tratamiento Id
     * @param param string
     */
    updateTratamientoId(param: any): void {
        this.tratamientoIdBehavior.next(param);
    }
}
