export const locale = {
    lang: 'es',
    data: {
        MAIN: {
            HIDE: 'Esconder',
            BG_COLOR: 'Color de fondo',
            POSITION: 'Posición',
            VARIANT: 'Variante',
            LEFT: 'Izquierda',
            RIGHT: 'Derecha',
            STYLE: 'Estilo',
            SETTINGS: 'Ajustes',
        },
        THEME: {
            TITLE: 'Temas de colores',
            DEFAULT: 'Default',
            LIGHT_GREEN: 'Verde claro',
            DARK_GREEN: 'Verde oscuro',
            YELLOW: 'Amarillo',
            CUSTOM: 'Personalizado',
        },
        LAYOUT: {
            LAYOUT_STYLES: 'Estilos de diseño',
        },
        MENU_BAR: {
            TITLE: 'Barra de menú',
            FOLDED: 'Doblado',
            ABOVE: 'Arriba',
            BELOW_STATIC: 'Abajo estático',
            BELOW_FIXED: 'Abajo fijo',
        },
        SALES_NAV: {
            TITLE: 'Barra de navegación. Módulo ventas',
            FOLDED: 'Doblado',
            FIRST_BG: 'Fondo primario',
            SECOND_BG: 'Fondo secundario',
        },
    },
};
