import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationDialogComponent } from 'app/modules/shared/components/confirmation-dialog/confirmation-dialog.component';
import { isArray } from 'lodash';

@Component({
    selector: 'app-navigate-appointment-dialog',
    templateUrl: './navigate-appointment-dialog.component.html',
    styleUrls: ['./navigate-appointment-dialog.component.scss'],
})
export class NavigateAppointmentDialogComponent implements OnInit {
    skipParams = ['cita_id', 'paciente_id', 'tiene_cama'];
    constructor(
        public matDialogRef: MatDialogRef<NavigateAppointmentDialogComponent>,
        private matDialog: MatDialog,
        private translate: TranslateService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    ngOnInit() {
        if (this.data.nota_evolutiva) {
            this.skipParams.push('motivo');
        }
    }

    openDialog(): void {
        if (this.emptyModel()) {
            this.matDialogRef.close('close');
            return;
        }
        const dialogRef = this.matDialog.open(ConfirmationDialogComponent, {
            width: '280px',
            panelClass: 'custom-dialog',
            autoFocus: false,
            data: {
                text: this.translate.instant('DIALOG.LOSE_CHANGES'),
            },
        });
        const sub = dialogRef.afterClosed().subscribe((res) => {
            if (res) {
                this.matDialogRef.close('close');
            } else {
                this.matDialogRef.close();
            }
            sub.unsubscribe();
        });
    }

    getInnerText(text): string {
        if (text) {
            const div = document.createElement('div');
            div.innerHTML = text;
            return div.textContent;
        }
        return '';
    }

    /**
     * Valida que el modelo este vacio
     */
    emptyModel(): boolean {
        for (const i in this.data) {
            if (!this.skipParams.includes(i)) {
                if (isArray(this.data[i]) && this.data[i].length > 0) {
                    return false;
                } else if (!isArray(this.data[i])) {
                    switch (typeof this.data[i]) {
                        case 'string':
                            if (this.getInnerText(this.data[i]) !== '') {
                                return false;
                            }
                            break;
                        case 'object':
                            for (const ele of this.data[i].pendientes) {
                                if (ele.actual) {
                                    return false;
                                }
                            }
                            break;
                    }
                }
            }
        }
        return true;
    }
}
