import { Injectable } from '@angular/core';
import {
    HttpClient,
    HttpHeaders,
    HttpEventType,
    HttpParams,
} from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { fuseConfig } from '../fuse-config';
import { map } from 'rxjs/internal/operators/map';

export interface RequestFileCustom {
    body?: any;
    headers?: HttpHeaders | { [header: string]: string | Array<string> };
    observe?: any;
    params?: HttpParams | { [param: string]: string | Array<string> };
    reportProgress?: boolean;
    responseType?: any;
    withCredentials?: boolean;
}

@Injectable({
    providedIn: 'root',
})
export class NetworkService {

    private showSidebar = new BehaviorSubject<boolean>(true);
    private showSidebar$ = this.showSidebar.asObservable();

    private patientsFilter = new BehaviorSubject<boolean>(false);
    private patientsFilter$ = this.patientsFilter.asObservable();

    constructor(private HttpClient: HttpClient) {}

    setShowSidebar(show: boolean) {
        return this.showSidebar.next(show);
    }

    getShowSidebar(): Observable<boolean> {
        return this.showSidebar$;
    }

    setPatientsFilter(filter: boolean) {
        return this.patientsFilter.next(filter);
    }

    getPatientsFilter(): Observable<boolean> {
        return this.patientsFilter$;
    }

    getToken(): string {
        const partialToken = document.cookie
            .substr(document.cookie.indexOf('token'))
            .split('=')[1];
        const token = partialToken
            ? partialToken.indexOf(';') !== -1
                ? partialToken.substring(0, partialToken.indexOf(';'))
                : partialToken
            : '';
        return token;
    }

    get(endpoint): Observable<any> {
        const token = this.getToken();
        const headers = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: token,
                clientkey: fuseConfig.API_KEY,
            }),
            withCredentials: true,
        };

        return this.HttpClient.get(fuseConfig.URL + endpoint, headers);
    }

    post(endpoint, data?): Observable<any> {
        const token = this.getToken();
        const headers = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: token,
                clientkey: fuseConfig.API_KEY,
            }),
            withCredentials: true,
        };

        return this.HttpClient.post(fuseConfig.URL + endpoint, data, headers);
    }

    put(endpoint, data?): Observable<any> {
        const token = this.getToken();
        const headers = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: token,
                clientkey: fuseConfig.API_KEY,
            }),
            withCredentials: true,
        };

        return this.HttpClient.put(fuseConfig.URL + endpoint, data, headers);
    }

    'responseType': 'text';
    patch(endpoint, data?): Observable<any> {
        const token = this.getToken();
        const headers = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: token,
                clientkey: fuseConfig.API_KEY,
            }),
            withCredentials: true,
        };

        return this.HttpClient.patch(fuseConfig.URL + endpoint, data, headers);
    }

    delete(endpoint): Observable<any> {
        const token = this.getToken();
        const headers = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: token,
                clientkey: fuseConfig.API_KEY,
            }),
            withCredentials: true,
        };

        return this.HttpClient.delete(fuseConfig.URL + endpoint, headers);
    }

    file(endpoint, formdata): Observable<any> {
        const token = this.getToken();
        const headers: RequestFileCustom = {
            headers: new HttpHeaders({
                Authorization: token,
                clientkey: fuseConfig.API_KEY,
            }),
            withCredentials: true,
            reportProgress: true,
            observe: 'events',
        };

        return this.HttpClient.post(
            fuseConfig.URL + endpoint,
            formdata,
            headers
        ).pipe(
            map((event: any) => {
                switch (event.type) {
                    case HttpEventType.UploadProgress:
                        const progress = Math.round(
                            (100 * event.loaded) / event.total
                        );
                        return { status: 'progress', message: progress };

                    case HttpEventType.Response:
                        return event.body;

                    default:
                        return `Unhandled event: ${event.type}`;
                }
            })
        );
    }

    getFile(endpoint): Observable<any> {
        const token = this.getToken();
        const headers: RequestFileCustom = {
            headers: new HttpHeaders({
                Authorization: token,
                clientkey: fuseConfig.API_KEY,
            }),
            withCredentials: true,
            responseType: 'blob',
        };

        return this.HttpClient.get(fuseConfig.URL + endpoint, headers);
    }

    postForm(endpoint, data?): Observable<any> {
        const token = this.getToken();
        const headers = {
            headers: new HttpHeaders({
                // 'Content-Type': 'multipart/form-data',
                Authorization: token,
                clientkey: fuseConfig.API_KEY,
            }),
            withCredentials: true,
        };

        return this.HttpClient.post(fuseConfig.URL + endpoint, data, headers);
    }

    putForm(endpoint, data?): Observable<any> {
        const token = this.getToken();
        const headers = {
            headers: new HttpHeaders({
                // 'Content-Type': 'multipart/form-data',
                Authorization: token,
                clientkey: fuseConfig.API_KEY,
            }),
            withCredentials: true,
        };

        return this.HttpClient.put(fuseConfig.URL + endpoint, data, headers);
    }

    postAndGetBlob(endpoint, data?): Observable<any> {
        const token = this.getToken();
        const headers = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: token,
                clientkey: fuseConfig.API_KEY,
            }),
            withCredentials: true,
            responseType: 'blob' as any,
        };

        return this.HttpClient.post(fuseConfig.URL + endpoint, data, headers);
    }
}
