export const locale = {
    lang: 'en',
    data: {
        MAIN: { LOADING_PLEASE_WAIT: 'Loading, please wait' },
        DIALOG: {
            CONFIRM: 'Confirm',
            ACCEPT: 'Accept',
            CANCEL: 'Cancel',
            CLOSE: 'Close',
            REPLACE: 'Replace',
            NEXT: 'Next',
            PRECONSULT: 'Pre-consult',
            REGISTERED_BY: 'Registered by',
            MODIFIED_BY: 'Modified by',
            APPOINTMENT_SUCCESS_MSG: 'Your appointment has been scheduled',
            PAYMENT_SUCCESS_MSG: 'The payment was made successfully',
            SUCCESS_FORM: 'Updated questionnaire',
        },
        MAIL_DIALOG: {
            CLOSE: 'Close',
            EMAIL_TO: 'Email',
            AND: 'and',
            X_OTHERS: 'other(s)',
            INPUT_ADDR: 'Type email addresses',
            SEND: 'Send',
            CANCEL: 'Cancel',
            SEND_TO: 'To',
            SUBJECT: 'Subject',
            FROM: 'From',
            SELECT_SENDER: 'Select sender',
            CARBON_COPY: 'Cc',
            BLIND_CARBON_COPY: 'Bcc',
            ATTACH_INVOICE_PDF: 'Attach receipt PDF',
            ATTACH_FILE_PDF: 'Attach PDF file',
            ATTACH_FILES: 'Attach files',
            ADD_FILE: 'Add file',
            REMOVE_FILE: 'Remove file',
            PREVIEW_FILE: 'Preview',
        },
        QUILL: {
            FORMAT_OPTIONS: 'More Options',
            LINK: 'Insert link',
            IMAGE: 'Insert image',
            FONT: 'Font Family',
            SIZE: 'Font Size',
            SMALL: 'Small',
            LARGE: 'Large',
            BIG: 'Big',
            BOLD: 'Bold',
            ITALIC: 'Italic',
            UNDERLINE: 'Underline',
            TEXT_COLOR: 'Text Color',
            BACKGROUND_COLOR: 'Background Color',
            ALIGNMENT: 'Alignment',
            LIST_ORDERED_NUMBER: 'List Ordered Number',
            LIST_ORDERED_BULLET: 'List Ordered Bullet',
            MORE_INDENT: 'Increase Indent',
            DECREASE_INDENT: 'Decrease Indent',
            QUOTE: 'Quote',
            STRIKETHROUGH: 'Strikethrough',
            CLEAR: 'Clear Format',
            UNDO: 'Undo',
            REDO: 'Redo',
        },
        PRECONSULT: {
            TITLE: 'Pre-consult',
        },
    },
};
