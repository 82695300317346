import { Injectable } from '@angular/core';
import { NetworkService } from 'app/services/network-service.service';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class PortalService {
    constructor(private networkService: NetworkService) {}

    // EP Home

    /**
     * Obtiene lista de citas de Consulta externa aisgnadas a un medico.
     * @param desde Date string desde fecha
     * @param hasta Date string hasta fecha
     */
    getAgendaCE(desde: string, hasta: string): Observable<any> {
        return this.networkService.get(`medico/agendace/${desde}/${hasta}`);
    }

    /**
     * Obtiene lista de citas de Consulta externa aisgnadas a un medico.
     * @param medico UUID del medico que consulta
     * @param hastambulatorioa UUID del ambulatorio
     * @param desde Date string desde fecha
     * @param hasta Date string hasta fecha
     */
    getListaEspera(
        medico: string,
        ambulatorio: string,
        desde: string,
        hasta: string
    ): Observable<any> {
        return this.networkService.get(
            `lista_espera/medico/${medico}/${ambulatorio}/${desde}/${hasta}`
        );
    }

    getEstadisticasCE(desde: string, hasta: string): Observable<any> {
        return this.networkService.get(
            `medico/estadisticasce/${desde}/${hasta}`
        );
    }

    // EP pacientes

    /**
     * Retorna una lista de los apcientes registardos en sistema.
     */
    getPacientes(): Observable<any> {
        return this.networkService.get('pacientes');
    }

    /**
     * Listar pacientes filtrados por nombre y apellido
     */
    getPacientesFilter(nombre: string, apellido: string): Observable<any> {
        return this.networkService.get(
            `pacientes?filter=nombre:like:${nombre}|apellido:like:${apellido}&limit=100`
        );
    }

    /**
     * Retorna una lista de los pacientes con filtro.
     */
    // getPacientesFiltrado(
    //     nombre: string,
    //     apellido: string,
    //     historia: string,
    //     id: string
    // ): Observable<any> {
    //     return this.networkService.get(
    //         `pacientes?filter=nombre:like:${nombre}|apellido:like:${apellido}|historia:like:${historia}|identificacion:like:${id}&limit=1000`
    //     );
    // }
    getPacientesFiltrado(texto: string, limit?: string): Observable<any> {
        return this.networkService.get(
            `pacientes?filter=texto:like:${texto}&limit=${
                limit ? limit : '1000'
            }`
        );
    }

    getPacientesByBirth(texto: string, date: string): Observable<any> {
        return this.networkService.get(
            `pacientes?filter=texto:like:${texto}|fecha_nac:eq:${date}&limit=200`
        );
    }

    getPacienteById(id): Observable<any> {
        return this.networkService.get('pacientes/' + id);
    }

    searchPacientes(str): Observable<any> {
        return this.networkService.get('pacientes/busqueda/' + str);
    }

    getPacienteFoto(data) {
        return this.networkService.get('foto/paciente/' + data);
    }

    getCitas(desde, hasta) {
        return this.networkService.get(
            'medico/agendace' + '/' + desde + '/' + hasta
        );
    }

    postCita(data) {
        return this.networkService.post('citas/ce', data);
    }

    putCita(data) {
        return this.networkService.put('citas/ce', data);
    }

    deleteCita(cita) {
        return this.networkService.delete('citas/ce/' + cita);
    }

    deleteCitaData(cita) {
        return this.networkService.delete(`citas/ce/${cita}/${true}`);
    }

    /**
     * Retorna lista de paises
     */
    getPaises(): Observable<any> {
        return this.networkService.get('pais');
    }

    /**
     * Retorna lista de estados de acuerdo al pais seleccionado
     * @param id_pais id del pais seleccionado
     */
    getEstados(id_pais): Observable<any> {
        return this.networkService.get(`estado/${id_pais}`);
    }

    /**
     * Retorna lista de ciudades de acuerdo al estado seleccionado
     * @param id_estado id del estado seleccionado
     */
    getCiudades(id_estado): Observable<any> {
        return this.networkService.get(`ciudad/${id_estado}`);
    }

    /**
     * Retorna lista de ciudades de acuerdo al pais seleccionado
     * @param id_estado id del estado seleccionado
     */
    getCiudadesByPais(idPais): Observable<any> {
        return this.networkService.get(`pais/${idPais}/ciudades`);
    }

    /**
     * Registrar pacientes
     */
    postPacientes(body: any): Observable<any> {
        return this.networkService.post('pacientes', body);
    }

    /**
     * Actualizar pacientes
     */
    updatePacientes(body: any): Observable<any> {
        return this.networkService.put('pacientes', body);
    }

    /**
     * Listar las citas planificadas del Usuario logeado.
     */
    getInformes(paciente_id: string): Observable<any> {
        return this.networkService.get(`pacientes/${paciente_id}/informes`);
    }

    // Anexos

    /**
     * Listar tipos de documento para anexar.
     */
    getDocTipo(): Observable<any> {
        return this.networkService.get(`tipoanexo`);
    }

    /**
     * Registrar anexos.
     */
    postAnexos(body: any): Observable<any> {
        return this.networkService.postForm(`pacientes/anexos`, body);
    }

    /**
     * Enviar correo con pdf.
     */
    postSendPDf(body: any): Observable<any> {
        return this.networkService.postForm(`pacientes/informes/correo`, body);
    }

    /**
     * Actualizar anexos.
     */
    putAnexos(body: any): Observable<any> {
        return this.networkService.putForm(`pacientes/anexos`, body);
    }

    /**
     * Listado de médicos
     */
    getMedicList(filter?: any): Observable<any> {
        let f = '';
        if (filter) f = '?' + filter;
        return this.networkService.get(`medicos${f}`);
    }

    getSpecialtyList(filter?: any): Observable<any> {
        let f = '';
        if (filter) f = '?' + filter;
        return this.networkService.get(`especialidades${f}`);
    }

    getCupos(medico, especialidad, desde, hasta): Observable<any> {
        return this.networkService.get(
            `cupos/${medico}/${especialidad}/${desde}/${hasta}`
        );
    }

    /**
     * Calendario del medico
     */
    getMedicCalendar(
        id_user: string,
        desde: string,
        hasta: string
    ): Observable<any> {
        return this.networkService.get(
            `medico/${id_user}/horario/${desde}/${hasta}`
        );
    }

    /**
     * Actualizar dato de Medico (Online)
     * @param body {id: uuid, on_line: boolean}
     */
    putMedicoStatus(body: any): Observable<any> {
        return this.networkService.put(`medico/online`, body);
    }

    /**
     * Informacion del medico
     */
    getMedicInfo(id_user: string): Observable<any> {
        return this.networkService.get(`medico/${id_user}`);
    }

    getTipoCita(
        medico: string,
        especialidad: string,
        paciente: string
    ): Observable<any> {
        return this.networkService.get(
            `tipocita/${medico}/${especialidad}/${paciente}`
        );
    }

    /**
     * Servicios de cita
     */
    getServiciosCita(idCita: string): Observable<any> {
        return this.networkService.get(`/citas/${idCita}/servicios`);
    }

    postServiciosCita(idCita: string, data: any): Observable<any> {
        return this.networkService.postForm(`/citas/${idCita}/servicios`, data);
    }

    getClinic(idAmbulatory: string): Observable<any> {
        return this.networkService.get(`ambulatorio/${idAmbulatory}`);
    }

    deleteServiciosCita(
        idCita: string,
        idServicioCita: string
    ): Observable<any> {
        return this.networkService.delete(
            `citas/${idCita}/servicios/${idServicioCita}`
        );
    }

    // Lista de espera
    postAtender(body: any): Observable<any> {
        return this.networkService.post(`lista_espera/atender`, body);
    }

    deleteListaEspera(idLista: string): Observable<any> {
        return this.networkService.delete(`lista_espera/${idLista}`);
    }

    /**
     * Marca el inicio de la Atencion Medica
     */
    putAtenderCita(idCita: string): Observable<any> {
        return this.networkService.put(`citas/${idCita}/atender`);
    }

    /**
     * Información completa de usuario.
     */
    getPacienteInfo(paciente_id: string): Observable<any> {
        return this.networkService.get(`pacientes/${paciente_id}`);
    }

    /**
     * Descarga de archivos pdf (informes).
     */
    downloadPdf(url: string): Observable<any> {
        return this.networkService.getFile(`${url}`);
    }

    /**
     *
     * @param body
     * @returns
     */
    postOrdenMedicaLaboratorio(body): Observable<any> {
        return this.networkService.post('ordenesmedicas/laboratorio', body);
    }

    /**
     *
     * @param body
     * @returns
     */
    postOrdenMedicaImagenes(body): Observable<any> {
        return this.networkService.post('ordenesmedicas/imagen', body);
    }

    /**
     * Impresion de ordenes medicas
     * @param body paciente: string <uuid>, cita: string <uuid>, solicitudes: Array of string <uuid>
     */
    printOrdenMedica(body): Observable<any> {
        return this.networkService.postAndGetBlob(
            'resumen_historia/print/solicitud',
            body
        );
    }

    /**
     * Listado de informes por filtro (Utilizado por medico y secretaria) filtros paciente, medico
     * @param paciente
     */
    getPatientInformes(pacienteId): Observable<any> {
        return this.networkService.get(
            `pacientes/informes?limit=400&paciente_id=${pacienteId}`
        );
    }

    /**
     * Registrar recipe para paciente
     * @param paciente
     */
    postRecipe(body: any): Observable<any> {
        return this.networkService.post(`paciente/recipe`, body);
    }

    /**
     * Editar recipe para paciente
     * @param paciente
     */
    putRecipe(body: any): Observable<any> {
        return this.networkService.put(`paciente/recipe`, body);
    }

    /**
     * Eliminar recipe para paciente
     * @param paciente
     */
    deleteRecipe(idRecipe: string): Observable<any> {
        return this.networkService.delete(`paciente/recipe/${idRecipe}`);
    }


    // Ingreso de pacientes
    postIngresoPaciente(body: any): Observable<any> {
        return this.networkService.post('atencion/ingreso/paciente', body);
    }

    putIngresoPaciente(body: any): Observable<any> {
        return this.networkService.put('atencion/ingreso/paciente', body);
    }

    getIngresoPaciente(modulo: "Emergencia" | "Hospitalizacion" | "Cirugia", 
        status: "ingresado" | "egresado" | "espera" | "otros" | "pendiente") : Observable<any> {
        return this.networkService.get(`atencion/paciente/${modulo}/${status}`);
    }

    // Manejo de Citas
        // Ingresar Cita (pacientes pedientes a ingresados)
    postIngresoCita(body: any): Observable<any> {
        return this.networkService.post('atencion/ingreso/cita', body);
    }
        // Reversar Cita (Pacientes Ingresados que pasan a pendientes)
    deleteIngresoCita(body: any): Observable<any> {
        return this.networkService.put('atencion/ingreso/cita', body);
    }
        // Omitir (Pacientes Ingresados que pasan a omitidos, se eliminan del listado)
    putCitasOmitir(body: any): Observable<any> {
        return this.networkService.put('citas/omitir', body);
    }

    // Agregar a sala de espera
    postSalaEspera(body: any): Observable<any> {
        return this.networkService.post('atencion/sala_espera', body);
    }

    putSalaEspera(body: any): Observable<any> {
        return this.networkService.put('atencion/sala_espera', body);
    }

    putEditSalaEspera(espera_id: string, body: any): Observable<any> {
        return this.networkService.put(`atencion/sala_espera/${espera_id}`, body);
    }

    deleteSalaEspera(espera_id: string): Observable<any> {
        return this.networkService.delete(`atencion/sala_espera/${espera_id}`);
    }

    // Ingresar paciente desde egresados
    putReingresoPaciente(cita_id: any, body?:any): Observable<any> {
        return this.networkService.put(`citas/${cita_id}/reingreso`, body);
    }

    // Reingresar paciente a sala de espera desde otros
    moveSalaEspera(otros_id: string, body: any): Observable<any> {
        return this.networkService.put(`atencion/sala_espera/${otros_id}`, body);
    }

    // Obtener columnas de la tabla
    getTableColumnsList(): Observable<any> {
        return this.networkService.get(`atencion/columna`);
    }

    // Actualizar columnas de la tabla
    putTableColumnsList(body: any): Observable<any> {
        return this.networkService.put(`atencion/columna`, body);
    }

    // Obtener lista de niveles de urgencia
    getUrgenciaList(): Observable<any> {
        return this.networkService.get(`nivelurgencia`);
    }

    // Obtener la data de un paciente de una cita
    getCitaData(cita_id: string): Observable<any> {
        return this.networkService.get(`citas/${cita_id}/paciente`);
    }

    getVitals(cita_id: string, signo_id: string, fecha?: string): Observable<any> {
        return this.networkService.get(`citas/${cita_id}/signos/${signo_id}?desde:gte=${fecha}`);
    }

    postVitals(cita_id: string, body: any): Observable<any> {
        return this.networkService.post(`citas/${cita_id}/signos`, body);
    }

    /**
     * Enviar correo de confirmacion
     * @param body  "paciente": "string", "correo": "string", "contrasena": "string", "confirmar_contrasena": "string"
     */
    postConfirmarCorreo(body: any): Observable<any> {
        return this.networkService.post(`paciente/correo/enviar`, body);
    }

    /**
     * Lista de templates para citas
     */
    getTemplates(medicoId: string): Observable<any> {
        return this.networkService.get(
            `template_cita?filter=medico:eq:${medicoId}`
        );
    }

    /**
     * Busqueda de template por id
     */
    getTemplatesById(id: string): Observable<any> {
        return this.networkService.get(`template_cita/${id}`);
    }

    /**
     * Asociar template a una cita
     * @param body template_cita: uuid, cita: uuid, definicion: objeto (json, pero enviar como un objeto), secciones_fijas: objeto (json, pero enviar como un objeto, null), respuesta: objeto (json, pero enviar como un objeto, null)
     */
    postTemplatesCita(body: any): Observable<any> {
        return this.networkService.post(`cita_template`, body);
    }

    postPaidCita(body: any): Observable<any> {
        return this.networkService.post(`venta/paid-cita`, body);
    }

    putStatusCita(idCita: string, body: any): Observable<any> {
        return this.networkService.put(`cita/${idCita}/confirmar`, body);
    }

    /**
     * Obtener exportacion a csv o xlsx
     * @param body JSON con los parametros u optiones de exportacion necesarios
     * @returns
    */
    downloadExcel(body: any): Observable<any>{
        return this.networkService.postAndGetBlob('paciente/exportar', body);
    }
}
