import { Injectable } from '@angular/core';
import { NetworkService } from 'app/services/network-service.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class PatientService {
    private medicOnline = new BehaviorSubject(false);
    medicOnlineSub = this.medicOnline.asObservable();
    constructor(private networkService: NetworkService) {}

    updateMedicStatus(value: boolean): void {
        this.medicOnline.next(value);
    }

    /**
     * Listar las citas planificadas del Usuario logeado.
     */
    getCitasCe(): Observable<any> {
        return this.networkService.get(`citas/ce`);
    }

    getPatientCitas(pacienteId: string): Observable<any> {
        return this.networkService.get(`citas/paciente/${pacienteId}`);
    }

    /**
     * Listar las citas planificadas del Usuario logeado.
     */
    getInformes(paciente_id?: string): Observable<any> {
        if (paciente_id) {
            return this.networkService.get(
                `pacientes/${paciente_id}/informes?limit=10000`
            );
        }
        return this.networkService.get(`pacientes/informes?limit=10000`);
    }

    getInformesNew(paciente_id?: string): Observable<any> {
        if (paciente_id) {
            return this.networkService.get(
                `pacientes/informes?filter=by_user:eq:false&limit=10000&paciente_id=${paciente_id}`
            );
        }
        return this.networkService.get(
            `pacientes/informes?filter=by_user:eq:false&limit=10000`
        );
    }

    getInformesAnexos(idCabecera: string): Observable<any> {
        return this.networkService.get(`estudio/${idCabecera}/anexo`);
    }

    /**
     * Listado de informes por filtro (Utilizado por medico y secretaria) filtros paciente, medico
     * @param paciente
     * @param especialidad
     * @param medico
     */
    getPatientInformes(
        paciente,
        especialidad,
        medico,
        desde,
        hasta
    ): Observable<any> {
        return this.networkService.get(
            `pacientes/informes?filter=especialidad:like:${especialidad}|medico:like:${medico}|fecha:r:${desde}:${hasta}&limit=1000&paciente_id=${
                paciente || ''
            }`
        );
    }

    /**
     * Listar las citas planificadas del Usuario logeado.
     */
    getPacientes(nombre: string, apellido: string): Observable<any> {
        return this.networkService.get(
            `pacientes?filter=nombre:like:${nombre}|apellido:like:${apellido}&limit=50`
        );
    }

    /**
     * Información completa de usuario.
     */
    getUserInfo(user_id: string): Observable<any> {
        return this.networkService.get(`users/${user_id}`);
    }

    /**
     * Información completa de usuario.
     */
    getPacienteInfo(paciente_id: string): Observable<any> {
        return this.networkService.get(`pacientes/${paciente_id}`);
    }

    /**
     * Grupo sanguinea del paciente
     */
    getGrupoSanguineo(paciente_id): Observable<any> {
        return this.networkService.get(
            'pacientes/' + paciente_id + '/gruposanguineo'
        );
    }

    /**
     * Listar las citas planificadas del Usuario logeado.
     */
    getMedicos(nombre: string): Observable<any> {
        return this.networkService.get(
            `medicos?filter=nombre:like:${nombre}&limit=50`
        );
    }

    /**
     * Listar las citas planificadas del Usuario logeado.
     */
    getAllMedicos(ambulatorioId: string): Observable<any> {
        return this.networkService.get(
            `medicos?filter=ambulatorio_id:eq:${ambulatorioId}&limit=200`
        );
    }

    /**
     * Descarga de archivos pdf (informes).
     */
    downloadPdf(url: string): Observable<any> {
        return this.networkService.getFile(`${url}`);
    }

    /**
     * Actualizacion de usuarios
     * @param body
     */
    updateUserConfig(body: any): Observable<any> {
        return this.networkService.put(`users/register`, body);
    }

    /**
     * Retorna lista de paises
     */
    getPaises(): Observable<any> {
        return this.networkService.get('pais');
    }

    /**
     * Retorna lista de estados de acuerdo al pais seleccionado
     * @param id_pais id del pais seleccionado
     */
    getEstados(id_pais): Observable<any> {
        return this.networkService.get(`estado/${id_pais}`);
    }

    /**
     * Retorna lista de ciudades de acuerdo al pais seleccionado
     * @param id_estado id del estado seleccionado
     */
    getCiudades(id_estado): Observable<any> {
        return this.networkService.get(`ciudad/${id_estado}`);
    }

    /**
     * Registro de foto de usuario
     * @param body
     */
    postUserPhoto(body: any): Observable<any> {
        return this.networkService.post(`foto/usuario`, body);
    }

    /**
     * Get Laboratorios
     * @param idCita
     */
    getLaboratorio(idCita): Observable<any> {
        return this.networkService.get('citas/' + idCita + '/laboratorio');
    }

    /**
     * Get imagenes
     * @param idCita
     */
    getListadoImagenes(idCita): Observable<any> {
        return this.networkService.get('citas/' + idCita + '/imagenes');
    }

    /**
     * Resumen de cita
     * @param cita
     */
    getResumenHistoria(cita) {
        return this.networkService.get('resumen_historia/' + cita);
    }

    /**
     * Obtener lista de medicos del paciente
     * @param pacienteId
     */
    getPatientMedics(pacienteId: string) {
        return this.networkService.get(`medicos/paciente/id/${pacienteId}`);
    }

    /**
     * Informacion del medico
     */
    getMedicInfo(id_user: string): Observable<any> {
        return this.networkService.get(`medico/${id_user}`);
    }

    /**
     * Ingresar Lista de espera
     */
    postWaitingList(body: any): Observable<any> {
        return this.networkService.post(`lista_espera`, body);
    }

    /**
     * Get lista de cupos del medico por especialidad
     * @param medico
     * @param especialidad
     * @param desde
     * @param hasta
     */
    getCupos(medico, especialidad, desde, hasta): Observable<any> {
        return this.networkService.get(
            `cupos/${medico}/${especialidad}/${desde}/${hasta}`
        );
    }

    getCuposByPaciente(
        paciente,
        medico,
        especialidad,
        desde,
        hasta
    ): Observable<any> {
        return this.networkService.get(
            `cupos/paciente/${paciente}/${medico}/${especialidad}/${desde}/${hasta}`
        );
    }

    deleteAnexoPaciente(idCabecera: string, idAnexo: string): Observable<any> {
        return this.networkService.delete(
            `estudio/${idCabecera}/anexo/${idAnexo}`
        );
    }

    /**
     * Obtener datos de Lista de espera y validar email
     */
    postValidateEmail(body: any): Observable<any> {
        return this.networkService.post('lista_espera/validar_email', body);
    }

    deleteListaEspera(listaEsperaId: string): Observable<any> {
        return this.networkService.delete(`lista_espera/${listaEsperaId}`);
    }

    /**
     * Eliminar cita planificada
     * @param cita UUID cita
     * @returns
     */
    deleteCita(cita) {
        return this.networkService.delete('citas/ce/' + cita);
    }

    /**
     * Editar una cita
     * @param body JSON con parametros necesarios
     * @returns
     */
    putCita(body) {
        return this.networkService.put('citas/ce', body);
    }

    getPacienteById(id): Observable<any> {
        return this.networkService.get('pacientes/' + id);
    }

    /**
     * Datos de cliente
     * @param id string de uuid
     */
    getCustomerById(clienteId: string): Observable<any> {
        return this.networkService.get(`cliente/${clienteId}`);
    }

    putStatusCita(idCita: string, body: any): Observable<any> {
        return this.networkService.put(`cita/${idCita}/confirmar`, body);
    }

}
