import { Injectable } from '@angular/core';
import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class IqGuard implements CanActivate {
    constructor() {}

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | boolean {
        const view = next.paramMap.get('view_id');
        const consulta = next.paramMap.get('consulta_id');

        if (view === 'quirurgico') {
            if (consulta === '2') {
                return true;
            } else {
                return false;
            }
        } else {
            return true;
        }
    }
}
