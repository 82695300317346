export const locale = {
    lang: 'es',
    data: {
        ITEMS: 'Registros por página',
        NEXT_PAGE: 'Página siguiente',
        PREVIOUS_PAGE: 'Página anterior',
        OF: 'de',
        FIRST_PAGE: 'Primera página',
        LAST_PAGE: 'Última página',
    },
};
