export const locale = {
    lang: 'es',
    data: {
        SCHEDULE_APPOINTMENT: 'Agendar Cita',
        SCHEDULED_APPOINTMENT: 'Cita Agendada',
        SCHEDULED_APPOINTMENT_MESSAGE: 'Su cita ha sido agendada',
        FILL_FORM_MESSAGE:
            'Su médico necesita que llene un formulario antes de su cita, haga click en siguiente para llenarlo. Cerrar para llenarlo luego.',
        RESCHEDULE_APPOINTMENT: 'Reagendar Cita',
        DO_VERIFY:
            'Verifique la fecha y hora de la cita y luego presione agendar',
        DO_SAVE: 'Luego presione guardar',
        SAVE: 'Guardar',
        SCHEDULE: 'Agendar',
        CANCEL: 'Cancelar',
        MOTIVE: 'Motivo',
        CLOSE: 'Cerrar',
        NEXT: 'Siguiente',
        TELEMEDICINE: 'Telemedicina',
        CONSULT_PRICE_SHOW: 'Consulta',
        AT_HOME: 'A domicilio',
        FEE: 'Transaccion PM+',
        TAXES: 'Impuestos',
        TOTAL: 'Total',
        CONSULT: 'Consulta en sitio',
        CONSULT_PRICE: 'Precio de consulta',
        PAYMENT_TITLE: 'Pagar Cita',
        PAYMENT_BUTTON: 'Pagar',
        PAYMENT_AMOUNT: 'Monto a Pagar',
        CREDIT_CARD_NAME: 'Nombre que aparece en la tarjeta',
        CREDIT_CARD: 'Tarjeta de Crédito',
        PAYMENT_CONSULT_PRICE: '',
        SELECT_PAYMENT: 'Selecciona un método de pago',
        CREDIT_CARD_FIELDS: {
            NUMBER: 'Número de tarjeta de crédito',
            EXPIRATION: 'Fecha de expiración',
            CVV: 'Código de seguridad',
        },
        INFO_ICON:
            'Requerimos que ingreses tu número de verificación de tarjeta de crédito (CVV) para confirmar que el pago se realiza correctamente. Tu número de CVV se puede encontrar en la parte posterior de tu tarjeta de crédito.',
        INFO_ZELLE:
            'Ingresar el Codigo PM+ en la seccion de comentario como aqui se indica',
        NO_PAYMENT_CONTACT_CLINIC:
            'No hay formas de pago habilitadas. Comuniquese con la clínica',
        ZELLE_PAYMENT: {
            ANTICIPATION_DAYS: 'Para pagar con Zelle su cita debe ser mayor al',
            PM_CODE: 'Código PM+',
            IMPORTANT_MSG:
                '<b>Importante:</b> Registre este código en el campo comentario del pago Zelle.',
            IMPORTANT_MSG_IMG:
                'Ingrese en el campo <b>Agregar mensaje</b> el<br> código generado en portal médico',
            COPY_CODE: 'Copiar código de conciliación',
            COPY_SUCCESS: 'Código de conciliación copiado con éxito',
            COPY_ERROR:
                'Ocurrió un error al copiar el código de conciliación, intente nuevamente',
            MAIL: 'Correo de transferencia',
            LAST_DIGITS: 'Último 4 dígitos de la cuenta',
            TITULAR: 'Nombre titular de cuenta',
            DATE: 'Fecha de pago',
            INFORMATION: 'Informacion Zelle',
            NOT_ALLOWED:
                'El tiempo de anticipo para pagar una cita usando este método de pago es menor al mínimo permitido, por favor utilice otro método de pago.',
            COMMENT: 'Comentario',
            REFERENCE: 'Referencia',
            AMOUNT: 'Monto en Bs',
            ERROR: {
                REQUIRED: 'Campo requerido',
                MIN: 'Cantidad minima de caracteres requeridos',
                EMAIL_INVALID: 'Formato de correo no es valido',
                AMOUNT_INVALID: 'El monto debe ser mayor a 0 Bs.',
                INVALID_DATE: 'Fecha invalida',
            },
        },
        DIRECTION: {
            UPDATE_MSG:
                '¿Desea guardar la dirección en el perfíl del paciente?',
            BTN: {
                EDIT: 'Editar',
                ACCEPT: 'Aceptar',
            },
            ADD: {
                COUNTRY: 'País',
                CITY: 'Ciudad',
                ADDRESS: 'Dirección',
            },
        },
    },
};
