export const locale = {
    lang: 'es',
    data: {
        MAIN: {
            NO_RESULTS: 'No se encontraron resultados',
            LEFT_MENU: 'Menú izquierdo',
            YEARS: 'años',
            TELEMEDICINE: 'Telemedicina',
            INVALID_EMAIL: 'Formato de correo invalido',
            SHARE_INVITATION: 'Compartir acceso a la cita',
            NO_MESSAGES: 'No hay mensajes en el chat',
            CHAT: 'Escribe un mensaje',
            CONSULT_PRICE: 'Precio de la consulta:',
            SAVE_AS_ANEXO: 'Guardar como anexo de paciente',
            SEND_MESSAGE: 'Enviar mensaje',
            SEND_ATTACH: 'Enviar adjunto',
            ATTACH_FILE: 'Adjuntar archivo',
            DELETE_FILE: 'Eliminar del chat',
            SAVE_AS_RECIPE: 'Anexar como recipe',
            SAVE_AS_INFORME: 'Anexar como informe',
            SAVE_AS_DIAG: 'Anexar como imagen diagnóstica',
            DELETE_FROM_ANEXO: 'Eliminar de anexos',
            VIEW_FILE: 'Ver archivo'
        },
        MODAL: {
            EMAIL: 'Correo',
            DEFAULT: 'Correo por defecto',
            YES: 'Si',
            NO: 'No',
            YEARS: 'Años',
        },
        BTN: {
            COPY_LINK: 'Copiar enlace de cita',
            LOGIN: 'Ingresar',
            REGISTER: 'Registrarse',
            DISABLE_CAM: 'Deshabilitar cámara',
            DISABLE_MIC: 'Deshabilitar micrófono',
            ENABLE_CAM: 'Habilitar cámara',
            ENABLE_MIC: 'Habilitar micrófono',
            DISCONNECT: 'Desconectar de la sala',
            SEND_TO: 'Enviar a:',
            SEND_EMAIL: 'Enviar a otro correo',
            SHARE: 'Compartir enlace de cita',
            JOIN: 'Unirse ahora',
            HIDE: 'Ocultar cámara',
            SEND: 'Enviar',
            CLOSE: 'Cerrar',
            FULLSCREEN: 'Pantalla completa',
        },
        VIDEO: {
            WELCOME_MESSAGE: '¡Hola!... Tu cita ya está por comenzar.',
            CLOSED: 'La cita se encuentra cerrada.',
            CONTACT:
                'Pongase en contacto con su médico para planificar una nueva cita. ',
        },
        CHAT: 'Escribe un mensaje',
        MSG: {
            LINK_TITLE:
                '¡Hola! \n\nPara unirte a la video llamada, haz clic en este enlace: \n\n',
            COPY_LINK: 'El enlace de acceso está disponible en el portapapeles',
            LINK_ERROR:
                'El enlace de acceso no pudo ser copiado en el portapapeles',
            NO_MAIL: 'El paciente no tiene asociado un correo electrónico',
            READY_TO_JOIN: '¿Listo para unirse?',
            NOBODY_CONNECTED: 'No hay nadie conectado en la sala',
            PATIENT_CONNECTED: 'El paciente está conectado',
        },
    },
};
