import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { NetworkService } from 'app/services/network-service.service';

@Injectable({
    providedIn: 'root',
})
export class LandingPageService {
    constructor(private networkService: NetworkService) {}

    getEspecialidades(): Observable<any> {
        return this.networkService.get('especialidades');
    }

    getEspecialidadesFilter(especialidad, ambulatorioId): Observable<any> {
        return this.networkService.get(
            `especialidades?filter=nombre:like:${especialidad}&ambulatorio_id:eq:${ambulatorioId}&limit=50`
        );
    }

    getMedicos(medico, especialidad, ambulatorioId): Observable<any> {
        return this.networkService.get(
            `medicos/${especialidad}?filter=nombre:like:${medico}|ambulatorio_id:eq:${ambulatorioId}&limit=50`
        );
    }

    getCupos(medico, especialidad, desde, hasta): Observable<any> {
        return this.networkService.get(
            `cupos/${medico}/${especialidad}/${desde}/${hasta}`
        );
    }

    getCuposNew(
        idAmbulatorio: string,
        desde: string,
        hasta: string,
        texto: string
    ): Observable<any> {
        return this.networkService.get(
            `ambulatorio/${idAmbulatorio}/cupos/${desde}/${hasta}?filter=medico_especialidad:rlike:${texto}&limit=5000`
        );
    }

    registerCita(body): Observable<any> {
        return this.networkService.post('citas/ce', body);
    }

    registerPrepaidCita(body): Observable<any> {
        return this.networkService.postForm('venta/prepaid-cita', body);
    }

    reagendarCita(body): Observable<any> {
        return this.networkService.put('citas/ce', body);
    }

    eliminarCita(id): Observable<any> {
        return this.networkService.delete('citas/ce/' + id);
    }

    /**
     * Listar las citas planificadas del Usuario logeado.
     */
    getMedicosFilter(nombre: string, ambulatorioId: string): Observable<any> {
        return this.networkService.get(
            `medicos?filter=nombre:like:${nombre}|ambulatorio_id:eq:${ambulatorioId}&limit=100`
        );
    }

    /**
     * Obtener ID de clinica
     * @param nickname {string}
     */
    getClinicId(nickname: string): Observable<any> {
        return this.networkService.get(`ambulatorio/id/${nickname}`);
    }

    /**
     * Obtener info de clinica
     * @param id {string}
     */
    getClinicInfo(idAmbulatorio: string): Observable<any> {
        return this.networkService.get(`ambulatorio/${idAmbulatorio}`);
    }

    /**
     * Obtener foto de clinica
     * @param idAmbulatorio
     */
    getClinicPhoto(idAmbulatorio: string): Observable<any> {
        return this.networkService.get(`foto/ambulatorio/${idAmbulatorio}`);
    }

    /**
     * Informacion del medico
     */
    getMedicInfo(id_user: string): Observable<any> {
        return this.networkService.get(`medico/${id_user}`);
    }

    getTipoCita(
        medico: string,
        especialidad: string,
        paciente: string
    ): Observable<any> {
        return this.networkService.get(
            `tipocita/${medico}/${especialidad}/${paciente}`
        );
    }

    /**
     * Ingresar Lista de espera
     */
    postWaitingList(body: any): Observable<any> {
        return this.networkService.post(`lista_espera`, body);
    }

    /**
     * Obtener datos de Lista de espera y validar email
     */
    postValidateEmail(body: any): Observable<any> {
        return this.networkService.post('lista_espera/validar_email', body);
    }

    /**
     * Información completa de usuario.
     */
    getPacienteInfo(paciente_id: string): Observable<any> {
        return this.networkService.get(`pacientes/${paciente_id}`);
    }

    /**
     * Condigo de conciliacion Zelle
     */
    getCodigoZelle(): Observable<any> {
        return this.networkService.get(`venta/codigo-conciliacion`);
    }

    getValidarRecipe(
        nickname,
        cabecera,
        codigo_validador,
        validar
    ): Observable<any> {
        return validar
            ? this.networkService.get(
                  `paciente/recipe/validar/${nickname}/${cabecera}/${codigo_validador}/true`
              )
            : this.networkService.getFile(
                  `paciente/recipe/validar/${nickname}/${cabecera}/${codigo_validador}/false`
              );
    }
}
