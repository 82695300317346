export const locale = {
    lang: 'en',
    data: {
        FOOTER: {
            TRIAL_PERIOD_1: 'There are',
            TRIAL_PERIOD_2: 'days until the trial period expires',
            DAYS: 'days',
        },
    },
};
