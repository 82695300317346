import {
    AfterViewChecked,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    ViewEncapsulation,
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FuseConfigService } from '@fuse/services/config.service';
import { navigation } from 'app/navigation/navigation';
import { Router } from '@angular/router';

// Archivos de traducción
import { locale as english } from './i18n/en';
import { locale as spanish } from './i18n/es';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

@Component({
    selector: 'vertical-layout-1',
    templateUrl: './layout-1.component.html',
    styleUrls: ['./layout-1.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class VerticalLayout1Component
    implements OnInit, AfterViewChecked, OnDestroy
{
    fuseConfig: any;
    navigation: any;
    url: any;
    selectedOption = '';
    app_route = '';

    // Private
    private _unsubscribeAll: Subject<any>;
    private isPortal = false;
    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private router: Router,
        private cdr: ChangeDetectorRef,
        private fuseTranslate: FuseTranslationLoaderService
    ) {
        this.fuseTranslate.loadTranslations(english, spanish);
        // Set the defaults
        this.navigation = navigation;

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config) => {
                this.fuseConfig = config;
            });
    }

    /**
     * Aftre view checked
     */
    ngAfterViewChecked(): void {
        this.cdr.detectChanges();
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    getUrl(): string {
        const url = window.location.hash.split('#');
        return url[1] === undefined ? '' : url[1].substring(1);
    }

    showFooter(): boolean {
        const params = window.location.hash.split('/');
        if (
            (params[1] === 'medic' ||
                params[1] === 'portal' ||
                params[1] === 'secretary' ||
                params[1] === 'busqueda' ||
                params[1] === 'sales' ||
                params[1] === 'consulta') &&
            this.fuseConfig.modo_prueba &&
            this.fuseConfig.dias_restantes > 0
        ) {
            return true;
        }

        return false;
    }
}
