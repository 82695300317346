export const locale = {
    lang: 'en',
    data: {
        MAIN: {
            NO_RESULTS: 'No results found',
            NO_RESULTS_ADD_PATIENT:
                'No results found, make click on (+) to register a patient',
            BACK: 'Back',
            CLOSE: 'Close',
            CLEAR: 'Clear',
            NONE: 'There is no data to show',
            SEARCH: 'Search',
            ONLINE: 'Online',
            OFFLINE: 'Offline',
            SALES_MENU: 'Sales menu',
            YEARS: 'years',
            OF: 'of',
            HAS_VITALS: 'Has vital signs registered',
            VITALS: `Doesn't vital signs registered`,
            CALENDAR: 'Calendar',
            PATIENTS_OF_THE_DAY: 'Patients of the Day',
        },
        ANEXOS: {
            ADD: 'Add',
            ADD_ANNEX: 'Add annex',
            EDIT_ANNEX: 'Edit annex',
            SELECT_TYPE: 'Select type',
            DATE: 'Date',
            SPECIALTY: 'Specialty',
            MEDIC: 'Medic',
            PATIENT: 'Patient',
            DESCRIPTION: 'Description',
            SEARCH: 'Search',
            ATTACH: 'Adjuntar',
            SAVE: 'Save',
            REQUIRED: 'This field is required',
            INVALID_DATE: 'Date format invalid',
            CANCEL: 'Cancel',
            ERROR: 'Please attacha a file',
            OBSERVATION: 'Observations',
            DROP_FILES: 'Drag the file to upload or click attach',
            ALLOWED_FILES: 'Allowed files',
            FILE_NOT_SUPPORTED: 'File extension not supported',
        },
        CHART: {
            BILLING: 'Billing',
            STADISTICS: 'Stadistics',
            ATTEND: 'Appointments attended',
            SCHEDULED: 'Appointments scheduled',
            YEAR: 'Year',
            QUANTITY: 'Quantity',
            MONTH: 'Month',
            SEX: 'Sex',
            MALE: 'Male',
            FEMALE: 'Female',
        },
        CITAS: {
            TITLE: 'Schedule',
            TODAY: 'Today',
            TOMORROW: 'Tomorrow',
            NONE: 'There are no appointments scheduled for the seven days range',
            AGE: 'Years old',
            EDIT: 'Edit',
            MAKE_APPOINTMENT: 'Make Appointment',
            CONFIRMED: 'Confirmed',
        },
        PATIENTS: {
            TITLE: 'Patients',
            HISTORY: 'History',
            PATIENT: 'Patient',
            SEARCH: 'Search',
            RESULTS: 'Results',
            OPTIONS: 'Options',
            REGISTER: 'Register patient',
            EDIT: 'Update patient information',
            FILTER: 'Filter',
            NEW_WINDOW: 'Open in new window',
            MAIL_CONFIRMED: 'Mail confirmed',
            MAIL_NOT_CONFIRMED: 'Mail not confirmed',
            RECIPE: 'Recipe',
            ANNEX: 'Annex',
            MEDICAL_REPORT: 'Medical report',
            EXAMN: 'Examns',
            APPOINTMENT: 'Appointment',
            SELECT_OPTION: 'Select an option',
            EVO_NOTE: 'Evolution note',
            EVO_NOTE_CONFIRM_MSG: 'Are you sure you want to create an',
            TABLE: {
                ID: 'ID / Passport',
                NAME: 'Name',
                LASTNAME: 'Lastname',
                TELF: 'Telephone',
                DATE: 'Birthdate',
                MAIL: 'E-mail',
                ACTION: 'Actions',
                HISTORY: 'History',
                REPORT: 'Reports',
                DELETE: 'Delete',
                EXPORT: 'Export'
            },
            ADD: {
                CONFIRMATION_PWD_MSG:
                    'Enter your password to execute \n the action',
                CONFIRM_EMAIL: 'Confirm mail',
                UPLOAD: 'Upload photo',
                INVITE: 'Invite Portal',
                REQUIRED: 'Field required',
                IMAGE: 'Patient image',
                PATIENT: 'Patient data',
                NAME: 'Name',
                LASTNAME: 'Lastname',
                PASSWORD: 'Password',
                PASSWORD_CONFIRM: 'Confirm password',
                TYPE: 'Type',
                ID: 'ID',
                DATE: 'Birthdate',
                SEX: 'Sex',
                MALE: 'Male',
                FEMALE: 'Female',
                COUNTRY: 'Country',
                STATE: 'State',
                CITY: 'City',
                ADDRESS: 'Address',
                EMERGENCY: 'Emergency contact details',
                TELF: 'Telephone',
                MAIL: 'E-mail',
                SAVE: 'Save',
                EDIT: 'Edit',
                UPDATE: 'Update',
                WAIT: 'Wait for all components to load',
                OK: 'Accept',
                CANCEL: 'Cancel',
                CROP: 'Crop image',
                MAX_10: 'the field allows 10 characters',
                MAX_40: 'the field allows 40 characters',
                MAX_250: 'the field allows 250 characters',
                VALID_MAIL: 'Not a valid email',
            },
            EXPORT: {
                HISTORY: 'History',
                CONTACT: 'Emergency contact',
                SELECT: 'Select All',
                FIELDS: 'Fields',
                TODAY: 'Today',
                LASTDAYS: 'Last 7 days',
                LASTMONTH: 'Last month',
                EVERYBODY: 'Everybody',
                FILTER: 'Filter by creation date',
                EXPORTAS: 'Export as',
                EXPORTPATIENTS: 'Export Patients',
                DREGISTER: 'Registration Date'
            }
        },
        CALENDAR: {
            APPOINT: 'Schedule Appointment',
            DATE: 'Date',
            TIME: 'Time',
            FROM: 'From',
            TO: 'To',
            MOTIVE: 'Motive',
            ACCEPT: 'Accept',
            CANCEL: 'Cancel',
            SEARCH_PATIENT: 'Search Patient',
            REGISTER_PATIENT: 'Register Patient',
            NAME: 'Name',
            LAST_NAME: 'Last Name',
            NACIONALITY: 'Nacionality',
            CEDULA: 'Identification',
            BIRTH_DATE: 'Birth Date',
            SEX: 'Sex',
            PHONE: 'Phone',
            EMAIL: 'Email',
            APPOINTMENT_TYPE: 'Appointment Type',
            SPECIALTY: 'Specialty',
            PATIENT_BASIC_DATA: 'Basic Data from Patient',
            CLOSED: 'Closed Appointment',
            ATTEND: 'Attend',
            DELETE: 'Delete',
            DAY: 'Day',
            WEEK: 'Week',
            MONTH: 'Month',
            GO_TO_TODAY: 'Go to actual day',
            EDIT: 'Edit',
            CONSULT: 'Clinic consultation',
            TELEMEDICINE: 'Telemedicine',
            TYPE_ATTENTION: 'Type of attention',
            SHARE_TELEMEDICINE: 'Compartir link de telemedicina',
            SERVICES: 'Services',
            AT_HOME: 'At home',
            NEXT_SLOT_AV: 'Next slot avaiable',
        },
        ATTEND: {
            TITLE: 'Attend patient',
            ATTEND: 'Attend',
            YEARS: 'years',
            MOTIVE: 'Consult motive',
            TYPE: 'Type of consult',
            FIRST: 'First',
            SUCCESSIVE: 'Successive',
            LOADING: 'Loading patient info, please wait.',
            SHARE_TELEMEDICINE: 'Share link',
            LINK_SUCCESS: 'The access link is available on the clipboard',
            LINK_ERROR: 'The access link could not be copied to the clipboard',
            COPY_LINK: 'Copy link of appointment',
            SEND_TO: 'Send to:',
            SEND_EMAIL: 'Send to another email',
            GO_TO_FILE: 'Go to file',
            VITALS: 'Vital signs',
            APPOINTMENT: 'Go to file',
            LINK_TITLE:
                'Hello! \n\nTo Join the video call, make click on this link: \n\n',
            CONFIRMED: 'Confirmed',
            CONFIRM: 'Comfirm',
            CONFIRMED_MSG: 'Confirmed in',
            MODIFIED_MSG: 'Modified in',
            CONSULT_PRICE_SHOW: 'Consult',
            PAY: 'Pay',
        },
        ERROR: {
            INVALID_TIME: 'Invalid start and end time',
            INVALID_DATE: 'Invalid date',
            INVALID_DATE_FORMAT: 'Invalid Date Format',
            INVALID_DATE_AND_TIME: 'Invalid date and time',
            INVALID_FORM: 'Form is incomplete',
            INVALID_PATIENT_FORM: 'Patient data in incomplete',
            INVALID_DATE_TELEMEDICINE:
                'The selected date does not correspond to the available telemedicine appointments ',
            REQUIRED: 'This field is required',
            INVALID_EMAIL: 'Invalid email format',
            APPOINTMENT_CLOSED:
                'This appointment is closed, it can not be rescheduled or deleted',
            OLD_DATE: 'Should select a date equal or later from actual date',
            PATIENT_NOT_SELECTED: 'Patient is not selected',
            PATIENT_NOT_REGISTERED:
                'The patient is not registered, select an existing one or register a new one',
            NO_SPECIALTYS: 'There are not specialtys associated to this medic',
            MISSING_ADDRESS: 'Home appointments require an address',
        },
        REPORT: {
            TYPE: 'Type',
            DATE: 'Date',
            FROM: 'from',
            DESCRIP: 'Description',
            TO: 'to',
            SPECIALTY: 'Specialty',
            MEDIC: 'Medic',
            ACTIONS: 'Actions',
            RECIPE: 'Recipe',
            INFORM: 'Report',
            IMAGE: 'Images',
            LAB: 'Laboratory',
            PATIENT: 'Patient',
            DELETE: 'Delete search and results',
            ADD: 'Agregar informe',
            BACK: 'Regresar',
            NONE: 'There are no registers',
            ANNEX: 'Annex',
        },
        WAITING_LIST: {
            TITLE: 'Waiting patients',
            TITLE_2: 'Patients attended',
            STATUS: 'Estatus',
            PATIENT: 'Patient',
            MOTIVE: 'Motive of consult',
            SPECIALTY: 'Specialty',
            TIME: 'Time',
            ACTIONS: 'Actions',
            NO_RESULTS: 'There are no appointments',
            ONLINE: 'Waiting',
            OFFLINE: 'Absent',
            IN_CONSULTATION: 'In consultation',
            APPOINTMENTS: 'Scheduled appointments for the current day',
            APPOINTMENTS_TODAY: 'Scheduled appointments for the current day',
            ATTENDED: 'Appointments attended',
            ATTENDING: 'Attending:',
            ATTEND_MSG: '¿Enter videocall?',
            DELETE_MSG: '¿Delete patient form waiting list?',
            NO_MAIL: 'The patient does not have an associated email',
            WAITED_TIME: 'Waited timed',
            NO_MOTIVE: 'No motive was registered',
            EMAIL: 'Correo',
            CONFIRM_DISCONNECT_MSG:
                'When you disconnect, patients waiting for attention will be eliminated from the list. \n Do you want to continue? ',
            OFFLINE_PATIENT_MSG:
                'The patient is offline. \n ¿Do you wish to continue with the attention?',
            LEFT: 'Left',
        },
        SERVICES: {
            NAME: 'Services',
            PRICE: 'Price',
            TAX: 'Tax',
            SUBTOTAL: 'Subtotal',
            INVOICED: 'Invoiced',
            ACTIONS: 'Actions',
            DELETE: 'Delete',
            NOT_INVOICED: 'Not Invoiced',
            PlURAL_INVOICED: 'Invoiced',
            ALL: 'All',
        },
        BTN: {
            CANCEL: 'Cancel',
            DELETE: 'Delete',
            INVITE: 'Invite Portal',
            CONFIRM_MAIL: 'Confirm mail',
            ACCEPT: 'Acept',
            SEND: 'Send',
            ENTER: 'Enter',
            SELECT: 'Select',
            ATTEND: 'Attend',
            COPY_LINK: 'Copy link',
            SEND_INVITATION: 'Send invitation',
            OPTIONS: 'Options',
            DOWNLOAD: 'Download',
            NEW: 'New',
            NEW_2: 'New',
            LAB: 'Laboratory',
            IMAGE: 'Image',
            SEND_EMAIL: 'Send mail',
            QUESTIONNARIE_PRECONSULT: 'Pre-appointment',
        },
        MENU_IZQUIERDO: {
            VIEW_LIST: 'View vist',
            VIEW_CHART: 'View chart',
            ACTIONS: 'Actions',
            ALLERGIES: 'Allergies',
            RECORD: 'Records',
            VITAL_SIGNS: 'Vital Signs',
            APPOINTMENT_HISTORY: 'Recent Appointments',
            BLOOD_TYPE: 'Blood Type',
            NOTES: 'Notes',
            PATHOLOGICAL: 'Pathological',
            NO_PATHOLOGICAL: 'No Pathological',
            ADD_ALLERGY: 'Add Allergy',
            ADD_RECORD: 'Add Record',
            ADD_VITAL_SIGNS: 'Add Vital Signs',
            VIEW_ALLERGY: 'View Allergys',
            VIEW_RECORD: 'View Records',
            VIEW_VITAL_SIGNS: 'View Vital Signs',
            NOT_ALLERGIES: 'Does not present allergies',
            ACTIVE_PRINCIPLE: 'Select active principle (Optional)',
            ACTIVE_PRINCIPLE_D: 'Active Principle',
            DATE: 'Select date (Optional)',
            DATE_N: 'Select date',
            COMMENTS: 'Comments (Optional)',
            NOT_RECORD: 'Does not present records',
            TYPE_RECORD: 'Type of Record',
            INVALID_OPTION: 'Invalid Option',
            PATHOLOGICAL_Q: '¿Pathological?',
            DESCRIPTION: 'Description',
            REQUIRED_FIELD: 'Required field!',
            TYPE: 'Type',
            MEDIC: 'Medic',
            OBSERVATION: 'Observation',
            DATE_D: 'Date',
            DATE_O: 'Date (Optional)',
            NO_VITAL_SIGNS: 'Does not present vital signs',
            OUT_RANGE: 'Out of range value',
            ALLOWED_RANGE: 'Allowed Values',
            GRAPH: 'Graph',
            VALUE: 'Value',
            DATE_H: 'Date / Hour',
            HOUR: 'Hour',
            MALE: 'Male',
            FEMALE: 'Female',
            RESPONSIBLE: 'Responsible of payment',
            TREATING: 'Treating doctor',
            AREA: 'Area',
            FLOOR: 'Floor',
            ROOM: 'Room',
            ROOM_INFO: 'Select an area and floor',
            BED: 'Bed',
            BED_INFO: 'Select a room',
            LOCATION: 'Location',
            ASSIGN: 'Not assigned',
            CONSULT: 'Consult',
            ESPECIALTY: 'Especialty',
            MEDICAL_TRANSFER: 'Medical transfer',
            LOCATION_TRANSFER: 'Location transfer',
            BLUE: 'Blue: Confirmed',
            RED: 'Rojo: Not confirmed',
            CONFIRMATION_EMAIL: 'Do you want to send a confirmation email?',
            PASSWORD: 'Password',
            PASSWORD_CONFIRM: 'Confirm password',
            CONFIRMATION_PWD_MSG:
                'Enter your password to execute \n the action',
            RESPONSIBLE_SIGNS: 'Responsible',
            CHANGE_TO_APPOINTMENT: 'Medic History',
            CHANGE_TO_NOTE: 'Evolutive Note',
        },
        DIRECTION: {
            UPDATE_MSG:
                'Do you want to save the direction in the patient profile?',
            BTN: {
                EDIT: 'Edit',
                ACCEPT: 'Accept',
            },
            ADD: {
                COUNTRY: 'Country',
                CITY: 'City',
                ADDRESS: 'Direction',
            },
        },
        MODULES: {
            EMERGENCY: 'Emergency',
            HOSPITALIZATION: 'Hospitalization',
            SURGERY: 'Surgery',
        },
        NAV: {
            ADMITTED: 'Admitted',
            WAITING_ROOM: 'Waiting Room',
            DISCHARGED: 'Discharged',
            OTHERS: 'Others',
        },
        EMERGENCY: {
            ADMIT_PATIENT: 'Admit patient',
            DASH_TITLE: 'Emergency - Modules',
            BACK_TO_MODULES: 'Modules',
            CLICK_TO_CHANGE: 'Click to change module',
            ADMIT: {
                SEARCH: 'Search patient',
                SELECT_AREA: 'Select the area',
                SELECT_BED: 'Select the bed',
                SELECT_SPEC_MEDIC: 'Select the specialty and medic',
                NO_BED: 'There is no area selected or there are no beds available in this area',
                NO_AREA_SELECTED: 'There is no area selected',
                NO_BEDS_AVAILABLE: 'There are no beds available in the selected area',
                MEDIC_PLACEHOLDER: 'Medic',
                SPECIALTY_PLACEHOLDER: 'Specialty',
                CONSULTATION_REASON: 'Consultation reason (Optional)',
                NO_USER_SELECTED: 'There is no user selected',
                ADMIT: 'Admit',
                ADD: 'Add',
                SAVE: 'Save',
                CANCEL: 'Cancel',
                DELETE: 'Delete',
                TABLE_DEFAULT: 'By default',
                NO_AREAS: 'There are no areas available',
                NO_MEDICS: 'There are no medics of this specialty',
                DONE: 'Done',
                ALL: 'All',
                ADMITTED_PATIENTS: 'Admitted',
                AGE: 'Age',
            },
            E_TABLE: {
                LOCATION: 'Location',
                PATIENT: 'Patient',
                MEDIC: 'Medic',
                TIME: 'Time',
                REASON: 'Reason',
                DX: 'Dx',
                TEMPERATURE: 'Temperature',
                BLOOD_PRESSURE: 'Blood Pressure',
                WEIGHT: 'Weight',
                DEFAULT: 'By default',
                NO_INFO: 'No information',
                AGE: 'Age',
                MALE: 'Male',
                FEMALE: 'Female',
                NO_RECORDS: 'No records found',
                YEARS: 'years',
                S2OX: 'S2Ox',
                SIZE: 'Size',
            },
            WAIT: {
                ADD: 'Add',
                ADMIT_PATIENT: 'Add patient - Waiting room',
                EDIT_PATIENT: 'Edit patient - Waiting room',
                DELETE_PATIENT: 'Delete patient',
                DELETE_PATIENT_CONFIRMATION: 'Are you sure you want to delete "{{patient}}" from the waiting room?',
                YES: 'Yes',
                NO: 'No',
                ADDITIONAL_INFO: 'Additional information',
                SITUATION: 'Situation/Classification',
                WAIT_PATIENTS: 'Waiting room',
                LEGEND: 'Legend',
            },
            OTHERS: {
                MOVE_PATIENT_CONFIRMATION: 'Are you sure you want to re-enter "{{patient}}" to the waiting room?',
                YES: 'Yes',
                NO: 'No',
                ADDITIONAL_INFO: 'Additional information',
                SITUATION: 'Situation/Classification',
                OTHERS: 'Others',
                MOVE_PATIENT: 'Re-enter patient to waiting room',
                MAINTAIN_WAIT_TIME: 'Maintain waiting time',
                SEARCH: 'Search',
            },
            W_TABLE: {
                CLASSIFICATION: 'Classification',
                ACTIONS: 'Actions',
                ADMIT: 'Admit',
                DELETE: 'Delete',
                QUIT_WAIT: 'Quit waiting',
                GET_INTO_BED: 'Get into bed',
                ORDER: 'Order',
            },
            DISCHARGE: {
                DISCHARGED: 'Discharged',
                RE_ADMIT: 'Readmit patient',
                RE_ADMIT_CONFIRMATION: 'Are you sure you want to readmit "{{patient}}" to the area {{area}}/{{bed}}?',
                RE_ADMIT_AREA: 'Select a location for {{patient}}',
                CANCEL: 'Cancel',
                YES: 'Yes',
                READMIT: 'Readmit',
            },
            D_TABLE: {
                DATE_TIME: 'Date/Time',
                DISCHARGE_BY: 'Discharged by',
                RE_ADMIT: 'Reenter to bed',
            },
            O_TABLE: {
                ACTIONS: 'Actions',
                MOVE_INTO_WAITING: 'Re-enter waiting room',
                DEPARTURE_DATE: 'Departure date',
                ELIMINATED_BY: 'Eliminated by',
            },
            SEARCH: {
                ADMITED: 'Patient / Specialty',
                WAITING: 'Patient / Classification',
                DISCHARGED: 'Patient / Specialty',
                OTHERS: 'Patient',
            },
        },
        HOSPITALIZATION: {
            DASH_TITLE: 'Hospitalization - Modules',
        },
        SURGERY: {
            DASH_TITLE: 'Surgery - Modules',
            SECHEDULE_TITLE: 'Operating Room Planning',
            CONFIRM_ADMISSION: 'Admission Confirmation',
            CONFIRM_OMIT: 'Omit Patient',
            CONFIRM_REVERSE: 'Reverse Patient Admission',
            CONFIRMATION: 'Confirm Appointment',
            PARTICIPANTS: 'Participants',
            ADD_PARTICIPANTS: 'Add participants',
            MOVE_APPOINTMENT: 'Move appointment',
            ADMITTED: 'Admitted',
            NOT_ADMITTED: 'Not admitted',
            TABLE: {
                TIME: 'Time',
                PATIENT: 'Patient',
                INTERVENTIONS: 'Planned interventions',
                PARTICIPANTS: 'Participants',
                ACTIONS: 'Actions',
                NO_INFO: 'No information',
                OMIT: 'Omit',
                REVERSE: 'Reverse',
                ADMIT: 'Admit',
                DISCHARGED_BY: 'Discharged by',
            },
            INPUTS: {
                PLACEHOLDER: 'Patient, History, Medic, ...',
                TODAY: 'Today',
                YESTERDAY: 'Yesterday',
            },
            MODAL: {
                APPOINTMENT_DATA: 'Appointment data',
                DATE_TIME: 'Date - Time:',
                SPECIALTY: 'Specialty:',
                MEDIC: 'Treating Medic:',
                LOCATION_DATA: 'Location data',
                AREA: 'Area:',
                OPERATING_ROOM: 'Operating room:',
                COMMENT: 'Comment',
                OMIT_MESSAGE: 'Are you sure you want to omit the admission of {{patient}} from {{quirofano}}?',
                REVERSE_MESSAGE: 'Are you sure you want to reverse the admission of {{patient}} from {{quirofano}}?',
                CONFIRMATION_MESSAGE: 'Are you sure you want to confirm the appointment of {{patient}} on {{quirofano}}?',
                ADMIT_MESSAGE: 'Are you sure you want to admit {{patient}} from {{quirofano}}?',
                DRAG_MESSAGE: 'Are you sure you want to move the appointment from {{hora}} to {{hora_n}}?',
                DRAG_MESSAGE_2: 'Are you sure you want to move the appointment from {{hora}} to {{hora_n}}, and from {{quirofano}} to {{quirofano_1}}?',
                CANCEL: 'Cancel',
                ADMIT: 'Admit',
                YES: 'Yes',
                NO: 'No',
                YEARS: 'years',
                MONTHS: 'months',
                DIAS: 'days',
                MAIN_SURGEON: 'Main surgeon:',
                ROLE: 'Role',
                NAME: 'Name',
                ANESTHESIOLOGIST: 'Anesthesiologist',
                HELPER_1: 'Helper 1',
                HELPER_2: 'Helper 2',
                HELPER_3: 'Helper 3',
                INSTRUMENTIST: 'Instrumentist Nurse',
                CIRCULANT: 'Circulant Nurse',
                PEDIATRICIAN: 'Pediatrician',
                VIDEO_TECHNICIAN: 'Video Technician',
                HEMODYNAMIC_NURSE: 'Hemodynamic Nurse',
                SELECT_ROLE: 'Select role',
            },
            SCHEDULE: {
                GO_BACK_DASH: 'Go back to the Dashboard',
                AREA: 'Area',
                WEEK_VIEW: 'Week view',
                DAY_VIEW: 'Day view',
                OPERATING_ROOM: 'Operating room',
                AVILABILITY: 'Operating room availability',
                DATE_TIME: 'Select Date and Time',
                CANCEL: 'Cancel',
                ACCEPT: 'Accept',
                CLICK_TO_CHANGE_DATE: 'Click to change date',
                TODAY: 'Today',
                OMITTED: 'Omitted Patients',
                NO_OMITTED: 'No Omitted Patients',
                PENDING: 'Pending Patients',
                SCHEDULE: 'Schedule',
                RESCHEDULE: 'Reschedule',
                NON_APPROVED: 'Non Approved Emergencies',
                EDIT: 'Edit',
                CONFIRM: 'Confirm',
                OMIT: 'Omit',
                PARTICIPANTS: 'Participants',
                PENDING_TABLE: {
                    PATIENT: 'Patient',
                    INTERVENTION: 'Intervention',
                    MEDIC: 'Medic',
                    AREA: 'Area',
                    OPERATING_ROOM: 'Operating room',
                },
                APPOINTMENT: {
                    SCHEDULE: 'Schedule',
                    TITLE: 'Appointment Assignment',
                    PATIENT_INFO: 'Patient Information',
                    INTERVENTION_INFO: 'Intervention Information',
                    INTERVENTION_SUMMARY: 'Intervention Summary',
                    SPECIALTY: 'Specialty',
                    DOCTOR: 'Doctor',
                    DATE: 'Date',
                    TIME: 'Start time',
                    TIME_LABEL: 'Time',
                    PRIORITY: 'Priority',
                    TRANSIT: 'Transit',
                    AREA: 'Area',
                    OPERATING_ROOM: 'Operating room',
                    ADITIONAL_SERVICES: 'Aditional services',
                    DX_PRE: 'Pre-Operative Dx',
                    INTERVENTION: 'Intervention',
                    TOTAL_TIME: 'Total Intervention Time (min)',
                    RX: 'Rx',
                    PATHOLOGICAL_ANATOMY: 'Pathological Anatomy',
                    ICU: 'ICU',
                    TRANSPARENT_TABLE: 'Transparent table',
                    SEPTIC: 'Septic',
                    BLOOD_BANK: 'Blood Bank',
                    LOCAL_ANESTHESIA: 'Local Anesthesia',
                    THERMAL_BLANKET: 'Thermal Blanket',
                    OBSERVATIONS: 'Observations',
                    REFERRED: 'Referred',
                    NOT_REFERRED: 'Not referred',
                    EXTERNAL_REFERRED: 'External referred',
                    TO: 'to',
                    TOTAL_TIME_HINT: 'Minimum 30 minutes',
                    RESCHEDULE_APPOINTMENT: 'Reschedule appointment',
                    LOCATION: 'Location',
                },
                PARTICIPANTS_MODAL: {
                    ROLE: 'Role',
                    NAME: 'Name',
                    ADD: 'Add',
                    CANCEL: 'Cancel',
                    ACCEPT: 'Accept',
                    DELETE: 'Delete',
                },
            }
        },
        DATES: {
            YEARS: 'years',
            MONTHS: 'months',
            DAYS: 'days',
        },
        VITALS: {
            TITLE: 'Vital signs',
            LOCATION: 'Location',
            SPECIALTY: 'Specialty',
            BLOOD_GROUP: 'Blood group',
            SAVE: 'Save',
            SHOW_CHART: 'Show chart',
            HIDE_CHART: 'Hide chart',
            TEMPERATURE: 'Temperature',
            TEMPERATURE_MEASURE: 'Temperature (°C)',
            WEIGHT: 'Weight',
            WEIGHT_MEASURE: 'Weight (kg)',
            SIZE: 'Size',
            SIZE_MEASURE: 'Size (cm)',
            TENSION: 'Blood pressure (mmHg)',
            TENSION_HIGH: 'Systolic',
            TENSION_HIGH_MEASURE: 'Systolic (mmHg)',
            TENSION_LOW: 'Diastolic',
            TENSION_LOW_MEASURE: 'Diastolic (mmHg)',
            S2OX: 'S2Ox',
            S2OX_MEASURE: 'S2Ox (%)',
            FROM_ADMISION: 'From admission',
            THREE_MONTHS: '3 months',
            SIX_MONTHS: '6 months',
            TWELVE_MONTHS: '12 months',
            NO_DATA: 'No data',
            YEAR: 'Year',
            ADD: 'Add',
        }
    },
};
