import { Injectable } from '@angular/core';
import { NetworkService } from 'app/services/network-service.service';
import { Subject } from 'rxjs/internal/Subject';
import { Observable } from 'rxjs';
import * as moment from 'moment';

@Injectable({
    providedIn: 'root',
})
export class HistoryService {
    private Subject: Subject<any>;

    constructor(private networkService: NetworkService) {
        this.Subject = new Subject();
    }
    getCitasId(data): Observable<any> {
        return this.networkService.get('citas/medico?filter=id:eq:' + data);
    }

    getPatientId(data): Observable<any> {
        return this.networkService.get('pacientes/' + data);
    }

    getPacienteDatosOtros(data): Observable<any> {
        return this.networkService.get('citas/' + data + '/paciente');
    }

    getAllergyPacient(data): Observable<any> {
        return this.networkService.get(
            'pacientes/' + data + '/alergias?limit=10000'
        );
    }

    renderAgain(res): void {
        this.Subject.next(res);
    }

    subscribe(next, error?, complete?): any {
        return this.Subject.subscribe(next, error, complete);
    }

    getVitalSigns(data): Observable<any> {
        return this.networkService.get('citas/' + data + '/signos');
    }

    getSignoVitalesChart(paciente, desde, hasta): Observable<any> {
        return this.networkService.get(
            `paciente/${paciente}/signovital/${desde}/${hasta}`
        );
    }

    getArea(): Observable<any> {
        return this.networkService.get('area');
    }

    getAreaHospitalizacion(): Observable<any> {
        return this.networkService.get('areas/hospitalizacion');
    }

    getAreaCirugia(): Observable<any> {
        return this.networkService.get('areas/cirugia');
    }

    getEspecialidad(): Observable<any> {
        return this.networkService.get('especialidades');
    }

    getEspecialidadQuirurgicas(): Observable<any> {
        return this.networkService.get('especialidades/quirurgicas');
    }

    getMedico(idEspecialidad?): Observable<any> {
        if (idEspecialidad === undefined) {
            return this.networkService.get('medicos');
        } else {
            return this.networkService.get('medicos/' + idEspecialidad);
        }
    }

    getTipoAntecedentes(): Observable<any> {
        return this.networkService.get('tiposantecedentes');
    }

    getPrincipioActivo(_filter?): Observable<any> {
        const filter = _filter ? `?filter=nombre:like:${_filter}` : '';
        return this.networkService.get('principioactivo' + filter);
    }

    getIntervencion(idEspecialidad?): Observable<any> {
        if (idEspecialidad === undefined) {
            return this.networkService.get('intervenciones');
        } else {
            return this.networkService.get('intervenciones/' + idEspecialidad);
        }
    }

    getPosicion(): Observable<any> {
        return this.networkService.get('posicion');
    }

    getDieta(): Observable<any> {
        return this.networkService.get('dieta');
    }

    getHidratacion(): Observable<any> {
        return this.networkService.get('tratamiento');
    }

    getVia(): Observable<any> {
        return this.networkService.get('via');
    }

    getInformes(id_cita): Observable<any> {
        return this.networkService.get('citas/' + id_cita + '/informes');
    }

    /**
     * Registrar un nuevo informe medico
     */
    postInformeMedico(body): Observable<any> {
        return this.networkService.post('pacientes/informes/generar', body);
    }

    previewInformeMedico(body): Observable<any> {
        return this.networkService.post('pacientes/informes/preview', body);
    }

    getTipoInforme(data): Observable<any> {
        return this.networkService.get('tipoinforme/' + data);
    }

    getMedida(idTratamiento?): Observable<any> {
        if (idTratamiento === undefined) {
            return this.networkService.get('medida');
        } else {
            return this.networkService.get('medida/' + idTratamiento);
        }
    }

    getIntervalo(): Observable<any> {
        return this.networkService.get('intervalo');
    }

    getExamenLaboratorio(): Observable<any> {
        return this.networkService.get('examenes/laboratorio');
    }

    getExamenLaboratorioListado(): Observable<any> {
        return this.networkService.get('examenes/laboratorio/listado');
    }

    getExamenLaboratorioPerfil(): Observable<any> {
        return this.networkService.get('examenes/laboratorio/perfil');
    }

    getExamenImagenes(): Observable<any> {
        return this.networkService.get('examenes/imagenes');
    }

    getExamenImagenesListado(): Observable<any> {
        return this.networkService.get('examenes/imagenes/listado');
    }

    getExamenImagenesPerfil(): Observable<any> {
        return this.networkService.get('examenes/imagenes/perfil');
    }

    getOrdenesMedicas(data): Observable<any> {
        return this.networkService.get('ordenesmedicas/' + data);
    }

    getOrdenMedicaHospitalizacion(data): Observable<any> {
        return this.networkService.get(
            'ordenesmedicas/hospitalizacion/' + data
        );
    }

    getOrdenMedicaDieta(data): Observable<any> {
        return this.networkService.get('ordenesmedicas/dieta/' + data);
    }

    getOrdenMedicaCirugia(data): Observable<any> {
        return this.networkService.get('ordenesmedicas/cirugia/' + data);
    }

    getOrdenMedicaInterconsulta(data): Observable<any> {
        return this.networkService.get('ordenesmedicas/interconsulta/' + data);
    }

    getOrdenMedicaTratamiento(data): Observable<any> {
        return this.networkService.get('ordenesmedicas/tratamiento/' + data);
    }

    getOrdenMedicaPosicion(data): Observable<any> {
        return this.networkService.get('ordenesmedicas/posicion/' + data);
    }

    getOrdenMedicaOtros(data): Observable<any> {
        return this.networkService.get('ordenesmedicas/otros/' + data);
    }

    getTiposAlergias(): Observable<any> {
        return this.networkService.get('tiposalergias');
    }

    getTiposAlergiasPaciente(data): Observable<any> {
        return this.networkService.get('tiposalergias/' + data);
    }

    getAlergiaMedicamento(id_paciente, id_medicamento): Observable<any> {
        return this.networkService.get(
            `pacientes/${id_paciente}/alergias/${id_medicamento}`
        );
    }

    postOrdenMedicaHospitalizacion(data): Observable<any> {
        return this.networkService.post('ordenesmedicas/hospitalizacion', data);
    }

    postOrdenMedicaCirugia(data): Observable<any> {
        return this.networkService.post('ordenesmedicas/cirugia', data);
    }

    postOrdenMedicaInterconsulta(data): Observable<any> {
        return this.networkService.post('ordenesmedicas/interconsulta', data);
    }

    postOrdenMedicaPosicion(data): Observable<any> {
        return this.networkService.post('ordenesmedicas/posicion', data);
    }

    postOrdenMedicaOtros(data): Observable<any> {
        return this.networkService.post('ordenesmedicas/otros', data);
    }

    postOrdenMedicaDieta(data): Observable<any> {
        return this.networkService.post('ordenesmedicas/dieta', data);
    }

    postOrdenMedicaLaboratorio(data): Observable<any> {
        return this.networkService.post('ordenesmedicas/laboratorio', data);
    }

    postSignosVitales(cita_id, data): Observable<any> {
        return this.networkService.post('citas/' + cita_id + '/signos', data);
    }

    postOrdenMedicaImagenes(data): Observable<any> {
        return this.networkService.post('ordenesmedicas/imagen', data);
    }

    putOrdenMedicaHospitalizacion(data): Observable<any> {
        return this.networkService.put('ordenesmedicas/hospitalizacion', data);
    }

    putOrdenMedicaInterconsulta(data): Observable<any> {
        return this.networkService.put('ordenesmedicas/interconsulta', data);
    }

    putOrdenMedicaPosicion(data): Observable<any> {
        return this.networkService.put('ordenesmedicas/posicion', data);
    }

    putOrdenMedicaOtros(data): Observable<any> {
        return this.networkService.put('ordenesmedicas/otros', data);
    }

    putOrdenMedicaDieta(data): Observable<any> {
        return this.networkService.put('/ordenesmedicas/dieta', data);
    }

    putOrdenMedicaCirugia(data): Observable<any> {
        return this.networkService.put('ordenesmedicas/cirugia', data);
    }

    putOrdenMedicaTratamiento(data): Observable<any> {
        return this.networkService.put('ordenesmedicas/tratamiento', data);
    }

    deleteOrdenMedicaHospitalizacion(data): Observable<any> {
        return this.networkService.delete(
            'ordenesmedicas/hospitalizacion/' + data
        );
    }

    deleteOrdenMedicaDieta(data): Observable<any> {
        return this.networkService.delete('ordenesmedicas/dieta/' + data);
    }

    downloadFile(url): Observable<any> {
        return this.networkService.getFile(url);
    }

    obtenerPlantillaInformePaciente(
        paciente_id,
        plantilla_id
    ): Observable<any> {
        return this.networkService.get(
            `pacientes/${paciente_id}/informes/${plantilla_id}`
        );
    }

    obtenerPlantillaInformeCita(
        cita_id,
        plantilla_id
    ): Observable<any> {
        return this.networkService.get(
            `citas/${cita_id}/informes/${plantilla_id}`
        );
    }

    obtenerHistoricoTratamiento(cita_id): Observable<any> {
        return this.networkService.get(
            '/historico/' + cita_id + '/registrotratamiento'
        );
    }

    obtenerTratamientos(cita_id, fecha, hora): Observable<any> {
        return this.networkService.get(
            '/citas/' + cita_id + '/registrotratamiento/' + fecha + '/' + hora
        );
    }

    deleteOrdenMedicaMezcla(data): Observable<any> {
        return this.networkService.delete('ordenesmedicas/hidratacion/' + data);
    }

    deleteOrdenMedicaTratamiento(data): Observable<any> {
        return this.networkService.delete('ordenesmedicas/tratamiento/' + data);
    }

    deleteOrdenMedicaLaboratorio(data): Observable<any> {
        return this.networkService.delete('ordenesmedicas/laboratorio/' + data);
    }

    deleteOrdenMedicaImagenes(data): Observable<any> {
        return this.networkService.delete('ordenesmedicas/imagen/' + data);
    }

    deleteOrdenMedicaCirugia(data): Observable<any> {
        return this.networkService.delete('ordenesmedicas/cirugia/' + data);
    }

    deleteOrdenMedicaInterconsulta(data): Observable<any> {
        return this.networkService.delete(
            'ordenesmedicas/interconsulta/' + data
        );
    }

    deleteOrdenMedicaPosicion(data): Observable<any> {
        return this.networkService.delete('ordenesmedicas/posicion/' + data);
    }

    deleteOrdenMedicaOtros(data): Observable<any> {
        return this.networkService.delete('ordenesmedicas/otros/' + data);
    }

    postOrdenMedicaMezclas(data): Observable<any> {
        return this.networkService.post('ordenesmedicas/hidratacion', data);
    }

    postOrdenMedicaTratamiento(data): Observable<any> {
        return this.networkService.post('ordenesmedicas/tratamiento', data);
    }

    postNotas(data): Observable<any> {
        return this.networkService.post('citas/notas', data);
    }

    postAlergiaPaciente(paciente_id, data): Observable<any> {
        return this.networkService.post(
            'pacientes/' + paciente_id + '/alergias',
            data
        );
    }

    postAntecedentes(paciente_id, data): Observable<any> {
        return this.networkService.post(
            'pacientes/' + paciente_id + '/antecedentes',
            data
        );
    }

    deleteAntecedente(antecedente_id): Observable<any> {
        return this.networkService.delete(`antecedentes/${antecedente_id}`);
    }

    getBalance(cita, form): Observable<any> {
        const filter = { ...form };
        let search = '';
        let slice = true;

        if (
            filter.fecha1 !== '' ||
            filter.fecha2 !== '' ||
            filter.es_injesta !== '' ||
            filter.via !== ''
        ) {
            search = '?filter=';
            slice = true;
        }
        if (filter.es_injesta.es_injesta !== '') {
            search += `es_injesta:eq:${filter.es_injesta.es_injesta}|`;
        }

        if (filter.via !== '') {
            search += `via:eq:${filter.via.via}|`;
        }

        if (filter.fecha !== '' && filter.fecha2 !== '') {
            search += `fecha:r:${moment(filter.fecha1).format(
                'YYYY-MM-DD'
            )}:${moment(filter.fecha2).add(1, 'day').format('YYYY-MM-DD')}`;
        }

        // if (filter.fecha1 !== '') {
        //     search += `fecha&from=${moment(filter.fecha1).format('YYYY-MM-DD')}`;
        // }

        // if (filter.fecha2 !== '') {
        //     search += `&to=${moment(filter.fecha2).format('YYYY-MM-DD')}`;
        // }

        return this.networkService.get('balancehidrico/' + cita + search);
    }
    postBalance(cita, data): Observable<any> {
        return this.networkService.post('balancehidrico/' + cita, data);
    }

    getTiposBalance(): Observable<any> {
        return this.networkService.get('balancehidrico');
    }

    getEvolucionMedica(data): Observable<any> {
        return this.networkService.get(
            'citas/' + data + '/evoluciones/medicas'
        );
    }
    getTipoAlta(): Observable<any> {
        return this.networkService.get('tipoalta');
    }
    postAlta(data): Observable<any> {
        return this.networkService.post('citas/altamedica', data);
    }
    getValidarAlta(data): Observable<any> {
        return this.networkService.get('citas/' + data + '/validaralta');
    }
    getEvolucionEnfermeria(data): Observable<any> {
        return this.networkService.get(
            'citas/' + data + '/evoluciones/enfermeria'
        );
    }
    getListadoImagenes(data): Observable<any> {
        return this.networkService.get('citas/' + data + '/imagenes');
    }

    postImagenes(cita, data): Observable<any> {
        return this.networkService.file(
            '/citas/' + cita + '/resultadoexterno',
            data
        );
    }

    getFile(url): Observable<any> {
        return this.networkService.get(url);
    }
    postEvolucionMedica(data): Observable<any> {
        return this.networkService.post('citas/evoluciones/medicas', data);
    }

    postLaboratorio(cita_id, data): Observable<any> {
        return this.networkService.file(
            'citas/' + cita_id + '/resultadoexterno',
            data
        );
    }

    postEvolucionEnfermeria(data): Observable<any> {
        return this.networkService.post('citas/evoluciones/enfermeria', data);
    }
    getTareasEnfermeria(data): Observable<any> {
        return this.networkService.get('citas/' + data + '/actividades');
    }
    postTareaEnfermeria(data): Observable<any> {
        return this.networkService.post('citas/actividades', data);
    }

    deleteTareaEnfermeria(data): Observable<any> {
        return this.networkService.delete('citas/actividades/' + data);
    }

    putTareaEnfermeria(data, idActividad): Observable<any> {
        return this.networkService.put(
            'citas/actividades/' + idActividad,
            data
        );
    }

    getSignos(): Observable<any> {
        return this.networkService.get('signos');
    }

    getTimeline(data): Observable<any> {
        return this.networkService.get('citas/' + data + '/timelines');
    }
    getTimelineImage(data): Observable<any> {
        return this.networkService.get('citas/' + data + '/timelines/imagenes');
    }

    getGrupoSanguineo(data): Observable<any> {
        return this.networkService.get('pacientes/' + data + '/gruposanguineo');
    }

    getListaSanguineo(): Observable<any> {
        return this.networkService.get('gruposanguineo');
    }

    postGrupoSanguineo(id_sangre, body): Observable<any> {
        return this.networkService.post(
            `pacientes/${id_sangre}/gruposanguineo`,
            body
        );
    }

    getNotas(data): Observable<any> {
        return this.networkService.get('citas/' + data + '/notas?all=true');
    }

    getTimelineLaboratorio(data): Observable<any> {
        return this.networkService.get(
            'citas/' + data + '/timelines/laboratorio'
        );
    }
    getTimelineTratamiento(data): Observable<any> {
        return this.networkService.get(
            'citas/' + data + '/timelines/tratamiento'
        );
    }

    getHistoricoCitas(data): Observable<any> {
        return this.networkService.get('pacientes/' + data + '/citas');
    }

    getAntecedentes(data): Observable<any> {
        return this.networkService.get('pacientes/' + data + '/antecedentes');
    }

    getNotasOperatorias(data): Observable<any> {
        return this.networkService.get('citas/' + data + '/notaoperatoria');
    }

    getLaboratorio(data): Observable<any> {
        return this.networkService.get('citas/' + data + '/laboratorio');
    }

    getResultado(tipo, id): Observable<any> {
        return this.networkService.get(`citas/resultado/${tipo}/${id}`);
    }

    printResultado(tipo, id): Observable<any> {
        return this.networkService.get(`citas/resultado/${tipo}/${id}/print`);
    }

    getLaboratorioTl(cita_id): Observable<any> {
        return this.networkService.get(
            `/citas/${cita_id}/timelines/laboratorio`
        );
    }

    getDetalleNotasOperatorias(data): Observable<any> {
        return this.networkService.get('notaoperatoria/' + data);
    }

    getMotives(str) {
        return this.networkService.get(
            `motivoconsulta?filter=descripcion:rlike:${str}&limit=30`
        );
    }

    getDx(str) {
        return this.networkService.get(
            `diagnosticos?filter=descripcion:rlike:${str}&limit=30`
        );
    }

    getTreatment(str) {
        return this.networkService.get(
            `tratamiento?filter=nombre:rlike:${str}&limit=30`
        );
    }

    getMedidas() {
        return this.networkService.get('medida?filter=medida_medica:eq:true');
    }

    getVias() {
        return this.networkService.get('via');
    }

    getIntervalos() {
        return this.networkService.get('intervalo');
    }

    getResumenHistoria(cita) {
        return this.networkService.get('resumen_historia/' + cita);
    }

    getCitasCE(cita) {
        return this.networkService.get('citas/ce/' + cita);
    }

    postMotivo(cita, body) {
        return this.networkService.post(
            'resumen_historia/' + cita + '/motivo',
            body
        );
    }

    putMotivo(cita, body) {
        return this.networkService.put(
            'resumen_historia/' + cita + '/motivo',
            body
        );
    }

    deleteMotivo(cita, motivo) {
        return this.networkService.delete(
            'resumen_historia/' + cita + '/motivo/' + motivo
        );
    }

    putEnfermedad(cita, body) {
        return this.networkService.put(
            'resumen_historia/' + cita + '/enfermedad',
            body
        );
    }

    postDiagnostico(cita, body) {
        return this.networkService.post(
            'resumen_historia/' + cita + '/diagnostico',
            body
        );
    }

    putDiagnostico(cita, body) {
        return this.networkService.put(
            'resumen_historia/' + cita + '/diagnostico',
            body
        );
    }

    deleteDiagnostico(cita, diagnostico) {
        return this.networkService.delete(
            'resumen_historia/' + cita + '/diagnostico/' + diagnostico
        );
    }

    postExamen(cita, body) {
        return this.networkService.post(
            'resumen_historia/' + cita + '/examen',
            body
        );
    }

    putExamen(cita, body) {
        return this.networkService.put(
            'resumen_historia/' + cita + '/examen',
            body
        );
    }

    deleteExamen(cita, examen) {
        return this.networkService.delete(
            'resumen_historia/' + cita + '/examen/' + examen
        );
    }

    postPlan(cita, body) {
        return this.networkService.post(
            'resumen_historia/' + cita + '/plan',
            body
        );
    }

    deletePlan(cita, plan) {
        return this.networkService.delete(
            'resumen_historia/' + cita + '/plan/' + plan
        );
    }

    postRecipe(cita, body): Observable<any> {
        return this.networkService.post(
            'resumen_historia/' + cita + '/recipe',
            body
        );
    }

    putRecipe(cita, body): Observable<any> {
        return this.networkService.put(
            'resumen_historia/' + cita + '/recipe',
            body
        );
    }

    deleteRecipe(cita, recipe): Observable<any> {
        return this.networkService.delete(
            'resumen_historia/' + cita + '/recipe/' + recipe
        );
    }

    /**
     * Get historico de recipes
     */
    getHistoricoRecipe(id_cita: string): Observable<any> {
        return this.networkService.get(`historico/${id_cita}/recipe`);
    }

    /**
     * Nota operatoria
     */
    getNotaOperatoria(id_cita): Observable<any> {
        return this.networkService.get(`notaoperatoria/${id_cita}`);
    }

    /**
     * Registrar informe quirúrgico
     */
    postNotaOperatoria(body): Observable<any> {
        return this.networkService.post('notaoperatoria', body);
    }

    /**
     * Rol medico
     */
    getRol(): Observable<any> {
        return this.networkService.get('rol/iq');
    }

    /**
     * Busqueda de medicos o enfermeras segun el rol
     */
    getUserRol(id_rol, text): Observable<any> {
        return this.networkService.get(`usuarios/busqueda/${id_rol}/${text}`);
    }

    /**
     * Prioridad
     */
    getPriority(): Observable<any> {
        return this.networkService.get('prioridad/iq');
    }

    /**
     * Transito
     */
    getTransit(): Observable<any> {
        return this.networkService.get('transito/iq');
    }

    /**
     * Tipo anestesia
     */
    getAnestesia(): Observable<any> {
        return this.networkService.get('tipoanestesia');
    }

    /**
     *
     */
    postDx(body): Observable<any> {
        return this.networkService.post('citas/diagnosticos', body);
    }

    /**
     * Diagnostico de ingreso
     */
    getDiagnostico(str): Observable<any> {
        return this.networkService.get(
            `diagnosticos?filter=descripcion:like:${str}&limit=30`
        );
    }

    /**
     * Diagnostico de ingreso
     */
    deleteDx(id_diagnostico): Observable<any> {
        return this.networkService.delete(
            `citas/diagnosticos/${id_diagnostico}`
        );
    }

    /**
     * Registrar intervenciones
     */
    postIntervenciones(body): Observable<any> {
        return this.networkService.post('notaoperatoria/iq', body);
    }

    /**
     * Eliminar intervenciones
     */
    deleteIntervenciones(id_nota): Observable<any> {
        return this.networkService.delete(`notaoperatoria/iq/${id_nota}`);
    }

    /**
     * Registrar participantes
     */
    postParticipantes(body): Observable<any> {
        return this.networkService.post('notaoperatoria/participantes', body);
    }

    /**
     * Eliminar participantes
     */
    deleteParticipantes(id_part): Observable<any> {
        return this.networkService.delete(
            `notaoperatoria/participantes/${id_part}`
        );
    }

    cerrarCita(id) {
        return this.networkService.put(`citas/${id}/cerrar`);
    }

    enviarObservacion(body) {
        return this.networkService.put('citas/envio-observacion', body);
    }

    getAreaConsulta(tipoId) {
        return this.networkService.get('area/' + tipoId);
    }

    getPiso() {
        return this.networkService.get('piso');
    }

    getCuarto(area, piso) {
        return this.networkService.get(`cuarto/${area}/${piso}`);
    }

    getCama(cuarto) {
        return this.networkService.get(
            `cama/${cuarto}?filter=disponible:eq:true`
        );
    }

    getExamenDetalle(grupo_id) {
        return this.networkService.get('citas/resultado/grupo/' + grupo_id);
    }

    /**
     * Obtiene un traslado medico y de ubicacion
     */
    getTraslado(id_cita): Observable<any> {
        return this.networkService.get(`citas/${id_cita}/traslado`);
    }

    /**
     * Registrar traslado medico y de ubicacion
     * @param body
     */
    postTraslado(body): Observable<any> {
        return this.networkService.post('citas/traslado', body);
    }

    /**
     * Listado de tipo de consultas
     */
    getTipoConsulta(): Observable<any> {
        return this.networkService.get('tipoconsulta');
    }

    getAldrete(cita): Observable<any> {
        return this.networkService.get('aldrete/' + cita);
    }

    getAldreteHistorico(cita): Observable<any> {
        return this.networkService.get('aldrete/' + cita + '/historico');
    }

    postAldrete(body): Observable<any> {
        return this.networkService.post('aldrete', body);
    }

    /**
     * Informacion del alta
     * @param id_cita id de la cita
     */
    getInfoAlta(id_cita): Observable<any> {
        return this.networkService.get(`citas/${id_cita}/altamedica`);
    }

    /**
     * Informacion del medico
     */
    getMedicInfo(id_user: string): Observable<any> {
        return this.networkService.get(`medico/${id_user}`);
    }

    /**
     * Firma del usuario
     */
    getFirma(id_user: string): Observable<any> {
        return this.networkService.get(`firma/usuario/${id_user}`);
    }

    /**
     * Impresion de recipes
     */
    printRecipe(cita_id: string): Observable<any> {
        return this.networkService.getFile(
            `resumen_historia/${cita_id}/print/recipe`
        );
    }

    /**
     * Obtener token para acceso a sala de twilio (video chat)
     * @param identity
     * @param room
     */
    getToken(identity: string, room: string): Observable<any> {
        return this.networkService.get(
            `twilioToken?identity=${identity}&room=${room}`
        );
    }

    /**
     * Descarga de archivos pdf (informes).
     */
    downloadPdf(url: string): Observable<any> {
        return this.networkService.getFile(`${url}`);
    }

    /**
     * Listado de informes por filtro (Utilizado por medico y secretaria) filtros paciente, medico
     * @param paciente
     */
    getPatientInformes(pacienteId): Observable<any> {
        return this.networkService.get(
            `pacientes/informes?limit=400&paciente_id=${pacienteId}`
        );
    }

    /**
     * Eliminar anexos por ID
     * @param anexoId
     */
    deleteAnexos(anexoId: string): Observable<any> {
        return this.networkService.delete(`pacientes/anexos/${anexoId}`);
    }

    /**
     * Informacion del usuario
     */
    getUserInfo(idUser: string): Observable<any> {
        return this.networkService.get(`users/${idUser}`);
    }

    /**
     * Get info de paciente
     * @param idPaciente
     */
    getPacienteInfo(idPaciente: string): Observable<any> {
        return this.networkService.get(`pacientes/${idPaciente}`);
    }

    //
    /**
     * Get info de paciente
     * @param body "ambulatorio": "string", "cita": "string", "email": "user@example.com"
     */
    postInvitacionVideollamada(body: any): Observable<any> {
        return this.networkService.post(`videollamada/invitacion`, body);
    }

    /**
     * Enviar correo de confirmacion
     * @param body  "paciente": "string", "correo": "string", "contrasena": "string", "confirmar_contrasena": "string"
     */
    postConfirmarCorreo(body: any): Observable<any> {
        return this.networkService.post(`/paciente/correo/enviar`, body);
    }

    /**
     * Elimina informacion de la cita
     * @param cita UUID de cita
     * @returns
     */
    deleteCitaData(cita): Observable<any> {
        return this.networkService.delete(`citas/ce/${cita}/${true}`);
    }

    /**
     * Impresion de ordenes medicas
     * @param body paciente: string <uuid>, cita: string <uuid>, solicitudes: Array of string <uuid>
     */
    printOrdenMedica(body): Observable<any> {
        return this.networkService.postAndGetBlob(
            'resumen_historia/print/solicitud',
            body
        );
    }

    /**
     * Actualizar costo de la cita
     * @param cita_id: UUID string
     * @param body precio_cita: number
     */
    updatePrecioCita(cita_id: string, body): Observable<any> {
        return this.networkService.put(`citas/${cita_id}/precio`, body);
    }

    /**
     * Enviar correo con pdf.
     */
    postSendPDf(body: any): Observable<any> {
        return this.networkService.postForm(`pacientes/informes/correo`, body);
    }

    /**
     * Actualizar estado de nota evolutiva de una cita
     * @param cita_id: UUID string
     */
    putNotaEvolutiva(cita_id: string, body: any): Observable<any> {
        return this.networkService.put(`citas/${cita_id}/nota_evolutiva`, body);
    }

    /**
     * Asociar template a una cita y guardar response
     * @param body template_cita: uuid, cita: uuid, definicion: objeto (json, pero enviar como un objeto), secciones_fijas: objeto (json, pero enviar como un objeto, null), respuesta: objeto (json, pero enviar como un objeto, null)
     */
    postTemplatesCita(body: any): Observable<any> {
        return this.networkService.post(`cita_template`, body);
    }

    /**
     * Lista de templates para citas
     */
    getTemplates(medicoId: string, especialidadId: string): Observable<any> {
        return this.networkService.get(
            `template_cita?filter=medico:eq:${medicoId}|especialidad:eq:${especialidadId}`
        );
    }

    /**
     * Lista de templates para citas
     */
    getTemplatesByMedic(medicoId: string): Observable<any> {
        return this.networkService.get(
            `template_cita?filter=medico:eq:${medicoId}`
        );
    }

    /**
     * Actualizar una plantilla
     */
    putTemplateCita(body: any): Observable<any> {
        return this.networkService.put(`template_cita`, body);
    }

    /**
     * Eliminar una plantilla
     */
    deleteTemplateCita(idTemplate: string): Observable<any> {
        return this.networkService.delete(`template_cita/${idTemplate}`);
    }

    /**
     * Crear un nuevo template
     * @param body
     */
    postTemplate(body: any): Observable<any> {
        return this.networkService.post(`template_cita`, body);
    }

    /**
     * Listado de kardex
     * @param id_cita id de la cita
     */
    getKardex(id_cita): Observable<any> {
        return this.networkService.get(`citas/${id_cita}/kardex`);
    }

    /**
     * Actualizar kardex del alta
     */
    putKardex(body: any): Observable<any> {
        return this.networkService.put(`citas/kardex`, body);
    }

    getCitaId(id): Observable<any> {
        return this.networkService.get(`citas/ce/${id}`);
    }

    // Tratamientos
    postRegistroTratamiento(id_om_tratamiento, body): Observable<any> {
        return this.networkService.post(`om/tratamiento/${id_om_tratamiento}/registroaplicar`, body);
    }
    
    postOmitirTratamiento(id_om_tratamiento, body): Observable<any> {
        return this.networkService.post(`om/tratamiento/${id_om_tratamiento}/registroomitir`, body);
    }
}
