import { Component, OnInit, Inject } from '@angular/core';
import {
    MatSnackBarHorizontalPosition,
    MatSnackBarVerticalPosition,
    MAT_DIALOG_DATA,
    MatSnackBar,
    MatDialogRef,
} from '@angular/material';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { HistoryService } from '../../../history.service';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { TranslateService } from '@ngx-translate/core';
import { id } from '@swimlane/ngx-charts/release/utils';

@Component({
    selector: 'app-medico-modal',
    templateUrl: './medico-modal.component.html',
    styleUrls: ['./medico-modal.component.scss'],
})
export class MedicoModalComponent implements OnInit {
    private horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    private verticalPosition: MatSnackBarVerticalPosition = 'top';

    currentView = 'consulta';
    trasladoForm: FormGroup;

    asignar = {
        consulta: { id: 0, descripcion: 'Sin asignar' },
        medico: { id: 0, descripcion: 'Sin asignar' },
        especialidad: { id: 0, descripcion: 'Sin asignar' },
    };

    consulta = [];
    medico = [];
    especialidad = [];
    search = [];

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private fb: FormBuilder,
        private historyService: HistoryService,
        private fuseProgressBarService: FuseProgressBarService,
        private snackBar: MatSnackBar,
        private _translateService: TranslateService,
        public dialogRef: MatDialogRef<MedicoModalComponent>
    ) {}

    ngOnInit(): void {
        this.trasladoForm = this.fb.group({
            traslado: [''],
        });

        this.asignar.consulta.descripcion = this.asignar.medico.descripcion = this.asignar.especialidad.descripcion = this._translateService.instant(
            'MENU_IZQUIERDO.ASSIGN'
        );

        this.getTipoConsulta();
        this.getEspecialidad();
    }

    bgOption(selectedView: string): Object {
        if (selectedView !== this.currentView) {
            return {
                backgroundColor: '#eeeeee',
            };
        } else {
            return {
                backgroundColor: '#dddddd',
                borderBottom: '2px solid #039be5',
                color: '#039be5',
            };
        }
    }

    selectOption(selected: string, ele: any): void {
        switch (selected) {
            case 'consulta':
                this.asignar.consulta = ele;
                this.changeView('especialidad');
                break;
            case 'especialidad':
                this.asignar.especialidad = ele;
                this.changeView('medico');
                this.getMedico();
                break;
            case 'medico':
                this.asignar.medico = ele;
                break;
        }
    }

    changeView(selected: string): void {
        if (selected !== this.currentView) {
            switch (selected) {
                case 'consulta':
                    this.search = this.consulta;
                    break;
                case 'especialidad':
                    this.search = this.especialidad;
                    break;
                case 'medico':
                    this.search = this.medico;
                    break;
            }
            this.currentView = selected;
            this.trasladoForm.get('traslado').setValue('');
        }
    }

    doSearch(view = this.currentView): void {
        const value = this.trasladoForm.get('traslado').value.toLowerCase();
        this.search = [];

        let arr;
        switch (view) {
            case 'consulta':
                arr = this.consulta;
                break;
            case 'especialidad':
                arr = this.especialidad;
                break;
            case 'medico':
                arr = this.medico;
                break;
        }

        arr.forEach((element) => {
            if (element.descripcion.toLowerCase().indexOf(value) !== -1) {
                this.search.push(element);
            }
        });

        if (this.search.length === 0) {
            switch (view) {
                case 'consulta':
                    this.search = this.consulta;
                    break;
                case 'especialidad':
                    this.search = this.especialidad;
                    break;
                case 'medico':
                    this.search = this.medico;
                    break;
            }
        }
    }

    postTraslado(): void {
        if (
            this.asignar.especialidad.id !== 0 &&
            this.asignar.medico.id !== 0
        ) {
            const body = {
                cita: this.data.cita_id,
                tipo_consulta: this.data.consulta_id,
                especialidad: this.asignar.especialidad.id,
                medico: this.asignar.medico.id,
                cama: this.data.cama,
            };

            this.historyService.postTraslado(body).subscribe(
                (res) => {
                    this.snackBar.open(res.message, 'INFO', {
                        horizontalPosition: this.horizontalPosition,
                        verticalPosition: this.verticalPosition,
                        duration: 3000,
                        panelClass: 'custom-blue',
                    });
                    this.dialogRef.close({
                        status: 200,
                    });
                    this.fuseProgressBarService.hide();
                },
                (err) => {
                    this.snackBar.open(err.message, 'ERROR', {
                        horizontalPosition: this.horizontalPosition,
                        verticalPosition: this.verticalPosition,
                        duration: 3000,
                        panelClass: 'custom-red',
                    });
                    this.fuseProgressBarService.hide();
                }
            );
        } else {
            this.snackBar.open('Seleccione todos los campos', 'OK', {
                horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,
                duration: 3000,
                panelClass: 'custom-red',
            });
            this.fuseProgressBarService.hide();
        }
    }

    getTipoConsulta(): void {
        this.fuseProgressBarService.show();
        this.historyService.getTipoConsulta().subscribe(
            (res) => {
                switch (this.data.consulta_id) {
                    case 5:
                        for (const i of res) {
                            if (i.id === 5 || i.id === 6) {
                                this.consulta.push(i);
                            }
                        }
                        this.search = this.consulta;
                        break;
                    case 6:
                        for (const i of res) {
                            if (i.id === 6) {
                                this.consulta.push(i);
                            }
                        }
                        this.search = this.consulta;
                        this.selectOption('consulta', this.search[0]);
                        break;
                    default:
                        this.consulta = this.search = [...res];
                        break;
                }
                this.fuseProgressBarService.hide();
            },
            (err) => {
                this.snackBar.open(err.message, 'ERROR', {
                    horizontalPosition: this.horizontalPosition,
                    verticalPosition: this.verticalPosition,
                    duration: 3000,
                    panelClass: 'custom-red',
                });
                this.fuseProgressBarService.hide();
            }
        );
    }

    getMedico(): void {
        this.fuseProgressBarService.show();
        this.historyService.getMedico(this.asignar.especialidad.id).subscribe(
            (res) => {
                this.medico = [...res];
                if (this.currentView === 'medico') {
                    this.search = [...res];
                }
                this.fuseProgressBarService.hide();
            },
            (err) => {
                this.snackBar.open(err.message, 'ERROR', {
                    horizontalPosition: this.horizontalPosition,
                    verticalPosition: this.verticalPosition,
                    duration: 3000,
                    panelClass: 'custom-red',
                });
                this.fuseProgressBarService.hide();
            }
        );
    }

    getEspecialidad(): void {
        {
            this.fuseProgressBarService.show();
            this.historyService.getEspecialidad().subscribe(
                (res) => {
                    this.especialidad = [...res];
                    this.fuseProgressBarService.hide();
                },
                (err) => {
                    this.snackBar.open(err.message, 'ERROR', {
                        horizontalPosition: this.horizontalPosition,
                        verticalPosition: this.verticalPosition,
                        duration: 3000,
                        panelClass: 'custom-red',
                    });
                    this.fuseProgressBarService.hide();
                }
            );
        }
    }
}
