import {
    AfterViewInit,
    Component,
    EventEmitter,
    Inject,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChange,
    SimpleChanges,
    ViewEncapsulation,
    ViewChild
} from '@angular/core';
// Archivos de traducción
import { locale as english } from '../../../i18n/en';
import { locale as spanish } from '../../../i18n/es';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import {
    MatDialog,
    MatDialogRef,
    MatSnackBar,
    MAT_DIALOG_DATA,
} from '@angular/material';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { FuseConfigService } from '@fuse/services/config.service';
import { takeUntil } from 'rxjs/operators';
import IMask from 'imask';
import { ActivatedRoute, Router } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { PdfJsViewerComponent } from 'ng2-pdfjs-viewer';
// Imports

@Component({
    selector: 'file-preview-dialog',
    templateUrl: './file-preview-dialog.html',
    styleUrls: ['./file-preview-dialog.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
})
export class FilePreviewDialogComponent implements OnInit, AfterViewInit {

    message: string = '';
    newBlob: Uint8Array;
    pdfViewer: PdfJsViewerComponent;
    pdfReady: boolean = false;
    @ViewChild('pdfViewer', { static: false }) set content(
        content: PdfJsViewerComponent
    ) {
        if (content) {
            this.pdfViewer = content;
        }
    }

    constructor(
        private fuseTranslate: FuseTranslationLoaderService,
        private fb: FormBuilder,
        private snackBar: MatSnackBar,
        private progressBar: FuseProgressBarService,
        public dialogRef: MatDialogRef<FilePreviewDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.fuseTranslate.loadTranslations(english, spanish);
        this.message = data ? data.texto : '';
        if (this.data.attachment) {
            if (this.data.attachment.tipo == 'application/pdf') {
                this.newBlob = this.base64ToArrayBuffer(this.data.attachment.data);
                this.pdfReady = true;
            }
        }
        else if (this.data.file) {
            if (this.data.file.tipo == 'application/pdf') {
                this.newBlob = this.base64ToArrayBuffer(this.data.file.data);
                this.pdfReady = true;
            }
        }
    }

    ngOnInit(): void {

    }

    ngAfterViewInit(): void {

    }

    getImageSource(file) {
        return `data:${file.tipo};base64,${file.data}`;
    }

    close() {
        this.dialogRef.close(
            {
                texto: this.message
            }
        );
    }

    base64ToArrayBuffer(base64): Uint8Array {
        let binary_string =  window.atob(base64.includes(',') ? String(base64).split(',')[1] : base64);
        let len = binary_string.length;
        let bytes = new Uint8Array( len );
        for (let i = 0; i < len; i++)        {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes;
    }
}
