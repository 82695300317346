import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToolbarMenuComponent } from './toolbar-menu.component';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseSidebarModule } from '@fuse/components';
import {
    MatButtonModule,
    MatIconModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatRadioModule,
    MatDialogModule
} from '@angular/material';
import { ContentModule } from 'app/layout/components/content/content.module';
import { FooterModule } from 'app/layout/components/footer/footer.module';
import { NavbarModule } from 'app/layout/components/navbar/navbar.module';
import { QuickPanelModule } from 'app/layout/components/quick-panel/quick-panel.module';
import { PipesCustomModule } from 'app/pipes/pipes.module';
import { TranslateModule } from '@ngx-translate/core';
import { NavigateAppointmentDialogComponent } from '../navigate-appointment-dialog/navigate-appointment-dialog.component';
import { ConfirmationDialogModule } from 'app/modules/shared/components/confirmation-dialog/confirmation-dialog.module';
import { TrialEndedDialogComponent } from '../trial-ended-dialog/trial-ended-dialog.component';
import { TrialEndedDialogService } from '../trial-ended-dialog/trial-ended-dialog.service';

@NgModule({
    declarations: [
        ToolbarMenuComponent,
        NavigateAppointmentDialogComponent,
        TrialEndedDialogComponent,
    ],
    imports: [
        CommonModule,
        FuseSharedModule,
        FuseSidebarModule,
        TranslateModule,
        ConfirmationDialogModule,

        // Material
        MatIconModule,
        MatButtonModule,
        MatTooltipModule,
        MatSnackBarModule,
        MatRadioModule,
        MatDialogModule,

        ContentModule,
        FooterModule,
        NavbarModule,
        QuickPanelModule,

        PipesCustomModule,
    ],
    exports: [ToolbarMenuComponent, NavigateAppointmentDialogComponent],
    entryComponents: [
        NavigateAppointmentDialogComponent,
        TrialEndedDialogComponent,
    ],
})
export class ToolbarMenuModule {}
