import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { HistoryService } from 'app/main/medic/history/history.service';
import { PortalService } from 'app/main/portal/portal.service';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { FuseConfigService } from '@fuse/services/config.service';
import { takeUntil } from 'rxjs/operators';
import { fuseAnimations } from '@fuse/animations';
import { ActivatedRoute, Router } from '@angular/router';
// Translations
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as es } from '../i18n/es';
import { locale as en } from '../i18n/en';
import { TranslateService } from '@ngx-translate/core';
import { isArray } from 'util';

@Component({
    selector: 'app-formulario-precita-dialog',
    templateUrl: './formulario-precita-dialog.component.html',
    styleUrls: ['./formulario-precita-dialog.component.scss'],
    animations: fuseAnimations,
})
export class FormularioPrecitaDialogComponent implements OnInit, OnDestroy {
    eventName: string;
    // url: string;
    template: any;
    citaInfo: any;
    precitaTemplate: any;
    response: any;
    fuseConfig: any;
    templateBody: any = {
        definicion: {},
        respuesta: {},
        completo: false,
        tipo: '',
        template_cita: '',
        cita: '',
    };
    responsable: any;
    queryParams: any;

    displayForm: boolean;
    formDisabled: boolean;
    selectedIndex: number = 0;

    @Input() nombre: string;

    private _unsubscribeAll = new Subject();

    constructor(
        public matDialogRef: MatDialogRef<FormularioPrecitaDialogComponent>,
        private _fuseProgressBarService: FuseProgressBarService,
        private translationLoader: FuseTranslationLoaderService,
        private snackBar: MatSnackBar,
        private historyService: HistoryService,
        private portalService: PortalService,
        private fuseConfigService: FuseConfigService,
        private route: ActivatedRoute,
        private router: Router,
        private translate: TranslateService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.translationLoader.loadTranslations(es, en);
        this.queryParams = this.route.snapshot.queryParams;
        this.fuseConfigService
            .getConfig()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config) => (this.fuseConfig = config));
    }

    ngOnInit(): void {
        // this.url = this.router.url;
        this.getTemplate(
            this.data.cuestionario_template || this.queryParams.idPlantilla
        );
    }

    getTemplate(templateId: string): void {
        this.portalService.getTemplatesById(templateId).subscribe((res) => {
            this.getCita(this.data.citaId || this.queryParams.idCita);
            this.template = res;
            this.eventName = this.template.definicion[0].section_name;
        });
    }

    changedTabs($event) {
        this.selectedIndex = $event.index;
    }

    getCita(citaId: string): void {
        this.historyService.getCitasCE(citaId).subscribe((res) => {
            this.citaInfo = res;
            if (
                this.fuseConfig.isPatient &&
                (res.estatus.toLowerCase() === 'atendida' ||
                    res.estatus.toLowerCase() === 'en consulta')
            ) {
                this.formDisabled = true;
            } else if (this.fuseConfig.isMedic && this.data.view === 'citas') {
                this.formDisabled = true;
            } else {
                this.formDisabled = false;
            }
            // this.formDisabled = res.tiempo_atencion !== null ? true : false;
            this.getResumenHistoria(
                this.data.citaId || this.queryParams.idCita
            );
        });
    }

    getResumenHistoria(citaId: string): void {
        this.historyService.getResumenHistoria(citaId).subscribe(
            (res) => {
                this._fuseProgressBarService.hide();
                this.precitaTemplate = res.secciones.filter(
                    (ele) => ele.tipo === 'precita'
                )[0];

                if (this.precitaTemplate) {
                    this.template.definicion = [
                        ...this.precitaTemplate.definicion.definicion,
                    ];
                    this.templateBody.respuesta = {
                        ...this.precitaTemplate.respuesta,
                    };
                }
                this.templateBody.definicion = {
                    definicion: [...this.template.definicion],
                };
                this.responsable =
                    this.templateBody.respuesta[
                        `${this.templateBody.definicion.definicion[0].section_name}_responsable`
                    ] || this.templateBody.respuesta[`responsable`];
                this.displayForm = true;
            },
            (error) => {
                this._fuseProgressBarService.hide();
                this.snackBar.open(error.error.message, '', {
                    verticalPosition: 'top',
                    panelClass: 'custom-red',
                    duration: 3000,
                });
            }
        );
    }

    saveTemplate($event: any): void {
        // this.clearMatrixcheckbox($event);
        const fields = this.template.definicion.filter((ele) => {
            if (ele.section_name === $event.name) {
                return ele.fields;
            }
        });
        if (fields[0]) {
            for (const i of fields[0].fields) {
                for (const key in $event.form) {
                    if (i.name === key) {
                        i.value = $event.form[key];
                        break;
                    }
                }
            }
            this.templateBody.respuesta[$event.name] = fields[0].fields;
        }
    }

    clearMatrixcheckbox($event: any): void {
        for (const def in $event.form) {
            if (this.isArray($event.form[def])) {
                for (const field in $event.form[def]) {
                    if ($event.form[def][field].columns) {
                        $event.form[def] = $event.form[def].filter(
                            (ele) => ele.columns
                        );
                        break;
                    }
                }
            }
        }
    }

    postTemplate(): void {
        this.templateBody.tipo = 'precita';
        this.templateBody.template_cita =
            this.data.cuestionario_template || this.queryParams.idPlantilla;
        this.templateBody.cita = this.data.citaId || this.queryParams.idCita;
        this.templateBody.completo = this.isComplete(
            this.templateBody.respuesta
        );
        if (this.templateBody.respuesta[`responsable`] === undefined) {
            this.templateBody.respuesta[`responsable`] = [];
        }
        this.templateBody.respuesta[`responsable`].push({
            user:
                this.fuseConfig.user.lastname !== ''
                    ? `${this.fuseConfig.user.firstname} ${this.fuseConfig.user.lastname}`
                    : this.nombre,
            fecha:
                moment().locale('es-do').format('LL') +
                ' ' +
                moment().locale('es-do').format('hh:mm A'),
        });

        this._fuseProgressBarService.show();
        this.historyService.postTemplatesCita(this.templateBody).subscribe(
            (res) => {
                this._fuseProgressBarService.hide();
                this.snackBar.open(
                    this.translate.instant('DIALOG.SUCCESS_FORM'),
                    '',
                    {
                        verticalPosition: 'top',
                        panelClass: 'custom-blue',
                        duration: 3000,
                    }
                );

                if (this.data === false) {
                    this.router.navigate(['/']);
                } else {
                    this.matDialogRef.close({
                        citaId: this.data.citaId,
                        formCompleted: this.templateBody.completo,
                    });
                }
            },
            (error) => {
                this._fuseProgressBarService.hide();
                this.snackBar.open(error.error.message, '', {
                    verticalPosition: 'top',
                    panelClass: 'custom-red',
                    duration: 3000,
                });
            }
        );
    }

    selectResponse(name: string): any {
        if (this.precitaTemplate) {
            for (const key in this.precitaTemplate.respuesta) {
                if (key === name) {
                    const selectedResponse = {
                        ...this.precitaTemplate.respuesta[key],
                    };
                    // const unsorted = this.respuesta[key];
                    // this.selectedResponse = this.sortObjectByKeys(unsorted);
                    return selectedResponse;
                }
            }
        }

        return {};
    }

    isComplete(response: any): boolean {
        for (const key in response) {
            for (const i of response[key]) {
                if (this.isArray(i.value) && this.arrayHasValue(i.value)) {
                    return true;
                } else if (
                    i.value !== '' &&
                    i.value !== false &&
                    i.value !== null &&
                    i.value !== undefined
                ) {
                    return true;
                }
            }
        }

        return false;
    }

    isArray(variable: any): boolean {
        return Array.isArray(variable);
    }

    arrayHasValue(variable: Array<any>): boolean {
        for (const i of variable) {
            if (i.checked) {
                return true;
            }
        }
        return false;
    }

    navigateHome(): void {
        this.router.navigate(['/']);
    }

    makeResponseObject(responseArray: any[]): void {
        this.response = {};
        responseArray.forEach((element) => {
            this.response[element.name] = element.value;
        });
        this.displayForm = true;
    }

    ngOnDestroy(): void {
        //Called once, before the instance is destroyed.
        //Add 'implements OnDestroy' to the class.
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}
