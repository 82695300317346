import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
    selector: 'app-active-call-dialog',
    templateUrl: './active-call-dialog.component.html',
    styleUrls: ['./active-call-dialog.component.scss'],
})
export class ActiveCallDialogComponent implements OnInit {
    constructor(public dialogRef: MatDialogRef<ActiveCallDialogComponent>) {}

    ngOnInit(): void {}
}
