import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import {
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatToolbarModule,
    MatDividerModule,
    MatTooltipModule,
} from '@angular/material';

import {
    FuseSearchBarModule,
    FuseShortcutsModule,
    FuseSidebarModule,
} from '@fuse/components';
import { FuseSharedModule } from '@fuse/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { ActiveVideocallDialogModule } from 'app/modules/active-videocall-dialog/active-videocall-dialog.module';
import { ToolbarMenuModule } from 'app/navigation/toolbar-menu/toolbar-menu.module';

import { ToolbarComponent } from 'app/layout/components/toolbar/toolbar.component';

@NgModule({
    declarations: [ToolbarComponent],
    imports: [
        RouterModule,
        TranslateModule,
        MatButtonModule,
        MatIconModule,
        MatMenuModule,
        MatToolbarModule,
        MatDividerModule,
        MatTooltipModule,
        ActiveVideocallDialogModule,
        ToolbarMenuModule,

        FuseSharedModule,
        FuseSearchBarModule,
        FuseShortcutsModule,
        FuseSidebarModule,
    ],
    exports: [ToolbarComponent],
})
export class ToolbarModule {}
