import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActiveCallDialogComponent } from 'app/main/medic/history/ingreso/modals/active-call-dialog/active-call-dialog.component';
import { TranslateModule } from '@ngx-translate/core';
import {
    MatButtonModule,
    MatDividerModule,
    MatIconModule,
    MatTooltipModule,
} from '@angular/material';

@NgModule({
    declarations: [ActiveCallDialogComponent],
    imports: [
        CommonModule,
        TranslateModule,
        // Material
        MatIconModule,
        MatTooltipModule,
        MatDividerModule,
        MatButtonModule,
    ],
    exports: [ActiveCallDialogComponent],
    entryComponents: [ActiveCallDialogComponent],
})
export class ActiveVideocallDialogModule {}
