import { Component } from '@angular/core';
// Archivos de traducción
import { locale as english } from './i18n/en';
import { locale as spanish } from './i18n/es';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { Subject } from 'rxjs';
import { FuseConfigService } from '@fuse/services/config.service';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
    config: any;
    private _unsubscribeAll: Subject<any> = new Subject();
    /**
     * Constructor
     */
    constructor(
        private fuseConfig: FuseConfigService,
        private fuseTranslate: FuseTranslationLoaderService
    ) {
        this.fuseTranslate.loadTranslations(english, spanish);
        this.fuseConfig
            .getConfig()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config) => {
                this.config = config;
            });
    }
}
