import {
    Component,
    ViewEncapsulation,
    Inject,
    OnInit,
    OnDestroy,
    ViewChild,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { locale as es } from './i18n/es';
import { locale as en } from './i18n/en';
import * as moment from 'moment';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
// import { PaymentDialogComponent } from '../../../sales/invoice/payment-dialog/payment-dialog.component';
// import { StripePaymentDialogComponent } from './stripe-payment-dialog/stripe-payment-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { FuseConfigService } from '@fuse/services/config.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { PatientService } from 'app/main/patient/patient.service';
import { DireccionComponent } from 'app/modules/shared/components/direccion/direccion.component';
import { C } from '@angular/cdk/keycodes';

@Component({
    selector: 'app-confirmar-cita',
    templateUrl: './confirmar-cita.component.html',
    styleUrls: ['./confirmar-cita.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ConfirmarCitaDialogComponent implements OnInit, OnDestroy {
    useMoment = moment;
    config: FuseConfigService | any;
    citaPrepagada = false;
    stripe = false;
    data: any = {
        cupo: {
            id_horario: '',
            consultorio: '',
            fecha: '',
            hora_desde: '',
        },
        info: {},
    };
    simbolo: string;
    motivo: string = '';
    patientInfo: any;
    disable = false;

    @ViewChild('appDireccion', { static: false })
    appDireccion: DireccionComponent;

    private unsubscribeAll = new Subject();

    constructor(
        private fuseConfig: FuseConfigService,
        public matDialogRef: MatDialogRef<ConfirmarCitaDialogComponent>,
        private matDialog: MatDialog,
        private translationLoader: FuseTranslationLoaderService,
        private patientService: PatientService,
        @Inject(MAT_DIALOG_DATA) private _data: any
    ) {
        this.translationLoader.loadTranslations(es, en);
        this.fuseConfig
            .getConfig()
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((config) => (this.config = config));
    }

    ngOnInit() {
        this.data = this._data.body;
        this.simbolo = this._data.simbolo;
        this.motivo = this.data.motivo || '';
        this.citaPrepagada = this.data.medico.telemedicina.cita_prepagada;
        this.stripe = false;

        if (this.data.info.es_a_domicilio) {
            this.getPatientInfo();
        }
    }

    // stripetDialog(): void {
    //     const dialog = this.matDialog.open(StripePaymentDialogComponent, {
    //         width: '550px',
    //         panelClass: 'custom-dialog',
    //         autoFocus: false,
    //         data: this._data,
    //     });

    //     const subs = dialog.afterClosed().subscribe((res) => {
    //         if (res && res.succesful) {
    //             this.matDialogRef.close({
    //                 prepaid: true,
    //                 motivo: this.motivo,
    //                 stripeRef: res.stripeRef,
    //                 dataPayment: res.dataPayment ? res.dataPayment : null,
    //                 appDireccion: this.appDireccion,
    //             });
    //         }
    //         subs.unsubscribe();
    //     });
    // }

    getPatientInfo(): void {
        this.disable = true;
        this.patientService
            .getPacienteById(this.data.paciente)
            .subscribe((res) => {
                this.patientInfo = { ...res };
                this.disable = false;
            });
    }

    ngOnDestroy(): void {
        this.unsubscribeAll.next();
        this.unsubscribeAll.complete();
    }
}
