import { Component, OnInit, OnDestroy, AfterViewChecked, Inject } from '@angular/core';
import { TrialEndedDialogService } from '../trial-ended-dialog/trial-ended-dialog.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { FuseConfigService } from '@fuse/services/config.service';
import { locale as english } from '../i18n/en';
import { locale as spanish } from '../i18n/es';
import { Router } from '@angular/router';
import { pipe, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatSnackBar, MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';

@Component({
  selector: 'app-trial-ended-dialog',
  templateUrl: './trial-ended-dialog.component.html',
  styleUrls: ['./trial-ended-dialog.component.scss']
})
export class TrialEndedDialogComponent implements OnInit {

  config: any;
  private _unsubscribeAll: Subject<any> = new Subject();
  plans = [];
  specs = [];
  emailSent: boolean = false;
  selectedPlan: number = 3;
  subButtonColors: any = [
    {
      bg: '#42b6e5',
      fg: 'white'
    },
    {
      bg: '#065d88',
      fg: 'white'
    },
    {
      bg: '#026397',
      fg: 'white'
    }
  ]

  constructor(
    public trialService: TrialEndedDialogService,
    private fuseConfig: FuseConfigService,
    private fuseTranslate: FuseTranslationLoaderService,
    private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<TrialEndedDialogComponent>,
    private router: Router,
    private _fuseConfigService: FuseConfigService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) private _data: any
  ) {
    this.fuseTranslate.loadTranslations(english, spanish);
    this.fuseConfig
        .getConfig()
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((config) => {
            this.config = config;
        });
    this.emailSent = _data.emailSent
  }

  ngOnInit() {
    this.getPlans();
  }

  getPlans() {
    this.trialService.getPlanData().subscribe(res => {
      this.plans = res.planes;
      this.specs = res.caracteristicas;
    }, err => {
      this.snackBar.open(err.error.message, '', {
          verticalPosition: 'top',
          duration: 3000,
          panelClass: 'custom-red',
      });
    });
  }

  sendEmail(plan) {
    let body = {
      plan: plan.plan_suscripcion
    }
    this.trialService.sendPlanEmail(body).subscribe(res => {
      this.emailSent = true;
      // this.dialogRef.disableClose = false;
      this.dialogRef.updateSize('600px', '530px');
      // this.dialogRef.close(true);

    }, err => {
      this.snackBar.open(err.error.message, '', {
          verticalPosition: 'top',
          duration: 3000,
          panelClass: 'custom-red',
      });
    });
  }

  matchPlanSpec(plan, spec) {
    return spec[`plan_suscripcion_${plan.plan_suscripcion}`];
  }

  getSubButtonColor(plan, type) {
    return plan.plan_suscripcion > (this.subButtonColors.length + 1) ? this.subButtonColors[0][type] : this.subButtonColors[plan.plan_suscripcion-1][type];
  }

  logout() {
    this.trialService.logout().subscribe(
        (data) => {
            this.dialogRef.disableClose = false;
            // this.dialogRef.close();
            this.dialog.closeAll();
            localStorage.removeItem('authToken');
            sessionStorage.removeItem('authToken');
            this.router.navigate(['/auth/main']);
            this._fuseConfigService.resetToDefaults();
        },
        (err) => {
            // console.log(err);
            this.dialogRef.disableClose = false;
            // this.dialogRef.close();
            this.dialog.closeAll();
            localStorage.removeItem('authToken');
            sessionStorage.removeItem('authToken');
            this.router.navigate(['/auth/main']);
        }
    );
    this._fuseConfigService.resetToDefaults();
  }

}
