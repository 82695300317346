import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { locale as english } from './i18n/es';
import { locale as spanish } from './i18n/en';

@Injectable()
export class MatPaginatorIntlCro extends MatPaginatorIntl {
    itemsPerPageLabel = '';
    nextPageLabel = '';
    previousPageLabel = '';
    firstPageLabel = '';
    lastPageLabel = '';
    constructor(
        private translate: TranslateService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService
    ) {
        super();
        this._fuseTranslationLoaderService.loadTranslations(english, spanish);
        this.itemsPerPageLabel = this.translate.instant('ITEMS');
        this.nextPageLabel = this.translate.instant('NEXT_PAGE');
        this.previousPageLabel = this.translate.instant('PREVIOUS_PAGE');
        this.firstPageLabel = this.translate.instant('FIRST_PAGE');
        this.lastPageLabel = this.translate.instant('LAST_PAGE');
    }

    getRangeLabel = function (page, pageSize, length) {
        if (length === 0 || pageSize === 0) {
            return '0 ' + this.translate.instant('OF') + length;
        }
        length = Math.max(length, 0);
        const startIndex = page * pageSize;
        // If the start index exceeds the list length, do not try and fix the end index to the end.
        const endIndex =
            startIndex < length
                ? Math.min(startIndex + pageSize, length)
                : startIndex + pageSize;
        return (
            startIndex +
            1 +
            ' - ' +
            endIndex +
            ' ' +
            this.translate.instant('OF') +
            ' ' +
            length
        );
    };
}
