import { Component, OnInit, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { HistoryService } from '../../history.service';
import {
    MatSnackBar,
    MatSnackBarHorizontalPosition,
    MatSnackBarVerticalPosition,
} from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { QuirurgicoService } from '../../quirurgico/quirurgico.service';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { CitasService } from '../../citas/citas.service';
import { FuseConfigService } from '@fuse/services/config.service';

@Component({
    selector: 'app-botonera_his',
    templateUrl: './botonera_his.component.html',
    styleUrls: ['./botonera_his.component.scss'],
})
export class BotoneraHis implements OnInit {
    paciente_id: any;
    cita_id: any;
    om_id: any;
    validar = false;

    selected: any;
    ruta: any;
    view: any;

    hideMenu: boolean;
    isNurse: boolean = false
    @Input() consulta_id: any;
    @Input() ingresado: boolean;

    // constiables privadas
    private _unsubscribeAll: Subject<any>;
    public data: any;
    public horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    public verticalPosition: MatSnackBarVerticalPosition = 'top';

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private quirurgicoServ: QuirurgicoService,
        private citasServ: CitasService,
        private fuseConfig: FuseConfigService
    ) {
        this.paciente_id = this.route.snapshot.paramMap.get('paciente_id');
        this.cita_id = this.route.snapshot.paramMap.get('cita_id');
        this.om_id = this.route.snapshot.paramMap.get('om_id');
    }
    ngOnInit(): void {
        this.fuseConfig.getConfig().subscribe(data => this.isNurse = data.isNurse)
        this.quirurgicoServ.changeDisplay(false);
        this.quirurgicoServ.showOption.subscribe(
            (bool) => (this.hideMenu = bool)
        );

        this.ruta = this.selected = this.view = this.route.snapshot.paramMap.get(
            'view_id'
        );

        if (this.view === null) {
            this.view = 'citas';
        }
    }

    altaClass(): string {
        const viewLocation = this.route.snapshot.paramMap.get('view_id');
        return viewLocation === 'alta'
            ? 'mat-raised-button mat-accent estilizar-botones'
            : 'mat-raised-button mat-basic estilizar-botones';
    }

    notasOperatoriasClass(): string {
        const viewLocation = this.route.snapshot.paramMap.get('view_id');
        return viewLocation === 'notas_operatorias' ||
            viewLocation === 'detalles_notas_operatorias'
            ? 'mat-raised-button mat-accent estilizar-botones'
            : 'mat-raised-button mat-basic estilizar-botones';
    }

    laboratorioClass(): string {
        const viewLocation = this.route.snapshot.paramMap.get('view_id');
        return viewLocation === 'laboratorio' ||
            viewLocation === 'laboratorio_resultados'
            ? 'mat-raised-button mat-accent estilizar-botones'
            : 'mat-raised-button mat-basic estilizar-botones';
    }

    imagenesClass(): string {
        const viewLocation = this.route.snapshot.paramMap.get('view_id');
        return viewLocation === 'imagenes' || viewLocation === 'cargar_archivos'
            ? 'mat-raised-button mat-accent estilizar-botones'
            : 'mat-raised-button mat-basic estilizar-botones';
    }

    bodyContentClass(): string {
        // router  is an instance of Router, injected in the constructor
        const viewLocation = this.route.snapshot.paramMap.get('view_id');
        return viewLocation === 'listar_ordenes_medicas' ||
            viewLocation === 'ordenes_medicas_hospitalizacion' ||
            viewLocation === 'ordenes_medicas_hospitalizacion_editar' ||
            viewLocation === 'ordenes_medicas_dieta' ||
            viewLocation === 'ordenes_medicas_dieta_editar' ||
            viewLocation === 'ordenes_medicas_mezclas' ||
            viewLocation === 'ordenes_medicas_mezclas_editar' ||
            viewLocation === 'ordenes_medicas_tratamiento' ||
            viewLocation === 'ordenes_medicas_tratamiento_editar' ||
            viewLocation === 'ordenes_medicas_laboratorio' ||
            viewLocation === 'ordenes_medicas_laboratorio_editar' ||
            viewLocation === 'ordenes_medicas_imagenes' ||
            viewLocation === 'ordenes_medicas_imagenes_editar' ||
            viewLocation === 'ordenes_medicas_cirugia' ||
            viewLocation === 'ordenes_medicas_cirugia_editar' ||
            viewLocation === 'ordenes_medicas_interconsulta' ||
            viewLocation === 'ordenes_medicas_interconsulta_editar' ||
            viewLocation === 'ordenes_medicas_posicion' ||
            viewLocation === 'ordenes_medicas_posicion_editar' ||
            viewLocation === 'ordenes_medicas_otros' ||
            viewLocation === 'ordenes_medicas_otros_editar'
            ? 'mat-raised-button mat-accent estilizar-botones'
            : 'mat-raised-button mat-basic estilizar-botones';
    }

    changeContent(content): void {
        this.selected = content;
        if (this.view !== 'citas') {
            const paciente_id = this.route.snapshot.paramMap.get('paciente_id');
            const cita_id = this.route.snapshot.paramMap.get('cita_id');
            const consulta_id = this.route.snapshot.paramMap.get('consulta_id');
            const om_id = this.route.snapshot.paramMap.get('om_id');
            this.ruta = content;
            this.router.navigate([
                'medic/history/' +
                    paciente_id +
                    '/' +
                    cita_id +
                    '/' +
                    content +
                    '/' +
                    consulta_id +
                    '/' +
                    om_id,
            ]);
        } else {
            this.ruta = content;
            this.citasServ.changeSelected(content);
        }
    }
}
