import {
    Component,
    ElementRef,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { PortalService } from 'app/main/portal/portal.service';
import { locale as es } from '../i18n/es';
import { locale as en } from '../i18n/en';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { ConfirmationDialogComponent } from 'app/modules/shared/components/confirmation-dialog/confirmation-dialog.component';

@Component({
    selector: 'app-direccion',
    templateUrl: './direccion.component.html',
    styleUrls: ['./direccion.component.scss'],
})
export class DireccionComponent implements OnInit, OnChanges {
    editMode: boolean = true;
    eventInfo: any;
    paises: any[] = [];
    ciudades: any[] = [];
    filteredPaises: any[] = [];
    filteredCiudades: any[] = [];

    pais: any;
    ciudad: any;
    customPais: string;
    customCiudad: string;
    direccion: string;

    @Input() event: any;
    @Input() patient: any;
    @Input() disableModal: boolean = false;

    @ViewChild('countryInput', { static: true }) countryInput: ElementRef;
    @ViewChild('cityInput', { static: true }) cityInput: ElementRef;

    constructor(
        private fb: FormBuilder,
        private portalService: PortalService,
        private matDialog: MatDialog,
        private translate: TranslateService,
        private translationLoader: FuseTranslationLoaderService
    ) {}

    ngOnInit(): void {
        this.getPaises();
        this.setVariables();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.patient && changes.patient.currentValue) {
            this.setVariables();
        }
    }

    getPaises(): void {
        this.portalService.getPaises().subscribe((res) => {
            this.paises = [...res];
            this.filteredPaises = [...res];
        });
    }

    getCiudades(idPais: string): void {
        this.portalService.getCiudadesByPais(idPais).subscribe((res) => {
            this.ciudades = [...res];
            this.filteredCiudades = [...res];
        });
    }

    displayFn(state): any {
        if (state === null || state === undefined) {
            return '';
        } else if (state.descripcion) {
            return state.descripcion;
        } else {
            return state;
        }
    }

    searchPais(value: string): void {
        this.pais = undefined;
        this.filteredPaises = this.paises.filter((ele) =>
            ele.descripcion.toLowerCase().includes(value.toLowerCase())
        );

        if (value.length === 0) {
            this.filteredPaises = [...this.paises];
        }
    }

    searchCiudad(value: string): void {
        this.ciudad = undefined;
        this.filteredCiudades = this.ciudades.filter((ele) =>
            ele.descripcion.toLowerCase().includes(value.toLowerCase())
        );

        if (value.length === 0) {
            this.filteredCiudades = [...this.ciudades];
        }
    }

    paisSelected($event: any): void {
        this.pais = $event.option.value;
        this.ciudad = undefined;
        this.getCiudades(this.pais.id);
        this.customPais = $event.option.value.descripcion;
    }

    ciudadSelected($event: any): void {
        this.ciudad = {
            id: $event.option.value.id,
            descripcion: $event.option.value.descripcion,
        };
        this.customCiudad = $event.option.value.descripcion;
        // this.getCiudades(this.pais.id);
    }

    pointerValidation(): any {
        if (
            ((this.pais && this.ciudad) ||
                (this.pais && this.customCiudad) ||
                (this.customPais && this.customCiudad)) &&
            this.direccion !== undefined &&
            this.direccion.trim() !== '' &&
            this.direccion !== null
        ) {
            return true;
        }

        return false;
    }

    confirm(): void {
        if (
            ((this.pais && this.ciudad) ||
                (this.pais && this.customCiudad) ||
                (this.customPais && this.customCiudad)) &&
            this.direccion !== undefined &&
            this.direccion.trim() !== '' &&
            this.direccion !== null
        ) {
            this.editMode = false;
            if (!this.disableModal) {
                const dialogRef = this.matDialog.open(
                    ConfirmationDialogComponent,
                    {
                        maxWidth: '550px',
                        minWidth: '300px',
                        panelClass: 'custom-dialog',
                        autoFocus: false,
                        data: {
                            text: this.translate.instant(
                                'DIRECTION.UPDATE_MSG'
                            ),
                        },
                    }
                );
                const sub = dialogRef.afterClosed().subscribe((res) => {
                    if (res) {
                        const body = { ...this.patient };
                        body.direccion = this.direccion;
                        body.fecha_nacimiento = body.nacimiento;
                        body.estado = body.estado.id;
                        if (body.estado === undefined) {
                            delete body.estado;
                        }
                        if (this.pais) {
                            body.pais = this.pais.id;
                        } else {
                            delete body.pais;
                        }
                        if (this.ciudad) {
                            body.ciudad = this.ciudad.id;
                        } else {
                            delete body.ciudad;
                        }
                        this.portalService
                            .updatePacientes(body)
                            .subscribe((res) => {
                                console.log(res);
                            });
                    }
                    sub.unsubscribe();
                });
            }
        }
    }

    setVariables(): void {
        if (this.event.pais) {
            this.customPais = this.event.pais_nombre;
            this.pais = {
                id: this.event.pais,
                descripcion: this.event.pais_nombre,
            };
            this.customCiudad = this.event.ciudad;
            this.direccion = this.event.direccion;
        } else if (this.patient.pais !== undefined) {
            this.customPais = this.patient.pais.descripcion
                ? this.patient.pais.descripcion
                : '';
            this.countryInput.nativeElement.value = this.customPais;
            this.pais = {
                id: this.patient.pais.id,
                descripcion: this.patient.pais.descripcion
                    ? this.patient.pais.descripcion
                    : '',
            };
            this.ciudad = {
                id: this.patient.ciudad.id,
                descripcion: this.patient.ciudad.descripcion,
            };
            this.customCiudad = this.patient.ciudad.descripcion
                ? this.patient.ciudad.descripcion
                : '';
            this.cityInput.nativeElement.value = this.customCiudad;
            this.direccion = this.patient.direccion;
        }

        this.customCiudad &&
        this.customCiudad.length > 0 &&
        this.customPais &&
        this.customPais.length > 0 &&
        this.direccion &&
        this.direccion.length > 0
            ? (this.editMode = false)
            : (this.editMode = true);
    }
}
