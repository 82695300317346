import {
    Component,
    OnInit,
    ChangeDetectorRef,
    Input,
    EventEmitter,
    Output,
} from '@angular/core';
import * as moment from 'moment';

@Component({
    selector: 'horas-listado',
    templateUrl: './horas-listado.component.html',
    styleUrls: ['./horas-listado.component.scss'],
})
export class HorasListadoComponent implements OnInit {
    useMoment = moment;

    lunesActual;

    private _citas: any = [];
    @Input()
    set citas(citas) {
        this._citas = citas.cupos;
        this.lunesActual = citas.fecha;
        this.arrangeCupos();
    }

    get citas() {
        return this._citas;
    }

    @Output('agendar') data = new EventEmitter();

    citasLunes = [];
    citasLunesResumen = [];
    citasMartes = [];
    citasMartesResumen = [];
    citasMiercoles = [];
    citasMiercolesResumen = [];
    citasJueves = [];
    citasJuevesResumen = [];
    citasViernes = [];
    citasViernesResumen = [];
    // len = 0;
    resumirLunes = true;
    resumirMartes = true;
    resumirMiercoles = true;
    resumirJueves = true;
    resumirViernes = true;
    loading = true;

    constructor() {
        this.lunesActual = moment().subtract(moment().day() - 1, 'days');
    }

    ngOnInit() {}

    agendar(cupo) {
        if (cupo.id_horario === null) {
            switch (cupo.day) {
                case 'lunes':
                    this.resumirLunes = false;
                    break;
                case 'martes':
                    this.resumirMartes = false;
                    break;
                case 'miercoles':
                    this.resumirMiercoles = false;
                    break;
                case 'jueves':
                    this.resumirJueves = false;
                    break;
                case 'viernes':
                    this.resumirViernes = false;
                    break;
            }
        } else {
            this.data.emit(cupo);
        }
    }

    arrangeCupos() {
        // this.len = 0;
        this.citasLunes = [];
        this.citasLunesResumen = [];
        this.citasMartes = [];
        this.citasMartesResumen = [];
        this.citasMiercoles = [];
        this.citasMiercolesResumen = [];
        this.citasJueves = [];
        this.citasJuevesResumen = [];
        this.citasViernes = [];
        this.citasViernesResumen = [];
        this.citasLunes = this._citas.filter((c) =>
            moment(c.fecha).isSame(moment(this.lunesActual), 'day')
        );
        if (this.citasLunes.length > 3) {
            this.citasLunesResumen = this.citasLunes.slice(0, 3);
            this.citasLunesResumen.push({
                id_horario: null,
                hora_desde: '+',
                day: 'lunes',
            });
        } else this.citasLunesResumen = this.citasLunes;
        // if(this.citasLunes.length > this.len) this.len = this.citasLunes.length;

        this.citasMartes = this._citas.filter((c) =>
            moment(c.fecha).isSame(
                moment(this.lunesActual).add(1, 'days'),
                'day'
            )
        );
        if (this.citasMartes.length > 3) {
            this.citasMartesResumen = this.citasMartes.slice(0, 3);
            this.citasMartesResumen.push({
                id_horario: null,
                hora_desde: '+',
                day: 'martes',
            });
        } else this.citasMartesResumen = this.citasMartes;
        // if(this.citasMartes.length > this.len) this.len = this.citasMartes.length;

        this.citasMiercoles = this._citas.filter((c) =>
            moment(c.fecha).isSame(
                moment(this.lunesActual).add(2, 'days'),
                'day'
            )
        );
        if (this.citasMiercoles.length > 3) {
            this.citasMiercolesResumen = this.citasMiercoles.slice(0, 3);
            this.citasMiercolesResumen.push({
                id_horario: null,
                hora_desde: '+',
                day: 'miercoles',
            });
        } else this.citasMiercolesResumen = this.citasMiercoles;
        // if(this.citasMiercoles.length > this.len) this.len = this.citasMiercoles.length;

        this.citasJueves = this._citas.filter((c) =>
            moment(c.fecha).isSame(
                moment(this.lunesActual).add(3, 'days'),
                'day'
            )
        );
        if (this.citasJueves.length > 3) {
            this.citasJuevesResumen = this.citasJueves.slice(0, 3);
            this.citasJuevesResumen.push({
                id_horario: null,
                hora_desde: '+',
                day: 'jueves',
            });
        } else this.citasJuevesResumen = this.citasJueves;
        // if(this.citasJueves.length > this.len) this.len = this.citasJueves.length;

        this.citasViernes = this._citas.filter((c) =>
            moment(c.fecha).isSame(
                moment(this.lunesActual).add(4, 'days'),
                'day'
            )
        );
        if (this.citasViernes.length > 3) {
            this.citasViernesResumen = this.citasViernes.slice(0, 3);
            this.citasViernesResumen.push({
                id_horario: null,
                hora_desde: '+',
                day: 'viernes',
            });
        } else this.citasViernesResumen = this.citasViernes;
        // if(this.citasViernes.length > this.len) this.len = this.citasViernes.length;

        this.loading = false;
    }
}
