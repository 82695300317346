import { Injectable, OnDestroy } from '@angular/core';
import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    Router,
} from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from '../main/auth/auth.service';
import { fuseConfig } from '../fuse-config';
import { FuseConfigService } from '@fuse/services/config.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { map, takeUntil } from 'rxjs/operators';
import { rolesNavigation } from '../fuse-config/roles';
import { MatSnackBar } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as english } from '../main/auth/login-2/i18n/en';
import { locale as spanish } from '../main/auth/login-2/i18n/es';
import * as moment from 'moment';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard implements CanActivate, OnDestroy {
    private _unsubscribeAll = new Subject();
    private config;

    constructor(
        private router: Router,
        private http: HttpClient,
        public authService: AuthService,
        public fuseConfigService: FuseConfigService,
        private snackBar: MatSnackBar,
        private _translateService: TranslateService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _fuseNavigationService: FuseNavigationService
    ) {
        this.fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((res) => {
                this.config = res;
            });

        this._fuseTranslationLoaderService.loadTranslations(english, spanish);
    }
    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | boolean {
        const params = window.location.hash.split('/');
        if (this.authService.isAuthenticated === true) {
            this.addChargesNavigation();
            switch (params[1]) {
                case 'portal':
                case 'medic':
                    if (this.config.isMedic) {
                        return true;
                    }
                    else if(this.config.isNurse){
                        return true;
                    }
                    return false;

                case 'pacientes':
                    if (this.config.isPatient) {
                        return true;
                    }
                    return false;

                case 'secretary':
                    if (this.config.isSecretary) {
                        return true;
                    }
                    return false;

                case 'admin':
                    if (this.config.isAdmin) {
                        return true;
                    }
                    return false;

                default:
                    return true;
            }
        } else {
            return this.authService.getProfile().pipe(
                map(
                    (res: any) => {
                        this.authService.isAuthenticated = true;
                        this.fuseConfigService.config = {
                            user: {
                                id: res.userId,
                                id_paciente: res.paciente ? res.paciente : '',
                                firstname: res.firstname,
                                lastname: res.lastname,
                                email: res.email,
                                image: res.image
                                    ? res.image
                                    : 'assets/images/avatars/profile.jpg',
                                especialidades: res.especialidades,
                                ambulatorio: res.ambulatorio,
                            },
                        };

                        // Set theme and layout
                        if (res.layout !== null) {
                            this.fuseConfigService.config = {
                                colorTheme: res.layout.colorTheme,
                                layout: {
                                    style: res.layout.style,
                                    width: res.layout.width,
                                    navbar: res.layout.navbar,
                                    toolbar: res.layout.toolbar,
                                    footer: res.layout.footer,
                                    sidepanel: res.layout.sidepanel,
                                },
                            };
                        }

                        // Set global roles booleans
                        this.fuseConfigService.config = {
                            isMedic: this.getRole(res.role, 'medico'),
                            isAdmin: this.getRole(res.role, 'admin'),
                            isSecretary: this.getRole(res.role, 'secretaria'),
                            isPatient: this.getRole(res.role, 'paciente'),
                            isNurse: this.getRole(res.role, 'enfermeria'),
                        };

                        this.authService
                            .getClinic(res.ambulatorio[0].ambulatorio)
                            .subscribe((resp) => {
                                // Validacion de plan
                                if (resp.plan_suscripcion === 0) {
                                    this.snackBar.open(
                                        this._translateService.instant(
                                            'LOGIN.INACTIVE_ACCOUNT'
                                        ),
                                        '',
                                        {
                                            verticalPosition: 'top',
                                            duration: 5000,
                                            panelClass: 'custom-red',
                                        }
                                    );
                                    this.router.navigate(['/']);
                                    return;
                                }
                                // Validacion de modulos
                                if (
                                    !resp.modulos.telemedicina &&
                                    !resp.modulos.historia_medica &&
                                    !resp.modulos.control_citas &&
                                    !res.rol_administrativo
                                ) {
                                    this.snackBar.open(
                                        this._translateService.instant(
                                            'LOGIN.BLOCKED'
                                        ),
                                        '',
                                        {
                                            verticalPosition: 'top',
                                            duration: 5000,
                                            panelClass: 'custom-blue',
                                        }
                                    );
                                    this.router.navigate(['/']);
                                    return;
                                }
                                this.fuseConfigService.config = {
                                    // encargado
                                    responsable: resp.responsable,
                                    cita_a_domicilio: resp.cita_a_domicilio,
                                    modules: {
                                        modulo_telemedicina:
                                            resp.modulos.telemedicina,
                                        modulo_historia_medica:
                                            resp.modulos.historia_medica,
                                        modulo_control_citas:
                                            resp.modulos.control_citas,
                                        modulo_ventas: resp.modulos.ventas,
                                    },
                                    // Seteo de moneda y formas de pago
                                    moneda: {
                                        id: resp.moneda.id,
                                        nombre: resp.moneda.nombre,
                                        iso: resp.moneda.iso,
                                        simbolo: resp.moneda.simbolo,
                                    },
                                    formas_pago: resp.formas_pago,
                                    impuesto_nombre: resp.impuesto_nombre,
                                    impuesto_porcentaje:
                                        resp.impuesto_porcentaje,
                                    // Set de config
                                    plan: resp.plan_suscripcion,
                                    dias_restantes:
                                        resp.dias_prueba -
                                        this.calculateDays(
                                            resp.fecha_activacion,
                                            resp.fecha_servidor
                                        ),
                                    modo_prueba:
                                        resp.dias_prueba !== 0 ? true : false,
                                };
                                this.addChargesNavigation();
                            });
                        return true;
                    },
                    (err) => {
                        this.router.navigate(['/']);
                        return false;
                    }
                )
            );
        }
    }

    getRole(roles: Array<any>, neededRole: 'medico' | 'admin' | 'enfermeria' | 'paciente' | 'secretaria'): boolean {
        for (let i = 0; i < roles.length; i++) {
            if (roles[i].name === neededRole)
                return true;
        }
        return false;
    }

    calculateDays(fecha_activacion: string, fecha_servidor: string): number {
        const days = moment(fecha_servidor).diff(
            moment(fecha_activacion),
            'days'
        );
        return days;
    }

    addChargesNavigation() {
        if ((this.config.isMedic && this.config.responsable.id === this.config.user.id) ||
            (this.config.isSecretary && this.config.responsable.secretarias && this.config.responsable.secretarias.includes(this.config.user.id))) {
            this._fuseNavigationService.updateNavigationItem('charges', {
                hidden: false,
            });

        } else {
            this._fuseNavigationService.updateNavigationItem('charges', {
                hidden: true,
            });
        }
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}
