export const locale = {
    lang: 'en',
    data: {
        MAIN: {
            NO_RESULTS: 'No results found',
            LEFT_MENU: 'Left menu',
            YEARS: 'years',
            SAVE_AS_ANEXO: 'Save as attachment',
            SEND_MESSAGE: 'Send message',
            SEND_ATTACH: 'Send attachment',
            ATTACH_FILE: 'Attach file',
            DELETE_FILE: 'Delete from chat',
            SAVE_AS_RECIPE: 'Append as recipe',
            SAVE_AS_INFORME: 'Append as report',
            SAVE_AS_DIAG: 'Append as diagnostic image',
            DELETE_FROM_ANEXO: 'Delete from appended files',
            VIEW_FILE: 'Ver archivo'
        },
        HISTORY: {
            HELLO: 'Hello, Patients!',
            TITLE: 'Hospitalization',
            REGISTERED: 'Registered',
            MEDICAL_ORDER: 'Medical Order',
            ACTIONS: 'Actions',
        },
        PATIENTS_OF_THE_DAY: {
            TITLE: 'Patients of the Day',
        },
        TIMELINE: {
            TITLE: 'Timeline',
            NO_DATA: 'No data',
            IMAGES: 'Images',
            LABORATORY: 'Laboratory',
            TREATMENT: 'Treatment',
            HOSPITALIZATION: 'Hospitalization',
            DIET: 'Diet',
            MIXES: 'Mixes',
            SURGERY: 'Surgery',
            INTERCONSULTATION: 'Interconsultation',
            EXTERNAL_CONSULTATION: 'External Consultation',
            POSITION: 'Position',
            OTHERS: 'Others',
            ACTIVITIES: 'Activities',
            ALLERGIES: 'Allergies',
            EGRESS: 'Egress',
            RECORD: 'Record',
            BALANCE: 'Hydric Balance',
            APPOINTMENTS: 'Appointments',
            NURSING_EVOLUTION: 'Nursing Evolution',
            MEDICAL_EVOLUTION: 'Medical Evolution',
            HISTORY: 'History',
            REPORT: 'Medical Report',
            OM: 'Medical Orders',
            VITAL_SIGN: 'Vital Sign',
            SURGICAL_REPORT: 'Surgical Report',
            SURGICAL_REPORTS: 'Surgical Reports',
            DATA: 'Data',
            CLOSE: 'Close',
            LOADING: 'Loading . . .',
            EMERGENCY: 'Emergency',
            ALDRETE: 'Aldrete',
            NONE: 'No results found',
            ANNEX: 'Add annex',
        },
        MENU_IZQUIERDO: {
            ACTIONS: 'Actions',
            ALLERGIES: 'Allergies',
            RECORD: 'Records',
            VITAL_SIGNS: 'Vital Signs',
            APPOINTMENT_HISTORY: 'Recent Appointments',
            BLOOD_TYPE: 'Blood Type',
            NOTES: 'Notes',
            PATHOLOGICAL: 'Pathological',
            NO_PATHOLOGICAL: 'No Pathological',
            ADD_ALLERGY: 'Add Allergy',
            ADD_RECORD: 'Add Record',
            ADD_VITAL_SIGNS: 'Add Vital Signs',
            NOT_ALLERGIES: 'Does not present allergies',
            ACTIVE_PRINCIPLE: 'Select active principle (Optional)',
            ACTIVE_PRINCIPLE_D: 'Active Principle',
            DATE: 'Select date (Optional)',
            DATE_N: 'Select date',
            COMMENTS: 'Comments (Optional)',
            NOT_RECORD: 'Does not present records',
            TYPE_RECORD: 'Type of Record',
            INVALID_OPTION: 'Invalid Option',
            PATHOLOGICAL_Q: '¿Pathological?',
            DESCRIPTION: 'Description',
            REQUIRED_FIELD: 'Required field!',
            TYPE: 'Type',
            MEDIC: 'Medic',
            OBSERVATION: 'Observation',
            DATE_D: 'Date',
            DATE_O: 'Date (Optional)',
            NO_VITAL_SIGNS: 'Does not present vital signs',
            OUT_RANGE: 'Out of range value',
            ALLOWED_RANGE: 'Allowed Values',
            GRAPH: 'Graph',
            VALUE: 'Value',
            DATE_H: 'Date / Hour',
            HOUR: 'Hour',
            MALE: 'Male',
            FEMALE: 'Female',
            RESPONSIBLE: 'Responsible of payment',
            TREATING: 'Treating doctor',
            AREA: 'Area',
            FLOOR: 'Floor',
            ROOM: 'Room',
            ROOM_INFO: 'Select an area and floor',
            BED: 'Bed',
            BED_INFO: 'Select a room',
            LOCATION: 'Location',
            ASSIGN: 'Not assigned',
            CONSULT: 'Consult',
            ESPECIALTY: 'Especialty',
            MEDICAL_TRANSFER: 'Medical transfer',
            LOCATION_TRANSFER: 'Location transfer',
        },
        EVOLUCIONES: {
            MEDICAL_EVOLUTION: 'Medical Evolutions',
            ADD_MEDICAL: 'Register medical evolution',
            NURSE_EVOLUTION: 'Nurse Evolutions',
            ADD_NURSE: 'Register nurse evolution',
        },
        ALTA: {
            EGRESS: 'Alta medica',
            DATE: 'Date',
            TYPE: 'Type',
            REPORT: 'Report',
            MEDIC: 'Medic',
            CANCEL: 'Cancel',
            SELECT_EGRESS: 'Select egress type',
            EMPTY_TEXT: 'Description cannot be empty',
        },
        BUTTONS: {
            ADD: 'Add',
            CLEAR: 'Clear',
            DELETE: 'Delete',
            CANCEL: 'Cancel',
            SAVE: 'Save',
            UPDATE: 'Update',
            NEXT: 'Next',
            BACK: 'Back',
            FIRST: 'First',
            LAST: 'Last',
            LOAD: 'Load more',
            FINALIZE: 'Finalize report',
            LOGIN: 'Login',
            REGISTER: 'Register',
        },
        OM: {
            OMITTED: 'Omitted',
            SEARCH: 'Search',
            SEARCH_P: 'Search by parameters',
            OMIT: 'Omit',
            EDIT: 'Edit',
            OMITTED_BY: 'Omitted by: ',
            AREA: 'Area',
            AREA_S: 'Select area',
            INDICATIONS: 'Indications',
            INDICATIONS_O: 'Indications (Optional)',
            DIET: 'Diet',
            DIET_S: 'Select diet',
            TREATMENT: 'Treatment',
            TREATMENT_S: 'Search by treatment',
            TA_TEXT:
                'The patient is allergic to the active component of the medicament. Do you wish to continue?',
            TA_CANCELBTN: 'Cancel',
            TA_ACCEPTBTN: 'Accept',
            VALUE_1: 'Minimum value is 1!',
            VIA: 'Via',
            DOSE: 'Dose',
            MEASURE: 'Measure',
            INTERVAL: 'Interval',
            ALL: 'ALL',
            EXAM: 'Exam',
            EXAMS: 'Exams',
            EXAM_S: 'Search by exams',
            EXAM_P: 'Search by profile',
            LIST: 'List',
            PROFILES: 'Profiles',
            SURGERY: 'Surgery',
            SPECIALTY: 'Specialty',
            SPECIALTY_S: 'Select specialty',
            INTERVENTION: 'Intervention',
            INTERVENTION_S: 'Select intervention',
            MEDIC: 'Medic',
            MEDIC_S: 'Select medic (Optional)',
            REASON: 'Reason',
            POSITION: 'Position',
            POSITION_S: 'Select position',
            OTHERS: 'Others',
            THERAPY: 'Is it respiratory therapy?',
        },
        ACTIVIDADES: {
            NURSE_ACTIVITIES: 'Nurse Activities',
            ADD_ACTIVITY: 'Add activity',
            PENDING_ACTIVITIES: 'Pending activities',
            PERFORMED_ACTIVITIES: 'Performed ativities',
            ACTIVITIES_LIST: 'Activities list',
            EDIT_ACTIVITY: 'edit activity',
            DELETE_ACTIVITY: 'delete activity',
        },
        MEDICAL_REPORTS: {
            NO_TEXT: "The report's content is empty",
            NO_DATA: 'This patient has no medical reports',
            NO_TEMPLATE:
                'The medical report must be done with a premade template',
            MEDICAL_REPORTS: 'Medical Reports',
            TYPES_REPORTS: 'Select template',
            PREVIEW: 'Preview',
            DATE: 'Date',
            TYPE: 'Type',
            DETAILS: 'Details',
            MEDIC: 'Medic',
            ACTION: 'Action',
            PRINT: 'Print',
        },
        TREATMENT: {
            DATE: 'Date',
            HOUR: 'Hour',
            TREATMENT: 'Treatment',
            DOSE: 'Dose',
            VIA: 'Via',
            NURSE: 'Nurse',
            STATUS: 'Status',
        },
        NOTA_OPERATORIA: {
            OPERATING_NOTE: 'Operating Note',
            DETAIL: 'Detail',
            NO_DETAIL: 'Select operative note to see its detail',
            OPERATING_NOTE2: 'Operative Notes',
            DATE: 'Date: ',
            DATE2: 'Date',
            TO: ' to ',
            INTERVENTION: 'Intervention Data',
            PARTICIPANTS: 'Participants',
            INTERVENTION2: 'Intervention',
            SURGEON: 'Main Surgeon: ',
            DIAGNOSTICS: 'Diagnostics',
            'PRE-OPERATIVE': 'Pre-Operative: ',
            'POST-OPERATIVE': 'Post-Operative: ',
            PROCEDURE: 'Procedure Data',
            TIME: 'Intervention time: ',
            ANESTHESIA: 'Type of anesthesia: ',
            PRIORITY: 'Priority: ',
            TRANSIT: 'Transit: ',
            FINDINGS: 'Findings: ',
            EVOLUTION:
                'Evolution and condition of the patient at the end of the surgery: ',
            SPECIALTY: 'Specialty',
            MEDIC: 'Medic',
            ACTION: 'Action',
            NO_DATA: 'This patient has no operating notes',
        },
        BALANCE: {
            BALANCE_HIDRICO: 'Hydric Balance,',
            INGESTA: 'Intake',
            EXCRETA: 'Excreted',
            TOTAL_INGESTA: 'Total Intake:',
            TOTAL_EXCRETA: 'Total Excrete:',
            BALANCE: 'Balance:',
            REGISTRADO: 'Registered by',
            CANTIDAD: 'Quantity',
            DETALLE: 'Detail',
            TIPO: 'Type',
            FECHA: 'Date/Hour',
            TODAS: 'All',
            DESDE: 'From:',
            HASTA: 'To:',
            HOY: 'Today',
            '7DIAS': 'Last 7 days',
            MES: 'Month',
            PRINCIPAL: 'Main',
            AGREGAR: 'Add',
            CANCELAR: 'Cancel',
        },
        IMAGENES: {
            IMAGENES: 'Images',
            RESULTADOS: 'Results',
            PENDIENTES: 'Pendings',
            FECHA: 'Date',
            EXAMEN: 'Exam',
            SOLICITADO: 'Requested by',
            INFORMADO: 'Reported by',
            INFORME: 'Report',
            IMAGEN: 'Image',
            ACCION: 'Action',
            EXTERNOS: 'Add external results',
            ARCHIVO: 'File',
            SELECCIONE: 'Select the file',
            BUSCAR: 'Search',
            ADJUNTAR: 'Adjunt',
            MOSTRAR: 'Show All',
            MODAL_TITLE: 'Image',
            MODAL_BTN: 'Close',
            SEE_ALL_APPOINTMENTS: 'See all appointments',
            SEE_ALL: 'See all',
            INCLUDE_RESULTS: 'Include results',
            SEE_IMAGE: 'See image',
            HALLAZGO: 'Finding',
        },
        QUILL: {
            FORMAT_OPTIONS: 'More Options',
            LINK: 'Insert link',
            IMAGE: 'Insert image',
            FONT: 'Font Family',
            SIZE: 'Font Size',
            SMALL: 'Small',
            LARGE: 'Large',
            BIG: 'Big',
            BOLD: 'Bold',
            ITALIC: 'Italic',
            UNDERLINE: 'Underline',
            TEXT_COLOR: 'Text Color',
            BACKGROUND_COLOR: 'Background Color',
            ALIGNMENT: 'Alignment',
            LIST_ORDERED_NUMBER: 'List Ordered Number',
            LIST_ORDERED_BULLET: 'List Ordered Bullet',
            MORE_INDENT: 'Increase Indent',
            DECREASE_INDENT: 'Decrease Indent',
            QUOTE: 'Quote',
            STRIKETHROUGH: 'Strikethrough',
            CLEAR: 'Clear Format',
            UNDO: 'Undo',
            REDO: 'Redo',
        },
        LAB: {
            LABORATORY: 'Laboratory',
            SEE_ALL_APPOINTMENTS: 'See all appointments',
            INCLUDE_RESULTS: 'Include results',
            RESULTS: 'Results',
            PENDING: 'Pending',
            DATE: 'Date',
            TEST: 'Test',
            DETAIL: 'Detail',
            REQUESTED: 'Requested',
            SPECIALTY: 'Specialty',
            ACTION: 'Action',
            NO_RESULTS: 'No results',
            ADD_EXTERNAL_RESULTS: 'Add External Results',
            BACK: 'Go back',
            SEARCH_FILE: 'Search File',
            ADD: 'Attach',
            LOADING: 'Loading . . .',
            SAVED: 'File uploaded succesfully',
            FILE_ERROR_TYPE: 'File must be PDF',
            LOADED: 'File loaded',
            ERROR_LOADING: 'Error loading file',
            EXAM_DETAILS: 'Exam Details',
            EXTERNAL_LAB: 'External Laboratory',
            EXAMS: 'Exams',
        },
        IN: {
            TITLE: 'Entry History',
            CONSULT_MOTIVE: 'Consult Motive',
            APPOINTMENT_MOTIVE: 'Appointment Motive',
            ACTUAL_DISEASE: 'Actual Disease',
            EVOLUTION: 'Evolution',
            DIAGNOSIS: 'Diagnosis',
            INGRESS: 'Dx - Entry',
            EGRESS: 'Dx - Egress',
            PHYSICAL_TEST: 'Physical Test',
            LABORATORY: 'Laboratory',
            IMAGES: 'Images',
            PLAN: 'Plan',
            RECIPE: 'Recipe',
            RECIPES: 'Recipes',
            PRINT_RECIPE: 'Print recipe',
            CLINICAL_HISTORY: 'Clinical History',
            ANNEX: 'Anexx',
            MOTIVES: 'Motives',
            DISEASE: 'Disease',
            DIAGNOSTICS: 'Diagnostics',
            PLAN_DESCRIPTION: 'Plan Description',
            CONCENTRATION: 'Concentration',
            UNIT: 'Measure',
            VIA: 'Via',
            INTERVAL: 'Interval',
            OBSERVATIONS: 'Observations',
            SEVERITY: 'Severity',
            MILD: 'Mild',
            NORMAL: 'Normal',
            SEVERE: 'Severe',
            ABDOMEN: 'Abdomen',
            HEAD_NECK: 'Head - Neck',
            CARDIO_PULMONARY: 'Cardio - Pulmonary',
            VERTEBRAL_COLUMN: 'Vertebral Column',
            RIGHT_END: 'Right End',
            INFERIOR_RIGHT_END: 'Inferior Right End',
            INFERIOR_LEFT_END: 'Inferior Left End',
            LEFT_END: 'Left End',
            GENITAL: 'Genitals',
            NEUROLOGICAL: 'Neurologic',
            ORL: 'ORL',
            SKIN: 'Skin',
            GENERAL_STATE: 'General State',
            REGION: 'Region',
            SAVE: 'Save',
            CONFIRM: 'Confirm',
            NO_SEVERITY: 'Severity must be specified',
            DELETE: 'Delete',
            EDIT: 'Edit',
            TOO_LONG: 'Content is too long',
            INCOMPLETE_FIELDS: 'Fields are incomplete',
            RE_EVALUATION: 'Re-evaluation',
            OBSERVATION: 'Observation',
            END_APPOINTMENT: 'End Appointment',
            DIAGNOSTIC: 'Diagnostic',
            PRINT: 'Print',
            CONFIRM_END_APPOINTMENT:
                'Are you sure you want to end this appointment?',
            REQUIRED: 'This section is required to end the appointment',
            CANCEL: 'Cancel',
            REQUIRED_FIELDS_INCOMPLETE:
                'Required data to complete this appointment is incomplete, check the fields marked with a "*"',
            SELECT_MOTIVE: 'Select consult motive',
            SELECT_DIAGNOSTIC: 'Select diagnostic',
            SELECT_DXI: 'Select entry diagnostic',
            SELECT_DXE: 'Select egress diagnostic',
            AREA: 'Area',
            FLOOR: 'Floor',
            ROOM: 'Room',
            BED: 'Bed',
            SELECT_AREA: 'Select Area',
            SELECT_FLOOR: 'Select Floor',
            SELECT_ROOM: 'Select Room',
            SELECT_BED: 'Select Bed',
            SEND_TO_OBSERVATION: 'Send patient to Observation',
            DETAILS: 'Details',
            PENDINGS: 'Pending',
            RESULTS: 'Results',
            NO_PENDING_EXAMS: 'No pending exams were found',
            NO_RESULT_EXAMS: 'No results were found',
            ADD_TEST: 'Add test',
            HOSPITAL_RELEASE: 'Hospital Release',
            NO_OBSERVATIONS: 'Physical Test observations must be specified',
            DISEASE_PLACEHOLDER: "Enter the disease's description",
            EVO_PLACEHOLDER: "Enter the evolution's description",
            TEST_PLACEHOLDER: 'Describe the physical test result',
            PLAN_PLACEHOLDER: 'Register the plan to follow',
            RECIPE_PLACEHOLDER: 'Select medicine to assign',
            REGISTERING_TEST: 'Registering test...',
            DELETING: 'Deleting test...',
            INDICATIONS: 'Indications',
            DOSIS: 'Dosis',
            ADD_MOTIVE: 'Add motive',
            ADD_DIAGNOSTIC: 'Add diagnostic',
            TORAX: 'Torax',
            EXTREMITIES: 'Extremities',
            GENERAL: 'General',
            ABDOMINAL: 'Abdominal',
            TEST_RESULTS: 'Physical Test Results',
            HISTORY: 'History',
            NEW: 'Do you want to assign a new appointment?',
            MEDICINES: 'Medicines',
            HISTORIC: 'History of entry ',
        },
        SURGICAL_REPORT: {
            TITLE: 'Operative Note',
            REQUIRED: 'Complete all the required fields',
            MAIN: {
                DATA: 'Intervention data',
                PARTICIPANTS: 'Participants',
                DIAGNOSIS: 'Diagnosis',
                PROCEDURE: 'Procedure description',
                REPORT: 'Surgical report',
                EDIT: 'Edit',
                FINALIZE: 'Are you sure you want to end the report?',
                END: 'End report',
            },
            DATA: {
                TIME: 'Time of intervention',
                PRIORITY: 'Priority',
                ELECTIVE: 'Elective',
                EMERGENCY: 'Emergency',
                TRANSIT: 'Transit',
                AMBULATORY: 'Ambulatory',
                HOSP: 'Hospitalization',
                START: 'Start of surgery',
                END: 'End of surgery',
                INTERVENTION: 'Interventions',
                ADDITIONAL: 'Additional interventions',
            },
            PARTICIPANTS: {
                DESCRIP: 'Description',
                TYPE: 'Type',
                MEDIC: 'Medic',
                NURSE: 'Nurse',
                ESPECIALTY: 'Especialty',
                DEFAULT: 'By default?',
            },
            DIAGNOSIS: {
                PRE: 'Preoperatives',
                POST: 'Postoperatives',
            },
            PROCEDURE: {
                TYPE: 'Type of anesthesia',
                FINDING: 'Findings',
                EVOLUTION:
                    'Evolution and condition of the patient at the end of the surgery',
            },
        },
        APPOINTMENT: {
            TITLE: 'Appointments History',
            DATE: 'Date',
            APPOINTMENT_TYPE: 'Appointment Type',
            SPECIALTY: 'Specialty',
            MEDIC: 'Medic',
            DIAGNOSTIC: 'Diagnostic',
            ACTION: 'Action',
            DETAILS: 'See details',
        },
        ALDRETE: {
            TITLE: 'Aldrete',
            ACTIVITY: 'Activity',
            CONSCIENCE: 'Conscience',
            COLOR: 'Color',
            CIRCULATION: 'Circulation',
            RESPIRATORY: 'Respiratory',
            CLEAR_FORM: 'Clear Form',
            SAVE: 'Save',
            ALDRETE_DETAILS: 'Aldrete Details',
            DATE: 'Date/Hour',
            MEDIC: 'Medic',
            POINTS: 'Points',
            NOTE: 'Note',
            SUGGESTED_POINTS: "It' suggested to have a score of",
            ACCUMULATED: 'Accumulated',
            BREATHING: 'Breathing',
            INCOMPLETE_ALDRETES: 'Aldretes are incomplete',
        },
        CONSULT_TYPE: {
            E: 'E',
            HOSP: 'H',
            SURG: 'S',
            EC: 'EC',
            IC: 'IC',
        },
        UTIL: {
            GO_TO: 'Go to',
            SEE: 'See',
        },
    },
};
