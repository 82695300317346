import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { locale as es } from '../i18n/es';
import { locale as en } from '../i18n/en';
import { FuseTranslationLoaderService } from '../../../../../@fuse/services/translation-loader.service';

@Component({
    selector: 'app-confirmation-dialog',
    templateUrl: './confirmation-dialog.component.html',
    styleUrls: ['./confirmation-dialog.component.scss'],
})
export class ConfirmationDialogComponent implements OnInit {
    constructor(
        public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
        private translationLoader: FuseTranslationLoaderService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.translationLoader.loadTranslations(es, en);
    }

    ngOnInit(): void {}
}
