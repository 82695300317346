import {
    Component,
    OnInit,
    OnDestroy,
    ViewEncapsulation,
    Input,
} from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { MatDialog, MatExpansionPanel } from '@angular/material';
import { CitasService } from '../citas/citas.service';
import { ActiveCallDialogComponent } from '../ingreso/modals/active-call-dialog/active-call-dialog.component';
import { FuseConfigService } from '@fuse/services/config.service';
import { takeUntil } from 'rxjs/operators';
import { HistoryService } from '../history.service';
import { NetworkService } from 'app/services/network-service.service';

@Component({
    selector: 'app-menu_izquierdo',
    templateUrl: './menu_izquierdo.component.html',
    styleUrls: ['./menu_izquierdo.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class MenuIzquierdoComponent implements OnInit, OnDestroy {
    // Variables privadas
    private panel: number;
    private _unsubscribeAll: Subject<any>;

    @Input() infoCita: any;

    consulta_id: any;
    paciente_id: any;
    cita: any;
    cita_id: any;
    img_src: any;
    selected: any;
    hideOption: boolean;
    view: any;
    hideToggle: boolean;
    subscription: Subscription;
    fuseConfig: any;
    templateName: string = '';
    showPanel: boolean = true;
    keepOpenAlergies: boolean = false;
    keepOpenHistory: boolean = false;
    keepOpenMenu: boolean = true;

    results = {
        alergias: false,
        antecedentes: false,
        signos_vitales: false,
        historial_citas: false,
    };

    private ingresado = false;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private matDialog: MatDialog,
        private _fuseConfigService: FuseConfigService,
        private historyService: HistoryService,
        private networkService: NetworkService
    ) {
        // this._fuseTranslationLoaderService.loadTranslations(english, spanish);
        this.panel = 0;
        this._unsubscribeAll = new Subject();
    }
    ngOnInit(): void {
        this._fuseConfigService
            .getConfig()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((res) => {
                this.fuseConfig = res;
            });

        // Parametros de url
        this.consulta_id = this.route.snapshot.paramMap.get('consulta_id');
        this.view = this.route.snapshot.paramMap.get('view_id');
        this.paciente_id = this.route.snapshot.paramMap.get('paciente_id');
        this.cita_id = this.route.snapshot.paramMap.get('cita_id');
        this.networkService.getShowSidebar().subscribe(data => this.showPanel = data)

        this.obtenerCita(this.cita_id);
        this.view !== 'citas'
            ? (this.hideToggle = true)
            : (this.hideToggle = false);
        // Subcripcion a cambio de url y actualizacion de parametros
        this.subscription = this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.consulta_id =
                    this.route.snapshot.paramMap.get('consulta_id');
                this.paciente_id =
                    this.route.snapshot.paramMap.get('paciente_id');
                this.cita_id = this.route.snapshot.paramMap.get('cita_id');
            }
        });
    }

    setPanel(index: number): void {
        this.panel = index;
    }

    setResults(result, bool): void {
        this.results[result] = bool;
    }

    /**
     * Dialogo utilizado en caso de que se tenga activa una video llamada
     * @param parametro
     * @param expansionPanel
     */
    openDialog(parametro, expansionPanel: MatExpansionPanel): void {
        if (this.fuseConfig.floating_videocall) {
            const dialog = this.matDialog.open(ActiveCallDialogComponent, {
                autoFocus: false,
                width: '400px',
            });

            const subs = dialog.afterClosed().subscribe((res) => {
                if (res) {
                    this.cambiarRuta(parametro, expansionPanel);
                    this._fuseConfigService.config = {
                        floating_videocall: false,
                    };
                }
                subs.unsubscribe();
            });
        } else {
            this.cambiarRuta(parametro, expansionPanel);
        }
    }

    cambiarRuta(parametro, expansionPanel: MatExpansionPanel): void {
        if (expansionPanel.expanded) {
            expansionPanel.close();
        } else {
            expansionPanel.open();
        }
        const paciente_id = this.route.snapshot.paramMap.get('paciente_id');
        const cita_id = this.route.snapshot.paramMap.get('cita_id');
        const consulta_id = this.route.snapshot.paramMap.get('consulta_id');
        const om_id = this.route.snapshot.paramMap.get('om_id');

        this.router.navigate([
            'medic/history/' + paciente_id + '/' + cita_id + '/agregar_alergia',
        ]);
        this.router.navigate([
            '/medic/history/' +
                paciente_id +
                '/' +
                cita_id +
                '/' +
                parametro +
                '/' +
                consulta_id +
                '/' +
                om_id,
        ]);

        // Logica para mantener abierto el panel seleccionado
        if(parametro == 'agregar_alergia'){
            this.keepOpenAlergies = true;
            this.keepOpenMenu = false;
            this.keepOpenHistory = false;
        }else if(parametro == 'agregar_antecedentes'){
            this.keepOpenHistory = true;
            this.keepOpenMenu = false;
            this.keepOpenAlergies = false;
        }else {
            this.keepOpenMenu = true;
            this.keepOpenAlergies = false;
            this.keepOpenHistory = false;
        }
    }

    historicoCita($event): void {
        this.consulta_id = $event.consulta_id;
    }

    obtenerCita(cita_id): void {
        this.historyService.getCitasCE(cita_id).subscribe(
            (res) => {
                this.cita = res;
            },
            (err) => {
                console.error(err);
            }
        );
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
        this.subscription.unsubscribe();
    }
}
