export const locale = {
    lang: 'en',
    data: {
        MAIN: {
            HIDE: 'Hide',
            BG_COLOR: 'Background color',
            POSITION: 'Position',
            VARIANT: 'Variant',
            LEFT: 'Left',
            RIGHT: 'Right',
            STYLE: 'Style',
            CUSTOM: 'Custom',
            SETTINGS: 'Settings',
        },
        THEME: {
            TITLE: 'Color themes',
            DEFAULT: 'Default',
            LIGHT_GREEN: 'Light green',
            DARK_GREEN: 'Dark green',
            YELLOW: 'Yellow',
        },
        DESIGN: {
            LAYOUT_STYLES: 'Layout styles',
        },
        MENU_BAR: {
            TITLE: 'Menu bar',
            FOLDED: 'Folded',
            ABOVE: 'Above',
            BELOW_STATIC: 'Static Down',
            BELOW_FIXED: 'Fixed down',
        },
        SALES_NAV: {
            TITLE: 'Navigation bar. Sales module',
            FOLDED: 'Folded',
            FIRST_BG: 'Primary background',
            SECOND_BG: 'Secondary fund',
        },
    },
};
