import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { NetworkService } from 'app/services/network-service.service';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    public isAuthenticated = false;
    constructor(private networkService: NetworkService) {}

    login(user): Observable<any> {
        const body = {
            email: user.email,
            password: user.password,
        };
        return this.networkService.post('users/authenticate', body);
    }

    logout(): Observable<any> {
        return this.networkService.get('users/logout');
    }

    getProfile(): Observable<any> {
        return this.networkService.get('user/');
    }

    getLogo(): Observable<any> {
        return this.networkService.get('logo');
    }

    /**
     * Login de pacientes - portal
     * @param body { "email": "string", "password": "string" }
     */
    login_patient(body: any): Observable<any> {
        return this.networkService.post(`paciente/authenticate`, body);
    }

    /**
     * Registro de pacientes para uso del portal
     * @param body { "nombre": "string", "apellido": "string", "nacimiento": "2020-07-29", "sexo": "M", "correo": "string", "password": "string" }
     */
    register(body: any): Observable<any> {
        return this.networkService.post(`users/register/paciente`, body);
    }

    /**
     * Confirmación de registro
     * @param param parametro que retorna en la respuesta de registro de paciente
     */
    confirmRegister(param1: string): Observable<any> {
        return this.networkService.post(`users/confirm?param1=${param1}`);
    }

    /**
     * Información de modulo activos
     * @param idAmbulatory
     */
    getClinic(idAmbulatory: string): Observable<any> {
        return this.networkService.get(`ambulatorio/${idAmbulatory}`);
    }

    /**
     * Obtener ID de clinica
     * @param nickname {string}
     */
    getClinicId(nickname: string): Observable<any> {
        return this.networkService.get(`ambulatorio/id/${nickname}`);
    }

    /**
     * Confirmar correo
     * @param body { "token": "string", "contrasena": "string", "confirmar_contrasena": "string", "google_id": 0 }
     */
    postConfirmMail(body: any): Observable<any> {
        return this.networkService.post(`paciente/correo/confirmar`, body);
    }

    /**
     * Modificar contraseña al olvidarla
     * @param body { "email": "string", "code": "string", "contrasena": "string", "confirmar_contrasena": "string" }
     */
    postForgotPassword(body: any): Observable<any> {
        return this.networkService.post(`user/finish_password_change`, body);
    }

    /**
     * solicitar cambio de clave
     * @param body { "email": "string" }
     */
    postResetPassword(body: any): Observable<any> {
        return this.networkService.post(`user/request_password_change`, body);
    }

    // Incluido para el proceso de agendado por landing usando Google
    registerCita(body): Observable<any> {
        return this.networkService.post('citas/ce', body);
    }
}
