export const locale = {
    lang: 'es',
    data: {
        LOGIN: {
            TITLE: 'INICIAR SESIÓN',
            USER: 'Usuario (paciente) / Email',
            REQUIRED: 'Campo requerido',
            MIN_ERROR: 'Debe tener un mínimo de 6 carácteres',
            DO_NOT_MATCH: 'Las contraseñas no coinciden',
            USER_REQUIRED: 'Usuario Requerido',
            PASSWORD: 'Contraseña',
            NEW_PASSWORD: 'Nueva contraseña',
            CONFIRM_PASSWORD: 'Confirmar contraseña',
            REMEMBER_ME: 'Recuérdame',
            LOGIN: 'CONECTARSE',
            EMAIL_REQUIRED: 'El correo es requerido',
            EMAIL: 'Correo electrónico',
            PASSWORD_REQUIRED: 'Contraseña es requerido!',
            WELCOME: 'Bienvenido a BINWUS-HIS!',
            LOADING: 'Cargando...',
            VALID_EMAIL: 'Por favor, introduzca un correo válido',
            INVALID_PASSWORD: 'Contraseña incorrecta',
            BACK: 'REGRESAR',
            DONT_HAVE: '¿No tienes una cuenta?',
            REGISTER: 'Regístrate',
            GOOGLE: 'Ingresa con tu cuenta Google',
            BLOCKED:
                'Solicite la activación del módulo Telemedicina, Control de Citas o Historia Clínica.',
            PASSWORD_RECOVERY: 'Recuperar contraseña',
            FORGOT_PASSWORD: '¿Olvidaste tú contraseña?',
            RESET_PASSWORD: 'REESTABLECER CONTRASEÑA',
            LOG_MESSAGE: 'Ingresar con tú usuario y contraseña',
            DEVELOPED_BY_MSG: 'Desarrollado por BINWUS',
            INACTIVE_ACCOUNT:
                'Su cuenta no está activa. Por favor ponerse en contacto con el administrador. Email: soporte@portalmedico.com',
            CHECK_CONNECTION:
                'Por favor chequee su conexión, espere un momento y vuelva a intentar.',
        },
    },
};
