import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { HistoryService } from '../../../history.service';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import {
    MatSnackBar,
    MAT_DIALOG_DATA,
    MatDialogRef,
    MatDialog,
} from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';

@Component({
    selector: 'app-comentarios-modal',
    templateUrl: './comentarios-modal.component.html',
    styleUrls: ['./comentarios-modal.component.scss'],
})
export class ComentariosModalComponent implements OnInit {

    useMoment = moment
    comments = []
    selectedComment: any = null
    newCommentText: string = ''

    @ViewChild('commentTextArea', { static: false }) commentTextArea: ElementRef

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private historyService: HistoryService,
        private fuseProgressBarService: FuseProgressBarService,
        private snackBar: MatSnackBar,
        private _translateService: TranslateService,
        private dialog: MatDialog,
        public dialogRef: MatDialogRef<ComentariosModalComponent>
    ) { }

    ngOnInit(): void {
        this.comments = this.data.comments
        this.historyService.getNotas(this.data.cita_id).subscribe(data => {
            this.comments = data
        })

        this.dialogRef.disableClose = true
        // Backdrop click
        this.dialogRef.backdropClick().subscribe(event => {
            if (!this.selectedComment && this.newCommentText) {
                this.dialog.open(CommentCloseDialog, {
                    panelClass: 'custom-dialog',
                    autoFocus: false,
                    data: {},
                }).afterClosed().subscribe(result => {
                    if (result) {
                        this.dialogRef.close()
                    }
                })
            } else {
                this.dialogRef.close()
            }
        })
    }

    onAddCommentClick() {
        this.selectedComment = null
        this.commentTextArea.nativeElement.value = this.newCommentText
        this.commentTextArea.nativeElement.focus()
    }

    onCommentClick(comment) {
        this.selectedComment = comment;
        this.commentTextArea.nativeElement.value = comment.nota
    }

    onSaveComment() {
        // New comment body
        const body = {
            cita: this.data.cita_id,
            nota: this.commentTextArea.nativeElement.value
        }
        // New comment request
        this.historyService.postNotas(body).subscribe(data => {
            this.fuseProgressBarService.show()
            if (data.status === 200) {
                this.newCommentText = ''
                this.historyService.getNotas(this.data.cita_id).subscribe(data => {
                    this.comments = data
                    if (this.comments.length) {
                        this.selectedComment = this.comments[0]
                        this.commentTextArea.nativeElement.value = this.comments[0].nota
                        this.fuseProgressBarService.hide()
                        this.snackBar.open('Comentario guardado', '', {
                            duration: 3000,
                            verticalPosition: 'top',
                            panelClass: 'custom-blue',
                        });
                    }
                })
            } else {
                this.fuseProgressBarService.hide()
                this.snackBar.open('No se pudo guardar el comentario', '', {
                    duration: 3000,
                    verticalPosition: 'top',
                    panelClass: 'custom-red',
                });
            }
        })
    }

    onModalClose() {
        if (!this.selectedComment && this.newCommentText) {
            this.dialog.open(CommentCloseDialog, {
                panelClass: 'custom-dialog',
                autoFocus: false,
                data: {},
            }).afterClosed().subscribe(result => {
                if (result) {
                    this.dialogRef.close()
                }
            })
        } else {
            this.dialogRef.close()
        }
    }

    getFormatedDate(date: string): string {
        return this.capitalizeFirstLetter(this.useMoment(date).add(this.useMoment().utcOffset(), 'minutes').locale(this._translateService.currentLang).format('ddd DD MMM YYYY - hh:mm A'))
    }

    capitalizeFirstLetter(text: string): string {
        return text.charAt(0).toUpperCase() + text.slice(1);
    }

}

@Component({
    selector: 'comment-close-dialog',
    templateUrl: './comment-close-dialog/comment-close-dialog.component.html',
})
export class CommentCloseDialog implements OnInit {
    fuseConfig: any;

    constructor(
        public dialogRef: MatDialogRef<any>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

    ngOnInit(): void {

    }

    cancel(): void {
        this.dialogRef.close(false)
    }

    confirm(): void {
        this.dialogRef.close(true)
    }

}
