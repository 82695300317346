import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { NetworkService } from 'app/services/network-service.service';

@Injectable()
export class TelemedicineService {
    constructor(private networkService: NetworkService) {}

    // SALAS EP

    /**
     * Agregar una nueva sala
     * @param body { "ambulatorio": "string", "medico": "string", "paciente": "string"}
     */
    postSala(body: any): Observable<any> {
        return this.networkService.post(`sala`, body);
    }

    /**
     * Agregar un nueva chat
     * @param body { "sala": "string", "cita": "string"}
     */
    postChat(body: any): Observable<any> {
        return this.networkService.post(`chat`, body);
    }

    /**
     * Agregar un nuevo chat
     * @param body { "id": "string", "chat": "string", "texto": "string", "tipo": 0, "url": "string" }
     */
    postMensaje(body: any): Observable<any> {
        return this.networkService.post(`mensaje`, body);
    }

    /**
     * Get
     * @param idChat
     */
    getMensaje(idChat: string): Observable<any> {
        return this.networkService.get(`mensaje/${idChat}`);
    }

    /**
     * Get parametros para conexion de opentok video
     * @param name {string} nombre de la sala
     */
    getRoomIdentifiers(name: string): Observable<any> {
        return this.networkService.get(`sala/token/${name}`);
    }

    /**
     * Actualizar dato de Medico (Online)
     * @param body {id: uuid, on_line: boolean}
     */
    putMedicoStatus(body: any): Observable<any> {
        return this.networkService.put(`medico/online`, body);
    }

    postMensajeAdjunto(body: any): Observable<any> {
        return this.networkService.postForm(`adjunto`, body);
    }

    deleteMensajeAdjunto(id): Observable<any> {
        return this.networkService.delete(`adjunto/${id}`);
    }

    postAdjuntoAnexo(id, tipo): Observable<any> {
        return this.networkService.post(`adjunto/${id}/anexo/${tipo}`);
    }

    postJitsiToken(body?: any): Observable<any> {
        return this.networkService.post(`videollamada/token`, body);
    }

    deleteAnexos(anexoId: string): Observable<any> {
        return this.networkService.delete(`pacientes/anexos/${anexoId}`);
    }
}
